import React from "react";
import ReactApexChart from 'react-apexcharts';

interface LineGraphProps {
    data: number[]
}

const LineGraph: React.FC<LineGraphProps> = () => {
    const chartOptions = {
        type: "line",
        series: [
            {
                name: 'Series 1',
                data: [44, 55, 67, 83],
            }
        ]
    }
    return (
        <div>
            <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="line"
                height="350"
            />
        </div>
    )
}

export default LineGraph;