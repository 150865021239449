import ForgottenPasswordCard from "../../components/common/cards/ForgottenPasswordCard";


type Props = {}

const ForgottenPasswordPage = (props: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <ForgottenPasswordCard />

    </div>
  );
};

export default ForgottenPasswordPage;