const fontConfigs = {
    detailPage : {
        appbar: {
            label: {
                    xs: '10px',    // Extra small screens (like small smartphones)
                    sm: '12px',    // Small screens
                    md: '14px',    // Medium screens (tablets)
                    lg: '14px',    // Large screens (laptops and desktops)
                    xl: '16px',    // Extra large screens (TVs or large monitors)
            },
            title: {
                    xs: '14px',
                    sm: '20px',
                    md: '22px',
                    lg: '22px',
                    xl: '24px',
            },
        },
        headings: {
            label: {
                    xs: '12px',    // Extra small screens (like small smartphones)
                    sm: '14px',    // Small screens
                    md: '16px',    // Medium screens (tablets)
                    lg: '16px',    // Large screens (laptops and desktops)
                    xl: '16px',    // Extra large screens (TVs or large monitors)
            },
            title: {
                    xs: '14px',
                    sm: '16px',
                    md: '18px',
                    lg: '18px',
                    xl: '18px',
            },
            frames: {
                xs: '12px',
                sm: '14px',
                md: '16px',
                lg: '16px',
                xl: '16px',
        },
        tableLabels: {
            xs: '10px',
            sm: '12px',
            md: '14px',
            lg: '16px',
            xl: '16px',
    },
        }
    }
}

export default fontConfigs;