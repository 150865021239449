import { Card, Grid, Typography, Dialog, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle, ListItem, ListItemText, Switch, List, ListSubheader, DialogContent, IconButton, DialogTitle, Divider, Stepper, StepLabel, Step, Snackbar, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { SmallTextEditField, HousingResolutionSelectField, HousingResolutionReadOnlySelectField, SmallEditReadOnlyField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { HousingSessionStorageKey, HousingTableParameterStorageService } from '../../../services/sessionStorage';
import CartesianGrid from '../graphs/CartesianGrid';
import FileUploadCard from './FileUploadCard';
import MuiAlert from '@mui/material/Alert';
import FileDownloadCard from './FileDownloadCard';


interface HousingDetailProps {
  housing_id?: any | null;
  create: boolean;
  handleRefreshButton: () => void;
  handleBackButton: () => void;
}

const HousingDetailCard: React.FC<HousingDetailProps> = ({housing_id, create, handleRefreshButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [housing, setHousing] = useState<any>(null);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [image, setImage] = useState("");
    const [graphData, setGraphData] = useState<any>()

    const resolution = [
        { label: "VGA: 640 x 480", value: [640, 480] },
        { label: "720p: 1280 x 720", value: [1280, 720] },
        { label: "1080p: 1920 x 1080", value: [1920, 1080] },
    ];



    // Handle switch toggle
    const handleSwitchToggle = async (event: any) => {
        formik.setFieldValue('is_active', event.target.checked);
        await formik.submitForm();  // Submit form with only 'is_active'
    
        // Show success message in Snackbar
        setSnackbarMessage(event.target.checked ? "Housing activated" : "Housing deactivated");
        setIsSnackbarOpen(true);
    };
    
    const validationSchema = () => {
        return Yup.object().shape({

        });
    }

    // // Fetch housing data and point_model JSON data
    useEffect(() => {
        if (housing_id) {
            const fetchHousing = async () => { 
                try {
                    const url = `housing/${housing_id}`;
                    
                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    
                    const fetchedHousing = response.data;
                    setHousing(fetchedHousing);

                    // If point_model URL exists, fetch the JSON data
                    if (fetchedHousing.point_model) {
                        const modelResponse = await fetch(fetchedHousing.point_model);
                        const modelData = await modelResponse.json();
                        setGraphData(modelData); // Set graphData for the chart
                    }

                } catch (error) {
                    console.error('Error fetching housing or point model data', error);
                }
            };
            fetchHousing();    
        }
    }, [housing_id]);

    // // Keeping This For Development Just Now
    // useEffect(() => {
    //     if (housing_id) {
    //         const fetchHousing = async () => { 
    //             try {
    //                 const url = `housing/${housing_id}`;
                    
    //                 const response = await api.get(url, {
    //                     headers: {
    //                         'Authorization': `Bearer ${authData.access}`
    //                     }
    //                 });
                    
    //                 const fetchedHousing = response.data;
    //                 setHousing(fetchedHousing);
    
    //                 // If point_model URL exists, ensure it has the correct port
    //                 if (fetchedHousing.point_model) {
    //                     const pointModelUrl = new URL(fetchedHousing.point_model);
    
    //                     // Check if port is missing, and add it if necessary
    //                     if (!pointModelUrl.port) {
    //                         pointModelUrl.port = '8000'; // Default to 8000 if no port is specified
    //                     }
    
    //                     // Fetch the JSON data from the modified URL
    //                     const modelResponse = await fetch(pointModelUrl.toString());
    //                     const modelData = await modelResponse.json();
    //                     setGraphData(modelData); // Set graphData for the chart
    //                 }
    
    //                 // If image URL exists, ensure it has the correct port
    //                 if (fetchedHousing.image) {
    //                     const imageUrl = new URL(fetchedHousing.image);
    
    //                     // Check if port is missing, and add it if necessary
    //                     if (!imageUrl.port) {
    //                         imageUrl.port = '8000'; // Default to 8000 if no port is specified
    //                     }
    
    //                     setImage(imageUrl.toString()); // Set image URL with correct port
    //                 }

    //             } catch (error) {
    //                 console.error('Error fetching housing, point model, or image data', error);
    //             }
    //         };
    //         fetchHousing();    
    //     }
    // }, [housing_id]);
    


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && housing) ? {
                id: housing.id || '',
                classifier: housing.classifier || null,
                description: housing.description || '',
                image: housing.image || null,
                name: housing.name || '',
                organisation: housing.organisation,
                max_conformance_probability: housing.max_conformance_probability || '',
                min_conformance_probability: housing.min_conformance_probability || '',
                point_model: housing.point_model || null,
                reader_resolution: housing.reader_resolution || [640, 480], // VGA default              
                date_created: housing.date_created ? dayjs(housing.date_created).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                date_updated: housing.date_updated ? dayjs(housing.date_updated).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                camera_macro_focus_required: housing.camera_macro_focus_required || true,
                is_active: housing.is_active
            }
            : {
                // id: '',
                name: '',
                description: '',
                organisation: authData.organisation,
                max_conformance_probability: 0.85,
                min_conformance_probability: 0.5,
                point_model: null,
                classifier: null,
                image: null,
                reader_resolution: [640, 480],
                camera_macro_focus_required: true,
                is_active: true
            },
        validationSchema,
        onSubmit: async (values) => {

            const formData = new FormData();

            if (create) {
                formData.append('name', values.name);
                formData.append('description', values.description);
                formData.append('organisation', values.organisation);
                formData.append('max_conformance_probability', values.max_conformance_probability);
                formData.append('min_conformance_probability', values.min_conformance_probability);
                formData.append('camera_macro_focus_required', values.camera_macro_focus_required);
                formData.append('is_active', values.is_active);

                // Convert reader_resolution id to array format and append
                formData.append('reader_resolution', JSON.stringify(values.reader_resolution));

                // Append files
                if (values.point_model) formData.append('point_model', values.point_model);
                if (values.classifier) formData.append('classifier', values.classifier);
                if (values.image) formData.append('image', values.image);

            } else {
                if (authData.isAdmin) {
                  formData.append('is_active', values.is_active);
                }
            }

            const url = housing ? `housing/${housing.id}/` : `housing/`
            const method = housing ? 'patch' : 'post'

            try {
                await api ({
                    method,
                    url,
                    data: formData,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setErrors(null);
                console.log("Housing successfully updated")
                if (method === 'post') {
                    handleRefreshButton() // reset states and close popup
                }
                const count = HousingTableParameterStorageService.get(HousingSessionStorageKey.count)
                HousingTableParameterStorageService.set(HousingSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                console.error("Error updating housing", error);

                if (error.response) {
                    console.log("Response data:", error.response.data);
                    console.log("Response status:", error.response.status);
                    console.log("Response headers:", error.response.headers);
                } else if (error.request) {
                    console.log("Request error, no response:", error.request);
                } else {
                    console.log("Error setting up request:", error.message);
                }


                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating housing`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                // window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    const steps = [
        'Describe It',
        'Assign Model',
        'Assign Classifier',
        'Configure Reader',
        'Save',
    ];


    // Function to determine the current active step
    const getActiveStep = () => {
        if (!formik.values.name || !formik.values.description) return 0;
        if (!formik.values.point_model) return 1;
        if (!formik.values.classifier) return 2;
        if (!formik.values.reader_resolution || 
            typeof formik.values.min_conformance_probability !== 'number' || 
            typeof formik.values.max_conformance_probability !== 'number') return 3;
        return 4; // All previous steps are complete, move to Save step
    };

    const activeStep = getActiveStep();
    const isSaveStep = activeStep === steps.length - 1;


    // Handlers for uploaded files
    const handlePointModelChange = async (file: File) => {
        const fileContent = await file.text(); // Read file content as text
        const jsonData = JSON.parse(fileContent); // Parse JSON content
        setGraphData(jsonData); // Set parsed JSON as graphData
        formik.setFieldValue('point_model', file); // Update form field if needed
      };
    const handleClassifierChange = (file: any) => formik.setFieldValue('classifier', file);
    const handleImageChange = (file: File) => {
        formik.setFieldValue('image', file);
        // Create a preview URL for the image
        const imageUrl = URL.createObjectURL(file);
        setImage(imageUrl);
        // Clean up the URL when the component unmounts or a new file is selected
        return () => URL.revokeObjectURL(imageUrl);
    };

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={4} direction="column" alignItems="center" sx={{ m: 0, height: "100%", width: '100%', overflowY: "auto", px: {xs: 8, xl: 20}}}>                           
                        
                        { create &&
                         <Grid item xs={12} md={2} sx={{height: "100%", width: '100%'}}>
                          <Grid container sx={{ gap: 4, height: "100%", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: 36, fontWeight: "bold" }}>Create Housing</Typography>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{mr: {xs: -8, xl: -8}}}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                </Grid>
                           </Grid>
                           </Grid>
                        }   

                           <Grid item xs={12} md={2} sx={{height: "100%", width: '100%' }}>
                           
                           {errors && (
                                <Alert severity="error" style={{ width: "59.5%" }}>
                                    <AlertTitle>Error</AlertTitle>
                                    {errors}
                                </Alert>
                            )}

                                <Grid container xs={12} spacing={ create ? 5 : 0} direction={{xs: 'column', sm: 'row', md: 'row', lg: 'row'}} sx={{ pr: {xs: 4, md: 0}, gap: 0, display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={3} sx={{}}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Name</Typography>
                                        <Box sx={{pl: 1.5}}>
                                            <Field name="name" type="text" className="form-control" placeholder="Name" component={create ? SmallTextEditField : SmallEditReadOnlyField} style={{marginTop: 8}} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={!create ? 6 : 9} sx={{}}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold'}}>Description</Typography>
                                        <Box sx={{pl: 1.5}}>
                                            <Field name="description" type="text" className="form-control" placeholder="Description" component={create ? SmallTextEditField : SmallEditReadOnlyField} style={{marginTop: 8}} />
                                        </Box>
                                    </Grid>
                                    { !create  && 
                                    <Grid item xs={1} sx={{}}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>ID</Typography>
                                        <Box sx={{pl: 1.5}}>
                                            <Field name="id" type="text" className="form-control" placeholder="ID" component={SmallEditReadOnlyField}  style={{marginTop: 8}} /> 
                                        </Box>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12}  sx={{height: "100%", width: '100%', }}>
                               
                                <Grid container xs={12} spacing={0} justifyContent={'space-between'} direction={{xs: 'column', sm: 'column', md: 'row'}} sx={{ height: "100%", width: '100%'}}>    
                                    <Grid item xs={12} sm={12} md={7.5} direction={'column'}>    
                                       
                                        <Grid container xs={12} sm={12}  md={12}  lg={11}  xl={10} spacing={1} direction={{xs: 'column', sm: 'row'}} sx={{ pr: {xs: 4, md: 0}, display: 'flex', justifyContent: 'space-between', height: "100%", width: '100%', }}>    
                                        <Grid item>
                                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }}>Full Image</Typography>
                                            <Box sx={{ pl: 1.5 }}>
                                                {!create && housing ? (
                                                    <FileDownloadCard url={housing.image} authData={authData} fileUrl={`housing/${housing.id}/download-image/`} />
                                                ) : (
                                                    <FileUploadCard title="Full Image" handleChange={handleImageChange} name="image" types={['JPG', 'PNG', 'JPEG']} />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }}>Model</Typography>
                                            <Box sx={{ pl: 1.5 }}>
                                                {!create && housing ? (
                                                    <FileDownloadCard url={housing.point_model} authData={authData} fileUrl={`housing/${housing.id}/download-point-model/`}/>
                                                ) : (
                                                    <FileUploadCard title="Model" handleChange={handlePointModelChange} name="point_model" types={['JSON']} />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }}>Classifier</Typography>
                                            <Box sx={{ pl: 1.5 }}>
                                                {!create && housing ? (
                                                    <FileDownloadCard url={housing.classifier} authData={authData} fileUrl={`housing/${housing.id}/download-classifier/`} />
                                                ) : (
                                                    <FileUploadCard title="Classifier" handleChange={handleClassifierChange} name="classifier" types={['JSON']} />
                                                )}
                                            </Box>
                                        </Grid>

                                        </Grid>
                                    </Grid>

                                

                                    <Grid item xs={12} sm={12} md={4.5}>
                                            <Grid item xs={12} direction="column" sx={{ flexGrow: 1 }}>
                                                <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 0.5 }}>Reader Parameter</Typography>
                                                <Grid container  xs={12} spacing={0} alignItems="center" sx={{ pl: 1}}>
                                                    <Grid item xs={6} sx={{minHeight: '2em'}}>
                                                        <Typography sx={{ minHeight: '2em', display: 'flex', alignItems: 'center' }}>Camera Macro Focus Required</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                    <Switch
                                                        edge="end"
                                                        checked={formik.values.camera_macro_focus_required}
                                                        onChange={() => {
                                                            if (create) {
                                                                formik.setFieldValue('camera_macro_focus_required', !formik.values.camera_macro_focus_required);
                                                            }
                                                        }}
                                                        inputProps={{ 'aria-labelledby': 'switch-list-label-camera-macro-focus' }}
                                                        sx={{ my: 0 }}
                                                    />
                                                    </Grid>

                                                    <Grid item xs={6} sx={{minHeight: '2ems'}}>
                                                        <Typography sx={{ minHeight: '2ems', display: 'flex', alignItems: 'center' }}>Reader Resolution </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                        <Field
                                                            name="reader_resolution"
                                                            component={create ? HousingResolutionSelectField : HousingResolutionReadOnlySelectField}
                                                            resolutionOptions={resolution}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography sx={{ minHeight: '2ems', display: 'flex', alignItems: 'center' }}>Test PMF Min Conformance Probability</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                        <Field
                                                            name="min_conformance_probability"
                                                            type="number"
                                                            component={create ? SmallTextEditField : SmallEditReadOnlyField}
                                                            style={{maxWidth: '80px'}}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sx={{minHeight: '2ems'}}>
                                                        <Typography sx={{ minHeight: '2ems', display: 'flex', alignItems: 'center' }}>Test PMF Max Conformance Probability</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                        <Field
                                                            name="max_conformance_probability"
                                                            type="number"
                                                            component={create ? SmallTextEditField : SmallEditReadOnlyField}
                                                            style={{maxWidth: '80px'}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>
                            <Grid item xs={12}  sx={{height: "100%", width: '100%', pb: 5 }}>
                               <Grid container xs={12} justifyContent={'space-between'} sx={{ height: "100%", width: '100%'}}>    
                                    <Grid item xs={12} sm={10} sx={{ height: "100%", width: '100%'}} > 
                                        <Box sx={{width: '100%', height: '100%', display: 'flex', gap: 5, pl:1.5}}>
                                        { image  && 
                                        <Box
                                            component="img"
                                            src={image}
                                            alt="Full Image"
                                            sx={{
                                                borderRadius: 1,
                                                maxWidth: '400px',
                                                maxHeight: '240px',
                                                height: 'auto',
                                                objectFit: 'contain',
                                            }}
                                        />
                                        }   
                                        <Box sx={{maxWidth: "520px", maxHeight: "240px", minWidth: "520px", minHeight: "240px"}}>
                                            <CartesianGrid graphdata={graphData} create={create} />
                                        </Box>
                                        </Box>
                                    </Grid>


                                    <>
                                    { create &&
                                        <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={1}
                                        direction="row"
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignSelf: "flex-end",
                                            gap: 2 // Add gap between buttons
                                        }}
                                    >           
                                        <Button
                                            variant="outlined"
                                            onClick={() => formik.resetForm()} // Reset form values to initial
                                            sx={{
                                                textTransform: 'none',
                                                fontSize: 16,
                                                mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                                minWidth: '140px',
                                                maxWidth: '140px',
                                            }}
                                        >
                                            Reset
                                        </Button>         
                                        <Tooltip title={!isSaveStep ? "Complete all steps to Save" : ""}>
                                            <span>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={!isSaveStep}
                                                    sx={{
                                                        color: "white",
                                                        textTransform: 'none',
                                                        fontSize: 16,
                                                        mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                                        minWidth: '140px',
                                                        maxWidth: '140px',
                                                        '&.Mui-disabled': { color: "white", opacity: 0.5 },
                                                    }}
                                                >
                                                    Save Changes
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                               
                                    }
                                        {!create && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={1}
                                                direction="row"
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignSelf: "flex-end",
                                                }}
                                                >
                                                <Box
                                                    sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    p: 0.5,
                                                    pr: 2,
                                                    minWidth: '150px',
                                                    maxWidth: '150px',
                                                    borderRadius: 1.1,
                                                    border: '1px solid',
                                                    textAlign: 'center',
                                                    mt: {xs: 3, sm:3, md: 3, lg: 0}
                                                    }}
                                                >
                                                    <Switch
                                                    checked={formik.values.is_active}
                                                    onChange={handleSwitchToggle}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                        color: colorConfigs.buttons.active,
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                        backgroundColor: colorConfigs.buttons.active,
                                                        },
                                                    }}
                                                    />
                                                    <Typography sx={{ ml: 1 }}>
                                                    {formik.values.is_active ? "Active" : "Inactive"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </>
                               </Grid>
                            </Grid>
                </Grid>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} sx={{ mb: '-10px' }} >
                    <MuiAlert onClose={() => setIsSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </form>
        </FormikProvider>
    );
};

export default HousingDetailCard;