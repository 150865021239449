import { Card, Grid, Typography, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {  TextEditField, TextEditReadOnlyField, OrganisationSelectField, UserTypeSelectField, SubscriptionSelectField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { OrganisationSessionStorageKey, OrganisationTableParameterStorageService } from '../../../services/sessionStorage';

interface OrganisationDetailProps {
  organisation: any;
  create: boolean;
  handleRefreshButton: () => void;
  handleBackButton: () => void;
}

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const OrganisationDetailCard: React.FC<OrganisationDetailProps> = ({organisation, create, handleRefreshButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<any>({})
    const [updatedValues, setUpdatedValues] = useState<any>({})
    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)
    const [isActiveChecked, setIsActiveChecked] = useState(false);

    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && organisation) ? {
                id: organisation.id || '',
                name: organisation.name || '',
                reader_config: organisation.reader_config || '',
                subscription: organisation.subscription || '',
                date_created: organisation.date_created ? dayjs(organisation.date_created).format('YYYY-MM-DD HH:mm:ss') : null,
                is_active: organisation.is_active || false,
            }
            : {
                id: '',
                name: '',
                reader_config: '',
                subscription: '',
                tier: '',
                date_created: dayjs(),
                is_active: true,
            },
        validationSchema,
        onSubmit: async (values) => {

            const data = authData.isAdmin ? {
                name: values.name,
                // reader_config: values.reader_config,
                tier: values.subscription ? values.subscription - 1 : '',
                is_active: values.is_active,
            } : {
                name: values.name,
                is_active: values.is_active,
            }

            const url = organisation ? `organisations/${organisation.id}/` : `organisations/`
            const method = organisation ? 'patch' : 'post'

            try {
                console.log("data", data)
                await api ({
                    method,
                    url,
                    data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                    },
                });
                setErrors(null);
                console.log("Organisation successfully updated")
                handleRefreshButton() // reset states and close popup
                const count = OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.count)
                OrganisationTableParameterStorageService.set(OrganisationSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating lot`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    //  Set checkbox state
    useEffect(() => {
        setIsActiveChecked(formik.values.is_active)
    }, [organisation, formik.values.is_active]);

  

    // Effect to set initial form values from organisation to check for form changes
    useEffect(() => {
        setTimeout(() => {
            setInitialValues(formik.initialValues);
            setUpdatedValues(formik.initialValues);
        }, 500);
        setHasMadeChanges(false);
    }, [organisation]);

    // Update the updatedValues state when formik values change
    useEffect(() => {
        setUpdatedValues(formik.values);
    }, [formik.values]);

    // Function to check if form has changed so the save button becomes active on edit
    const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
        for (const key in formValues) {
            if (key === "expiry_date") {
                const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                const initialDate = create ? dayjs(flatModel[key]).format('YYYY-MM-DD') : dayjs(formValues[key]).format('YYYY-MM-DD');
                if (formDate !== initialDate) {
                    console.log("Changed Date", formDate, initialDate)
                    return true;
                }
            } else {
                if (formValues[key]!= flatModel[key]) {
                    console.log("Changed Data", formValues[key], flatModel[key])
                    return true;
                }
            }
        }
        return false;
    };
            
    // Use Effect to check if any changes ahve been made to the form on Edit
    useEffect(() => {
        if (!create && organisation) {
            if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                const formChanged = hasFormChanged(initialValues, updatedValues);
                setHasMadeChanges(formChanged);
            }
        }
    }, [updatedValues, initialValues, organisation]);
    
    // Use Effect set save button active on load if Create and if changes are made on Edit
    useEffect(() => {
        if (create) {
            setHasMadeChanges(true);
        } else {
            setHasMadeChanges(false);
        }
    }, [create]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto'}}>
            <Card sx={{ height: "100%", boxShadow: 10, p:2, marginX: 5, marginY: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {errors && (
                <Alert severity="error" style={{ width: "59.5%" }}>
                    <AlertTitle>Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                    <div className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography variant="h6" gutterBottom sx={{color: '#6987C9', fontWeight: 'bold'}}>                                            
                                            Organisation
                                        </Typography> 
                                        {!create && <Field name="id" disableds type="text" className="form-control" label="Organisation ID" component={TextEditReadOnlyField}  />}
                                        {!create && <Field name="date_created" type="text" className="form-control" label="DateCreated" component={TextEditReadOnlyField} />}
                                        <Field name="name" type="text" className="form-control" label="Organisation Name" component={TextEditField} />
                                        {authData.isAdmin ? 
                                            <Field name="subscription" type="text" className="form-control" label="Subscription" component={SubscriptionSelectField} />
                                            :
                                            <Field name="subscription" type="text" className="form-control" label="Subscription" component={TextEditReadOnlyField} />
                                        }
                                        {/* <Field name="user_type" type="text" className="form-control" label="User Type" component={UserTypeSelectField} /> */}
                                        <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isActiveChecked} name="is_active"
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                    setIsActiveChecked(!isActiveChecked)
                                                    formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                      Organisation is
                                                      <Typography 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isActiveChecked ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isActiveChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isActiveChecked ? 'Active' : 'Inactive'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />
                                        </div>
                                </Card>
                            </Grid>
                            {!create && 

                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                <div className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography variant="h6"  sx={{color: '#6987C9', fontWeight: 'bold'}} gutterBottom>
                                    Organisation Stats
                                </Typography> 
                                {/* <Field name="date_joined" type="text" className="form-control" label="Date Joined" component={TextEditReadOnlyField} /> */}
                                {/* <Field name="last_login" type="text" className="form-control" label="Last Login" component={TextEditReadOnlyField} /> */}
                                {/* <Box sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                    <Field name="total_active_dogs" type="text" className="form-control" label="Total Active Dogs" component={TextEditReadOnlyField} />
                                    <Field name="total_dogs" type="text" className="form-control" label="Total Dogs" component={TextEditReadOnlyField} />
                                </Box>
                                <Box sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                    <Field name="total_cycles" type="text" className="form-control" label="Total Cycles" component={TextEditField} />
                                    <Field name="total_results" type="text" className="form-control" label="Total Results" component={TextEditReadOnlyField} />
                                </Box> */}
                                </div>
                                </Card>
                            </Grid>
                                }
                            </Grid>
                    </div>
                    <div className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Button
                            className="form-group"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            sx={{
                                position: "relative",
                                m: 1,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className="form-group"
                            type="submit"
                            variant="contained"
                            disabled={!hasMadeChanges}
                            sx={{
                                type:"submit",
                                position: "relative",
                                m: 1,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                                animation: hasMadeChanges ? 'growShrink 0.5s infinite alternate' : 'none',
                                '&.Mui-disabled': {
                                    color: "white", // Text color when disabled
                                    opacity: 0.5,
                                },
                            }}
                        >
                            Save Changes
                        </Button>
                    </div>
                </Card>
            </form>
        </FormikProvider>
    )
}

export default OrganisationDetailCard;