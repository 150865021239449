import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Badge, Box, Button, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Tooltip, Typography, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { UserSessionStorageKey, UserSessionStorageModel, UserTableParameterStorageService } from "../../../services/sessionStorage";
import RefreshIcon from '@mui/icons-material/Refresh';
import colorConfigs from "../../../configs/colorConfigs";
import { Link as RouterLink }from 'react-router-dom';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import { tsXLXS } from 'ts-xlsx-export';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface UsersTableProps {
    rows: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setUser: (detail: any) => void;
    setPopup: (detail: any) => void;
    handleRefreshButton: () => void;
  }

const UsersTable: React.FC<UsersTableProps> = ({
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setUser,
  setPopup,
  handleRefreshButton
}) => {
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            ID<br />&nbsp;
            </div>, 
        },  
        {
            accessorKey: 'user_type',
            header: "User Type",
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            User<br />Type
                        </div>,
        },
        {
            accessorKey: 'first_name',
            header: 'First Name',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            First<br />Name
                        </div>, 
        },
        {
            accessorKey: 'last_name',
            header: 'Last Name',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Last<br />Name
                        </div>, 

        },
        {
            accessorKey: 'email',
            header: 'Email',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Email<br />&nbsp;
            </div>, 
        },
        {
            accessorKey: 'organisation',
            header: 'Organisation',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Organisation<br />ID
            </div>, 
        },
        {
          accessorKey: 'organisation_name',
          header: 'Organisation Name',
          Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Organisation<br />Name
          </div>, 
      },
        {
            accessorKey: 'subscription_tier',
            header: 'Subscription Tier',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Subscription<br />Tier
            </div>, 
        },
        {
            accessorFn: (row) => dayjs(row.date_joined).format('YYYY-MM-DD HH:mm:ss'),
            header: 'Created',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Created<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
              ),
        },
        {
            accessorFn: (row) => row.last_login ?  dayjs(row.last_login).format('YYYY-MM-DD HH:mm:ss'): row.last_login,
            header: 'Last Login',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Last<br />Login
                        </div>, 
            Cell: ({ cell }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
                ),
        },
        {
            accessorKey: 'is_active',
            header: 'Active',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Active<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div style={{display: "flex", justifyContent: "center"}}>
                  {cell.getValue()?.toString() == "true" ? <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>: <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography>}
                </div>
              ),
            filterVariant: 'checkbox',
        },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type UserSessionStorageKeyType = keyof UserSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: UserSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        UserTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(UserSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(UserSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(UserSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(UserSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(UserSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(UserSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);


    return <MaterialReactTable
            columns={columns}
            data={rows}
            state={{
              isLoading,
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              showGlobalFilter
            }}
            onSortingChange={setSortingFunction}
            onColumnVisibilityChange={setColumnVisibilityFunction}
            onColumnFiltersChange={setFilterChangeFunction}
            onPaginationChange={setPaginationChangeFunction}
            onGlobalFilterChange={setGlobalFilterChangeFunction}
            onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
            defaultColumn={{    
              minSize: 10,
              maxSize: 160,
              size: 10,
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"
            // enableRowActions
            initialState={{
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              density: 'compact',
              columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
            }}

            // TABLE STYLING IS IN THE THEME
            displayColumnDefOptions={{
              'mrt-row-actions': {
              header: "Actions", // Renames Actions Column
              },
          }}

          muiTopToolbarProps={({ table }) => ({
              sx: (theme: any) => ({
                ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
              }),
            })}

          muiTableHeadProps={{
              sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
            }}

          muiTableHeadCellProps={({ column }) => ({
              sx: (theme: any) => ({
                ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
                ...(column.id === 'mrt-row-select' && {
                  maxWidth: '50px',
                  width: '50px',
                }),
              }),
            })}

          muiFilterTextFieldProps={{
              sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
            }}

          muiTableBodyRowProps={({ row }) => ({
              onClick: (event) => {
                  console.log(row.original);
                  setUser(row.original);
                  setPopup('Edit');
              },
              sx: (theme: any) => ({
                  ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
                  backgroundColor:
                  row.index % 2 === 0
                      ? colorConfigs.tables.firstRow
                      : colorConfigs.tables.secondRow,
              }),
              })}

          muiTableBodyProps={{
              sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
              }}
          
          muiPaginationProps={{
                rowsPerPageOptions: [250, 500, 1000],
                sx: {
                    padding: 0,  
                    margin: 0,   
                    '& .MuiTablePagination-root': {
                    padding: 0,  
                    margin: 0,   
                    },
                    '& .MuiToolbar-root': {
                    padding: 0,
                    margin: 0,
                    minHeight: '0px',
                    },
                    '& .MuiTablePagination-selectLabel': {
                    margin: 0,
                    },
                    '& .MuiTablePagination-displayedRows': {
                    margin: 0,
                    },
                    '& .MuiTablePagination-actions': {
                    margin: 0,
                    },
                },
                }}

          muiTableBodyCellProps={({ cell }) => ({
              // TABLE BODY CELLS
              sx: {
              },
          })}

          muiBottomToolbarProps={{
            // BOTTOM TOOLBAR (Pagination)
            sx: {
              height: '41px',
              maxHeight: '41px',
              minHeight: '41px',
              position: 'sticky',
              bottom: 0,
              zIndex: 1,
              backgroundColor: '#fff',
              boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)', 
              margin: 0,
              padding: 0,
              '& .MuiToolbar-root': {
                padding: 0,
                margin: 0,
                paddingBottom: 0,
                paddingTop: 0,
              },
              '& .MuiBox-root': {
                p: 0,
              },
              '& .MuiIconButton-root': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiTablePagination-root': {
                margin: 0,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                color: colorConfigs.buttons.bg,
              },
              '& .MuiTablePagination-selectLabel': {
                color: colorConfigs.tables.headBg,
                padding: 0,
                margin: 0,
              },
              '& .MuiTablePagination-displayedRows': {
                color: colorConfigs.buttons.bg,
                padding: 0,
                margin: 0,
              },
              '& .MuiTablePagination-actions': {
                color: colorConfigs.tables.headBg,
                padding: 0,
                margin: 0,
              },
            },
          }}

          renderTopToolbarCustomActions={({ table }) => {

            const handleArchiveCycles = () => {
                table.getSelectedRowModel().flatRows.map(row => {
                    //Add archive logic
                });
            };

            const handleExportRows = (rows: any[]) => {
              const excelRows = [];
              const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
              const filename = `${now}_UserList`;
            
              for (let x = 0; x < rows.length; x++) {
                const user = rows[x].original;
                const formattedDateJoined = dayjs(user.date_joined).format('YYYY-MM-DD HH:mm:ss');
                const formattedLastLogin = user.last_login ? dayjs(user.last_login).format('YYYY-MM-DD HH:mm:ss') : '';
                
                excelRows[x] = {
                  "ID": user.id,
                  "User Type": user.user_type,
                  "First Name": user.first_name,
                  "Last Name": user.last_name,
                  "Email": user.email,
                  "Organisation ID": user.organistation || '',
                  "Organisation Name": user.organistation_name || '',
                  "Subscription Tier": user.subscription_tier || '',
                  "Date Created": formattedDateJoined,
                  "Last Login": formattedLastLogin,
                  "Active": user.is_active ? "Yes" : "No",
                  // "Units": user.units,
                  // "Username": user.username || '',
                  // "Locale": user.locale || '',
                  // "Total Active Dogs": user.total_active_dogs,
                  // "Total Dogs": user.total_dogs,
                  // "Total Cycles": user.total_cycles,
                  // "Total Results": user.total_results,
                };
              }
            
              tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
            };
                

                return (
                  <Box style={{ display: 'flex', gap: '0.3rem', height: "40px" }}>   
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<FileDownloadOutlinedIcon />}
                      sx={{  minHeight: "100%", minWidth: "7.5em", background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold"}}
                    >
                      Export Data
                    </Button>
                    <Button 
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={() => handleRefreshButton()}
                        sx={{ minHeight: "100%", textTransform: 'none', background: colorConfigs.buttons.bg, color: "#fff", transition: 'transform 0.3s ease', fontWeight: "bold"}}
                        >
                        <RefreshIcon sx={{ p: 0, m: 0 }} />
                    </Button>
                    <Button
                      fullWidth
                      onClick={() => setPopup("Create")}
                      variant="contained"
                      id="demo-customized-button3"
                      disableElevation
                      endIcon={<GroupAddOutlinedIcon />}
                      sx={{ 
                        background: colorConfigs.buttons.bg, 
                        color: 'white', 
                        minWidth: "19.85em",
                        width: "fit-content",
                        minHeight: "100%",
                        textTransform: 'none', 
                        transition: 'transform 0.3s ease', 
                        fontWeight: 'bold', 
                      }}                
                    >
                      Create User
                    </Button>
                    <Button
                        id="demo-customized-button1"
                        variant="contained"
                        disableElevation
                        onClick={() => handleRemoveAllFilters()}
                        endIcon={<FilterAltOffOutlinedIcon />}
                        sx={{ 
                          minHeight: "100%",
                          minWidth: "5em",
                          background: colorConfigs.buttons.bg, 
                          color: "white", 
                          textTransform: 'none', 
                          transition: 'transform 0.3s ease', 
                          fontWeight: "bold"
                        }}
                      >
                        Clear Filters
                      </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                      } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                        Export to Excel
                      </MenuItem>
                    </StyledMenu>
                    </Box>
                );
            }}
            
            renderRowActions={({ row, table }) => (

                <Box sx={{ gap: '0.5rem', display: "flex" }}>
                {/* <Tooltip     
                    arrow
                    placement="left"
                    title="Cycles"
                    > */}
                    <Badge
                    badgeContent={row.original.total_cycles}
                    color={row.original.total_cycles ? 'primary' : 'default'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                    '& .MuiBadge-badge': {
                        top: '13px',
                        right: '10px',
                        background: colorConfigs.tables.headBg,
                        opacity: 0.98
                    },
                    }}
                    overlap="circular"
                    >
                    <IconButton
                        disabled={!row.original.total_cycles}
                        component={RouterLink}
                        to={`/cycles?user_id=${row.original.id}`}
                        state={{ clinicID: row.original.id }}
                        sx={{transition: 'transform 0.3s ease'}}
                    >
                        <RotateRightOutlinedIcon fontSize="large" sx={{color: row.original.total_cycles ? colorConfigs.sidebar.color: colorConfigs.sidebar.bg}} />
                    </IconButton>
                    </Badge>
                {/* </Tooltip> */}
                </Box>
              )}
        />
}
 
export default UsersTable;

