import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { login, getProfile, refreshToken, isLoggedIn } from './authService';
import { RootState } from '../../store';
import { clearAllSessionStorage } from '../../../services/sessionStorage';

export interface Profile {
    id: any;
    address: any;
    country: any;
    result_filters: any[];
    feedback_filters: any[];
    user_filters: any[]
    organisation_filters: any[]
    subscription_filters: any[]
}
export interface UserProfile {
    id: number | undefined;
    first_name: string;
    last_name: string;
    email: string;
    organisation: number | undefined;
    organisation_name: string;
    subscription_tier: number;
    is_superuser: boolean;
    is_staff: boolean;
    is_active: boolean;
    date_joined: string;
    last_login: string;
    user_type: string;
    profile: Profile;
}

export interface AuthState {
    access: string | null;
    isAuthenticated: boolean;
    user: UserProfile | null;
    loading: boolean;
    error: string | null;
    isSuperUser: boolean;
    isFreeUser: boolean;
    isStandardUserAnyTier: boolean;
    isStandardUserTier1: boolean;
    isStandardUserTier2: boolean;
    isStandardUserTier3: boolean;
    isOrganisationAdminAnyTier: boolean;
    isOrganisationAdminTier1: boolean;
    isOrganisationAdminTier2: boolean;
    isOrganisationAdminTier3: boolean;
    isAdmin: boolean;
    organisation: string | null;
    tier: any;
    refreshing: boolean;
}

interface LoginPayload {
    username: string;
    password: string;
}

interface LoginResponse {
    access: string;
}

const initialState: AuthState = {
    access: null,
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
    isSuperUser: false,
    isFreeUser: false,
    isStandardUserAnyTier: false,
    isStandardUserTier1: false,
    isStandardUserTier2: false,
    isStandardUserTier3: false,
    isOrganisationAdminAnyTier: false,
    isOrganisationAdminTier1: false,
    isOrganisationAdminTier2: false,
    isOrganisationAdminTier3: false,
    isAdmin: false,
    organisation: null,
    tier: null,
    refreshing: false,
};

// Login User and save access and refresh tokens in auth state
export const loginUser = createAsyncThunk<LoginResponse, LoginPayload, { state: RootState }>(
    'auth/loginUser',
    async ({ username, password }, { dispatch, rejectWithValue }) => {
        try {
            const data = await login(username, password);
            const { access } = data;

            dispatch(setCredentials({ access }));
            await dispatch(fetchUserProfile()).unwrap();

            return { access };
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Unknown error during login');
        }
    }
);

// Handles refresh token and saves new access and refresh tokens in auth state
export const refreshAccessToken = createAsyncThunk<void, void, { state: RootState }>(
  'auth/refreshAccessToken',
  async (_, { getState, dispatch, rejectWithValue }) => {

    const state = getState() as RootState;
    const access = state.auth.access;

    if (!access || !isLoggedIn(access)) {
      if (state.auth.refreshing) {
        console.log("refreshAccessToken already refreshing, skip refresh");
        return rejectWithValue('Refresh token in process');
      }
      else{
        console.log("refreshAccessToken refreshing...");
      }
      dispatch(refreshAccessTokenTrue());
      try {
        const data = await refreshToken();  // Attempts to refresh the token
        
        if (data.access) {
          dispatch(setCredentials({ access: data.access }));
          console.log("refreshAccessToken SUCCESS ...", data.access ? data.access.slice(-6): data.access);
          return;  // Exit function, refresh was successful
        } else {
          dispatch(setIsAuthenticated(false));
          console.error("refreshAccessToken ERROR", data);
          return rejectWithValue(data.error);  // Ignore specific errors but exit early
        } 
      } catch (error) {
        console.error("refreshAccessToken CATCHED ERROR:", error);
        // Handle any error during the refresh process and mark user unauthenticated
        dispatch(setIsAuthenticated(false));  // Set isAuthenticated to false
        return rejectWithValue(error);
      }
      finally {
        dispatch(refreshAccessTokenFalse());
      }
    }
  }
);


// Handles adding profile information to auth state.  Happens during LoginUser above
export const fetchUserProfile = createAsyncThunk<UserProfile, void, { state: RootState }>(
  'auth/fetchUserProfile',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const access = state.auth.access;

    if (access) {
      try {
        const userProfile = await getProfile(access);
        return userProfile;
      } catch (error: any) {
        return rejectWithValue(error.response?.data || 'Unknown error fetching user profile');
      }
    } else {
      return rejectWithValue('Access token not found');
    }
  }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<{ access: string}>) => {
            state.access = action.payload.access;
            state.isAuthenticated = true;
        },
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        logout: (state) => {
            console.log("logout: clearing local storage")
            localStorage.clear();
            clearAllSessionStorage();
            Object.assign(state, initialState);
        },
        refreshAccessTokenTrue: (state) => {
          state.refreshing = true;
        },
        refreshAccessTokenFalse: (state) => {
          state.refreshing = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload as string | null;
                state.loading = false;
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.isSuperUser = action.payload.is_superuser;
                state.isFreeUser = action.payload.user_type === "STANDARD_USER" && !action.payload.subscription_tier;
                state.isStandardUserAnyTier= action.payload.user_type === "STANDARD_USER" && Boolean(action.payload.subscription_tier);
                state.isStandardUserTier1 = action.payload.user_type === "STANDARD_USER" && action.payload.subscription_tier == 1;
                state.isStandardUserTier2 = action.payload.user_type === "STANDARD_USER" && action.payload.subscription_tier == 2;
                state.isStandardUserTier3 = action.payload.user_type === "STANDARD_USER" && action.payload.subscription_tier == 3;
                state.isOrganisationAdminAnyTier = action.payload.user_type === "ORGANISATION_ADMIN" && Boolean(action.payload.subscription_tier);
                state.isOrganisationAdminTier1 = action.payload.user_type === "ORGANISATION_ADMIN"  && action.payload.subscription_tier == 1;
                state.isOrganisationAdminTier2 = action.payload.user_type === "ORGANISATION_ADMIN"  && action.payload.subscription_tier == 2;
                state.isOrganisationAdminTier3 = action.payload.user_type === "ORGANISATION_ADMIN"  && action.payload.subscription_tier == 3;
                state.isAdmin = action.payload.user_type === "ADMIN_USER";
                state.organisation = action.payload.organisation?.toString() ?? null;
                state.tier = action.payload.subscription_tier;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.error = action.payload as string | null;
            })
            .addCase(refreshAccessToken.fulfilled, (state) => {
                // Handle successful token refresh if needed
            })
            .addCase(refreshAccessToken.rejected, (state, action) => {
                state.isAuthenticated = false; // Mark user as unauthenticated if refresh fails
                state.error = action.payload as string | null;
            });

    },
});

export const { setCredentials, setIsAuthenticated, logout, refreshAccessTokenTrue, refreshAccessTokenFalse} = authSlice.actions;
export default authSlice.reducer;

// SNIPPETS TO USE

// FETCH AUTH DATA
// const dispatch = useDispatch<AppDispatch>();
// const authData = useSelector((state: RootState) => state.auth); // Accessing the auth state
// console.log(authData.access)
// console.log(authData.user.id)

// LOGOUT
// const dispatch = useDispatch<AppDispatch>();
//     const handleLogout = () => {
//     dispatch(logout());
// };

// LOGIN
// const dispatch = useDispatch<AppDispatch>();
// const resultAction = await dispatch(loginUser({ username, password }));
// unwrapResult(resultAction); // If the promise is rejected, this will throw and you wont proceed

// AUTHENTICATION CHECK - No longer Needed
// const dispatch = useDispatch<AppDispatch>();
// useEffect(() => {
//   if (!authData.isAuthenticated) {
//       navigate(loginpage);
//       return;
//   }
//   if (!isLoggedIn()) {
//       dispatch(refreshAccessToken());
//   }
// }, [dispatch, navigate, authData.isAuthenticated]);