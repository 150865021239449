import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import api from '../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { OrganisationSessionStorageKey, OrganisationTableParameterStorageService } from '../../services/sessionStorage';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../configs/colorConfigs';
import OrganisationsTable from '../../components/common/tables/OrganisationsTable';
import OrganisationFilterBar from '../../components/common/filterbars/OrganisationFilterBar';
import OrganisationDetailCard from '../../components/common/cards/OrganisationDetailCard';
import DownloadProgressBar from '../../components/common/cards/DownloadProgressBar';
import sizeConfigs from '../../configs/sizeConfigs';
import fontConfigs from '../../configs/fontConfigs';

type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const OrganisationsPage = (props: Props) => {

const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [organisation, setOrganisation] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [totalCount, setTotalCount] = useState<any>(0); 
const [totalCountLoaded, setTotalCountLoaded] = useState<any>(0); 

const [sorting, setSorting] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.showSearch) || false
});

const [rows, setRows] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.data) || []
  });
  
const [queryString, setQueryString] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.currentQueryString) || ""
});

const handleRefreshButton = () => {
  setOrganisation("")
  fetchData(queryString)
  setPopup("")
}

const handleBackButton = () => {
  setOrganisation("")
  setPopup("")
}

const fetchData = async (query = "") => {
    setIsLoading(true);
    const data: any[] = [];
    const downloadPageSize = 250;  // Set the batch size to 250
    let downloadOffset = 0;
    let total = 0;
  
    try {
        const url = query ?  `organisations/?${query}&limit=${downloadPageSize}&offset=${downloadOffset}` : `organisations/?limit=${downloadPageSize}&offset=${downloadOffset}`
  
        // Fetch the first batch to get the total count
        const initialResponse = await api.get(url, {
            headers: {
                'Authorization': `Bearer ${authData.access}`
            }
        });
        if (initialResponse.data.results.length < 1) {
          console.log("No results for this query")
          return data
        } else {
          data.push(...initialResponse.data.results);
          total = initialResponse.data.count;
          downloadOffset += downloadPageSize;
          setTotalCount(total);
          setTotalCountLoaded(data.length);
          setIsLoading(false);
          setRows([...data]);
          setQueryString(query)
          OrganisationTableParameterStorageService.set(OrganisationSessionStorageKey.currentQueryString, query)
        }
        // Now fetch the remaining lots in the background
        while (data.length < total) {
            const url = query ?  `organisations/?${query}&limit=${downloadPageSize}&skip=${downloadOffset}` : `organisations/?limit=${downloadPageSize}&offset=${downloadOffset}`
  
            const response = await api.get(url, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }
            });
  
            data.push(...response.data.results);
            downloadOffset += downloadPageSize;
            setTotalCountLoaded(data.length);
            console.log(`Downloaded ${data.length} of ${total} organisations`);
        }
  
        // Final update after all lots are fetched
        setRows([...data]);
        console.log('Downloaded all organisations:', data);
  
        OrganisationTableParameterStorageService.set(OrganisationSessionStorageKey.data, data)
        OrganisationTableParameterStorageService.set(OrganisationSessionStorageKey.count, total)
  
        setTimeout(() => { 
          setTotalCount(false)
        }, 6000);
  
    } catch (error) {
        console.error('Error fetching organisations:', error);
    } finally {
      setIsLoading(false);
      return data
    }
  };
  
  useEffect(() => { 
    const dataInStorage = OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.count);
    if ((!dataInStorage || dataInStorage === 0) && (rows.length < 1 || !rows.length)) {
      // No data in storage and no rows loaded, show loading and fetch data
      // setIsLoading(true);
      // fetchData();
    } else if (dataInStorage && rows.length < 1) {
        // Data exists in session storage but rows haven't loaded yet, show loading until rows are ready
        setTotalCount(dataInStorage);
        setTotalCountLoaded(0);
        setIsLoading(true)
        const storedData = OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.data) || [];
  
        const fetchStoredDataInBatches = async () => {
          const batchSize = 1000;
          let batchIndex = 0;
          let allData: any[] = [];
          // Retrieve patients from storage in batches of 250 until all are fetched
          while (batchIndex * batchSize < storedData.length) {
            const batch = storedData.slice(batchIndex * batchSize, (batchIndex + 1) * batchSize);
            if (batchIndex >= 1) {setIsLoading(false)}
            allData = [...allData, ...batch];
            setRows([...allData]);  // Update rows incrementally
            batchIndex++;
            setTotalCountLoaded(allData.length)
            await new Promise(resolve => setTimeout(resolve, 10));
          }
          setTimeout(() => { // Hide progress bar
            setTotalCount(false)
          }, 3000);
          setIsLoading(false);  // All batches loaded, stop loading
        };
    
        fetchStoredDataInBatches();
    } else if (rows.length > 0) {
      // Rows are loaded, stop loading
      setIsLoading(false);
    }
  }, [rows.length, authData.isAuthenticated]);


const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 250, pageIndex: 0 })
  setSorting([])
}

  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated ? (
        <div style={{ overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw`, margin: "auto"}}>

        <div style={{ display: "flex", height: "100%", width: "100%"}}>
  
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{m:0}}
          >
            <Grid item xs={3} sx={{m:0, p:0}}>
              <Box sx={{ m:0, p:2, pt: 0, height: "100%", overflowY: "auto", marginRight: 6, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw` }}>
                  <DownloadProgressBar totalCount={totalCount} totalCountLoaded={totalCountLoaded}/>
                  <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 5, borderTopRightRadius: 3, borderTopLeftRadius: 3 }}>
                  <Box sx={{
                      borderRadius: 1,
                      height: 'calc(100vh - 120px)',
                      display: 'flex',
                      flexDirection: 'column',
                  }}>
                  <OrganisationFilterBar setIsLoading={setIsLoading} handleRemoveAllFilters={handleRemoveAllFilters}  fetchData={fetchData} />
                  <Box sx={{
                    flex: 1,
                    // maxHeight: 'calc(100vh - 295px)',
                    minHeight: 'calc(100vh - 295px)',
                    display: 'flex',
                    flexDirection: 'column',
                    borderTopRightRadius: 50,
                    borderTopLeftRadius: 0,
                    border: 'none',
                    boxShadow: 0,
                    
                }}>                      
                  <OrganisationsTable
                      rows={rows}
                      isLoading={isLoading}
                      sorting={sorting}
                      columnVisibility={columnVisibility}
                      columnFilters={columnFilters}
                      pagination={pagination}
                      globalFilter={globalFilter}
                      showGlobalFilter= {showGlobalFilter}
                      setShowGlobalFilter={setShowGlobalFilter}
                      handleRemoveAllFilters={handleRemoveAllFilters}
                      setGlobalFilter={setGlobalFilter}
                      setSorting={setSorting}
                      setColumnVisibility={setColumnVisibility}
                      setColumnFilters={setColumnFilters}
                      setPagination={setPagination}
                      setOrganisation={setOrganisation}
                      setPopup={setPopup}
                      handleRefreshButton={handleRefreshButton}
                    />
                  </Box>
                  </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={(organisation && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setOrganisation(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              height: '100vh',
              minHeight: '100vh',
            '& .MuiDialog-paper': {
              alignItems: 'center',
              justifyContent: 'center',
              p:0,
              height: '100vh',
              minHeight: '100vh'
            }
            }}
          >
            <AppBar
              onClick={() => {setOrganisation(""); setPopup("")}}
              sx={{
                position: 'fixed',  
                top: 0,             
                height: sizeConfigs.detailPage.appbar.height,
                minHeight: sizeConfigs.detailPage.appbar.height,
                maxHeight: sizeConfigs.detailPage.appbar.height,
                zIndex: 1201,
              }}
            >                  
             <Toolbar
                sx={{
                  height: sizeConfigs.detailPage.appbar.height,
                  minHeight: sizeConfigs.detailPage.appbar.height,
                  maxHeight: sizeConfigs.detailPage.appbar.height,
                  padding: 0,
                  backgroundColor: colorConfigs.tables.headBg,
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                }}
              >   
                {popup &&
                <Typography
                    onClick={(e) => e.stopPropagation()} 
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: fontConfigs.detailPage.appbar.title,
                      fontWeight: "bold",
                      m: "auto",
                      mt: 0,
                      mb: 0,
                    }}
                  >
                     {`${popup} Organisation`}
                    </Typography>  
                }
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setOrganisation(""); setPopup("")}}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    padding: 0,
                    fontSize: '18px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent
                  sx={{
                    height: sizeConfigs.detailPage.dialogContent.height,
                    minHeight: sizeConfigs.detailPage.dialogContent.height,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    minWidth: '100vw',
                    p: 0,
                    m: 0,
                    backgroundColor: colorConfigs.sidebar.bg,
                    mt: sizeConfigs.detailPage.appbar.height,
                  }}
                >    
                {(popup) &&
                <OrganisationDetailCard organisation={organisation} create={popup === "Create"} handleRefreshButton={handleRefreshButton} handleBackButton={handleBackButton}  />
                }
            </DialogContent>
        </Dialog>
    </div>
    
) : (null);
};

export default OrganisationsPage;
