import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import SignInDialog from "../../components/common/SignInDialog";
import api from "../../redux/features/auth/axiosAuthHelper";
import {
  logout,
  refreshAccessToken,
} from "../../redux/features/auth/authSlice";
import colorConfigs from "../../configs/colorConfigs";
import { TransitionProps } from "@mui/material/transitions";
import MethodCard from "../../components/common/cards/MethodCard";

interface MethodCardProps {
  create: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MethodPage = (props: MethodCardProps) => {
  const authData = useSelector((state: RootState) => state.auth);

  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
  ) : authData.isAuthenticated == true && authData.isFreeUser ? (
    <Navigate to="/account/subscription" />
  ) : authData.isAuthenticated == true && authData.isFreeUser === false ? (
    <div
      style={{
        overflowX: "hidden",
        maxWidth: `95vw`,
        minWidth: `85vw`,
        margin: "auto",
      }}
    >
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ m: 0 }}
        >
          <Grid item xs={3} sx={{ m: 0, p: 0 }}>
            <Box
              sx={{
                m: 0,
                p: 4,
                height: "100%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                overflowX: "hidden",
                maxWidth: `95vw`,
                minWidth: `95vw`,
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  color: "#6987C9",
                  fontWeight: "bold",
                }}
              >
                Create Method
              </Typography>
              <MethodCard {...props} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  ) : null;
};
export default MethodPage;
