import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Paper, Card } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../../configs/colorConfigs';

interface SubscriptionCardProps {
  subscriptions: any[],
  myTierIndex: any,
  selectedUpgrade: any,
  handleUpgradeSelection: (tier: any) => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({subscriptions, myTierIndex, handleUpgradeSelection, selectedUpgrade}) => {

  return (
    <Card variant="outlined"   
    sx={{ 
      width: '100%',
      height: '100%',
      boxShadow: 2
    }}>
    <TableContainer sx={{p: 0, m:0, height: '100%'}}>
      <Table sx={{height: '100%'}}>
        <TableHead  sx={{height: '17%'}}>
          <TableRow>
            <TableCell sx={{background: 'inherit'}}></TableCell>
            {subscriptions.map((subscription) => (
              <TableCell
                key={subscription.id}
                align="center"
                sx={{
                  color: '#000',
                  fontSize: {sm: '14px', md: '16px', lg: '18px'},
                  fontWeight: 'bold',
                  background: subscription.backgroundColor,
                  border: `3px solid ${subscription.backgroundColor}`
                }}
              >
                {subscription.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{height: '85%', minHeight: '85%', maxHeight: '85%'}}>
          <TableRow>
            <TableCell sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px", minWidth: "100px" }}>Access to the App</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                {subscription.app_access ? (
                  <CheckIcon sx={{ color: colorConfigs.buttons.green, fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: colorConfigs.buttons.red, fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>Access to the Portal</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                {subscription.portal_access ? (
                  <CheckIcon sx={{ color: colorConfigs.buttons.green, fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: colorConfigs.buttons.red, fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>Number of users</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                <Typography variant="body2" fontWeight="bold">
                  {subscription.number_of_users === 999999 ? 'Unlimited' : subscription.number_of_users}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>Unlimited results</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                {subscription.unlimited_results ? (
                  <CheckIcon sx={{ color: colorConfigs.buttons.green, fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: colorConfigs.buttons.red, fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>
              Support Level
            </TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                <Typography variant="body2" fontWeight="bold">
                  {subscription.support_level === "Limited Support" ? "Enhanced Support" : subscription.support_level}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', height: "65px",borderBottom: 'none' }}></TableCell>
            {subscriptions.map((subscription, index) => {
              return (
              <TableCell key={subscription.id} align="center" sx={{borderBottom: 'none', background: subscription.backgroundColor}}>
                {subscription.my_tier ?
                <Button 
                  onClick={() => handleUpgradeSelection(subscription.tier)}
                  variant="outlined" color={subscription.upgrade ? "secondary" : "primary"} sx={{textTransform: 'none', height: "65px", width: "135px", fontWeight: 'bold'}}>
                  {subscription.freeTier ? `Current Plan £0 / month` : `Current Plan £${subscription.price_per_month} / month`}
                </Button>
                :
                <Button 
                  onClick={() => handleUpgradeSelection(subscription.tier)}
                  variant={subscription.upgrade ? "contained" : "outlined"} sx={{textTransform: 'none', height: "65px", width: "135px", fontWeight: 'bold'}}>
                  {subscription.freeTier ? '£0 / month' : `${subscription.upgrade ? 'Upgrade' : ''} £${subscription.price_per_month} / month`}
                </Button>
                }
              </TableCell>
            )})}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Card>
  );
};

export default SubscriptionCard;
