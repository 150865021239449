import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { Button, Typography } from "@mui/material";
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";

interface DowngradeSubscriptionFormProps {
  upgradeTier: any,
}

const DowngradeSubscriptionForm: React.FC<DowngradeSubscriptionFormProps>  = ({upgradeTier}) => {
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const validationSchema = () => {
    return Yup.object().shape({
      tier: Yup.number()
        .required("Tier is required")
        .oneOf([1, 2, 3], "Invalid tier selected"),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      tier: upgradeTier || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const payload = {
        tier: values.tier,
      };

      try {
        const response = await api.post(
          "payments/update-subscription/",
          payload,
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );
        if (response.status === 200) {
          setSuccess("Subscription downgraded successfully!");
          setError(""); // Clear any previous errors
          dispatch(fetchUserProfile());
        }
      } catch (error: any) {
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
        setSuccess("");
        console.error("Error during checkout:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
        {!success &&
        <Button
            type='submit'
            variant="contained" 
            color="primary"
            sx={{
              textAlign: 'center', 
              marginX: 'auto', 
              textTransform: 'none', 
              fontWeight: 'bold', 
              mt: 1, 
              mb:1, 
              width: '100%'
            }}
        >
            {loading ? (
                "Processing..."
            ) : (
                <>
                    <Typography display="block" fontWeight='bold' >DOWNGRADE to Tier {upgradeTier}</Typography>
                </>
            )}
        </Button>
        }
        </form>
      </FormikProvider>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {success && <div style={{ color: "green" }}>{success}</div>}
    </>
  );
};

export default DowngradeSubscriptionForm;
