import React, {useEffect} from 'react'
import { FieldProps, getIn } from 'formik'
import { Box, Checkbox, InputAdornment, MenuItem, Select, TextField, TextFieldProps, Typography } from '@mui/material'
import colorConfigs from '../../../configs/colorConfigs';
import { useRareOrganisations } from '../CustomHooks';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ResultClassLabel from '../buttons/resultClassLabel';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Switch, FormControlLabel } from '@mui/material';
import fontConfigs from '../../../configs/fontConfigs';

export const TScoreField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TScore"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}

export const ResultDetailTableField: React.FC<FieldProps & TextFieldProps & { color: string }> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, color, ...rest } = props

  return (
      <TextField
          error={error ?? Boolean(isTouched && errorMessage)}
          helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
          {...rest}
          {...field}
          fullWidth
          variant="standard"
          margin="normal"
          autoFocus
          InputProps={{
              readOnly: true,
              disableUnderline: true,
              sx: {
                fontSize: fontConfigs.detailPage.headings.title,
                minWidth: '100%',
                textAlign: 'center'
            },
            }}
          sx={{
              p:0.5,
              minWidth: '100%',
              mt:2.5,
              mb:0,
          }}
          InputLabelProps={{
            sx: {
              fontSize: fontConfigs.detailPage.headings.tableLabels,
              fontWeight: 1000,
              paddingTop:1,
              color: '#fff',
              background: color,
              display: 'block',
              width: '100%',
              padding: '4px 12px',
              borderRadius: '4px',
              '&.Mui-focused': {
                color: '#fff'
            },
            '&.MuiFormLabel-root.MuiInputLabel-shrink': {
                color: '#fff'
            }
          },
              shrink: true 
          }}
      />
  )
}

export const TPeakField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TPeak"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const TCRatioField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TCRatio"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const CScoreField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="CScore"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const CPeakField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="CPeak"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,  
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
            
        />
    )
}


export const ResultDetailReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
            }}
            sx={{
              }}
            InputLabelProps={{
                style: {
                    fontSize:"18px",
                    marginLeft: "-4px"
                },
                shrink: true 
            }}
        />
    )
}


interface FormVisualResultsFieldProps extends FieldProps {
    visualResult: string;
    resultColor: string;
  }

export const FormVisualResultsField: React.FC<FormVisualResultsFieldProps & TextFieldProps> = ({
    visualResult,
    resultColor,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
<TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      sx={{
        fontSize: 18,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
    InputLabelProps={{
        style: {
            fontSize: 22,
            fontWeight: 1000,
            top: '-3px',
        },
        shrink: true 
    }}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
            padding: 1.5,
            pl: 3,
            fontSize: 18,
        },
        startAdornment: (
          <InputAdornment position="start" style={{ width: "100%", marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
            <Box sx={{minWidth: "100%", background: resultColor, p:0.5, fontWeight: "bold", display: "flex", justifyContent: "center", borderRadius: 2, overflow: "auto"}}>
                <Typography variant="h6" sx={{color: '#fff', minWidth: "5em", textAlign:"center", fontWeight: "normal"}}>{visualResult}</Typography>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};


interface TextAdornmentReadOnlyFieldProps extends FieldProps {
    status: boolean;
    id: string;
  }
export const TextAdornmentReadOnlyField: React.FC<TextAdornmentReadOnlyFieldProps & TextFieldProps>= ({
    status,
    id,
  ...props
}) => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                    fontSize: 14,
                    '& input': {
                      padding: 1,
                      paddingX: 2,
                  },
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <Typography sx={{p:0, m:0, pl: 2, minWidth: "2em", fontSize: 14, fontWeight: 'bold'}}>
                        {id}
                        </Typography>
                   </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" style={{}}>
                        {status ? <Typography sx={{ fontSize:"10px", marginX: 0.5, paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography> : <Typography sx={{ fontSize:"10px", marginX: 0.5, paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography> }
                   </InputAdornment>
                )
            }}
            sx={{
                fontSize: 14,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&:hover fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '& legend': {
                    width: 'auto',
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  opacity: 0.8,
                  fontWeight: 1000,
                  top: '-2px',
                },
                shrink: true 
            }}
        />
    )
}

interface TextAdornmentNoStatusReadOnlyFieldProps extends FieldProps {
    id: string;
    tier: string;
  }
export const TextAdornmentNoStatusReadOnlyField: React.FC<TextAdornmentNoStatusReadOnlyFieldProps & TextFieldProps>= ({
    id,
    tier,
  ...props
}) => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                    fontSize: 14,
                    '& input': {
                      padding: 1,
                      paddingX: 2,
                  },
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <Typography sx={{p:0, m:0, pl: 2, minWidth: "2em", fontSize: 14, fontWeight: 'bold'}}>
                        {id}
                        </Typography>
                   </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" style={{}}>
                        {tier && <Typography sx={{ fontSize: 14, marginX: 0.5, paddingX:0.7, }} >{tier}</Typography> }
                   </InputAdornment>
                )
            }}
            sx={{
                fontSize: 14,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&:hover fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '& legend': {
                    width: 'auto',
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  opacity: 0.8,
                  fontWeight: 1000,
                  top: '-2px',
                },
                shrink: true 
            }}
        />
    )
}

interface FeedbackReadOnlyFieldProps extends FieldProps {
    status: boolean;
    id: string;
  }
export const FeedbackReadOnlyField: React.FC<FeedbackReadOnlyFieldProps & TextFieldProps>= ({
    status,
    id,
  ...props
}) => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                    padding: '2px',
                    fontSize: 18,
                },
                startAdornment: (
                    <InputAdornment position="start">
                        {field.value === 'good' ?
                        <SentimentSatisfiedAltIcon sx={{color: colorConfigs.result.green, p:0, m:0, pl: 1, minWidth: "2em", fontWeight: 'bold'}}/>
                        : field.value === 'bad' ?
                        <SentimentVeryDissatisfiedIcon sx={{color: colorConfigs.result.red, p:0, m:0, pl: 1, minWidth: "2em", fontWeight: 'bold'}}/>
                        : (null)
                        }

                   </InputAdornment>
                ),

            }}
            sx={{
                fontSize: 18,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&:hover fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '& legend': {
                    width: 'auto',
                  },
                },
              }}
              InputLabelProps={{
                style: {
                    fontSize: 22,
                    fontWeight: 1000,
                    top: '-3px',
                },
                shrink: true 
            }}
        />
    )
}


interface ImageFieldProps extends FieldProps {
    image: string;
  }

export const ImageField: React.FC<ImageFieldProps & TextFieldProps> = ({
    image,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
<TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      multiline
      rows={1}
      sx={{
        fontSize: 18,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
    InputLabelProps={{
        style: {
            fontSize: 22,
            fontWeight: 1000,
            top: '-3px',
        },
        shrink: true 
    }}

      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
            padding: 3,
            fontSize: 18,
        },
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
             <img title="test" src={image} alt="test image" width="180px" height="60px" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const NotesField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
            multiline
            rows={3}
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  fontSize: 14,
                  '& input': {
                    padding: 1,
                    paddingX: 2,
                },
                },
            }}
            sx={{
                fontSize: 14,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&:hover fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                  '& legend': {
                    width: 'auto',
                  },
                },
              }}
            InputLabelProps={{
                style: {
                  fontSize: 16,
                  opacity: 0.8,
                  fontWeight: 1000,
                  top: '-2px',
                },
                shrink: true 
            }}
        />
    )
}

export const TextClassificationReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
                sx: {
                    p:"5px"
                },
            }}
            sx={{
              }}
            InputLabelProps={{
                style: {
                    fontSize:"18px",
                    marginLeft: "-4px"
                },
                shrink: true 
            }}
        />
    )
}

export const OrganisationSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
    const { error, helperText, field, form, type, ...rest } = props;
    const isTouched = getIn(form.touched, field.name);
    const errorMessage = getIn(form.errors, field.name);
    const authData = useSelector((state: RootState) => state.auth);
    const options = useRareOrganisations();

    return (
        <TextField
            error={Boolean(isTouched && errorMessage)}
            helperText={isTouched && errorMessage ? errorMessage : undefined}
            {...rest}
            {...field}
            fullWidth
            InputLabelProps={{
                sx: {
                    fontSize: '0.9rem',
                },
            }}
            sx={{
                margin: '16px 0',
                '& .MuiInputBase-root': {
                    fontSize: '0.9rem',
                },
            }}
            select
            // label="Organisation"
        >
            <MenuItem key={0} value="">
                <em>select organisation</em>
            </MenuItem>

            {options.map((option) => {
                return <MenuItem key={option.id} value={option.id}>
                    {option.id} - {option.name}
                </MenuItem>
            })}
        </TextField>
    );
};

export const ResolutionSelectField: React.FC<
    FieldProps & TextFieldProps & { resolutionOptions: { label: string; value: number[] }[] }
> = (props) => {
    const { error, helperText, field, form, resolutionOptions, ...rest } = props;
    const isTouched = getIn(form.touched, field.name);
    const errorMessage = getIn(form.errors, field.name);

    const handleChange = (event: any) => {
        const selectedValue = JSON.parse(event.target.value); // parse the selected value
        form.setFieldValue(field.name, selectedValue);  // Store the selected [width, height] array directly
    };

    return (
        <TextField
            {...rest}
            {...field}
            select
            fullWidth
            error={Boolean(isTouched && errorMessage)}
            helperText={isTouched && errorMessage ? errorMessage : undefined}
            onChange={handleChange}
            value={JSON.stringify(field.value)} // ensure it matches the serialized form
            InputLabelProps={{
                sx: { fontSize: '0.9rem' },
            }}
            sx={{
                margin: '16px 0',
                '& .MuiInputBase-root': { fontSize: '0.9rem' },
            }}
        >
            {resolutionOptions.map((option) => (
                <MenuItem key={option.label} value={JSON.stringify(option.value)}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};






export const SubscriptionSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { error, helperText, field, form, type, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);
  const authData = useSelector((state: RootState) => state.auth);
  const options = [
    {id: 1, name: "Free Tier"},
    {id: 2, name: "Tier 1"},
    {id: 3, name: "Tier 2"},
    {id: 4, name: "Tier 3"},
  ]

  return (
      <TextField
          error={Boolean(isTouched && errorMessage)}
          helperText={isTouched && errorMessage ? errorMessage : undefined}
          {...rest}
          {...field}
          fullWidth
          InputLabelProps={{
              sx: {
                  fontSize: '0.9rem',
              },
          }}
          sx={{
              margin: '16px 0',
              '& .MuiInputBase-root': {
                  fontSize: '0.9rem',
              },
          }}
          select
          // label="Organisation"
      >
          <MenuItem key={0} value="">
              <em>select organisation</em>
          </MenuItem>

          {options.map((option) => {
              return <MenuItem key={option.id} value={option.id}>
                  {option.id} - {option.name}
              </MenuItem>
          })}
      </TextField>
  );
};

export const UserTypeSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
    const { error, helperText, field, form, type, ...rest } = props;
    const isTouched = getIn(form.touched, field.name);
    const errorMessage = getIn(form.errors, field.name);
    const authData = useSelector((state: RootState) => state.auth);
    const options: string[] = authData.isAdmin ? ["ADMIN_USER", "ORGANISATION_ADMIN", "STANDARD_USER"] : ["ORGANISATION_ADMIN", "STANDARD_USER"]

    return (
        <TextField
            error={Boolean(isTouched && errorMessage)}
            helperText={isTouched && errorMessage ? errorMessage : undefined}
            {...rest}
            {...field}
            fullWidth
            InputLabelProps={{
                sx: {
                    fontSize: '0.9rem',
                },
            }}
            sx={{
                mt: '8px',
                '& .MuiInputBase-root': {
                    fontSize: '0.9rem',
                },
            }}
            select
        >
            <MenuItem key={0} value="">
                <em>select user type</em>
            </MenuItem>

            {options.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const SupportLevelSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { error, helperText, field, form, type, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);
  const options: any[] = [
    {id: 'BASIC', value: 0, label: "Basic Support", },
    {id: 'LIMITED', value: 1, label: "Limited Support", },
    {id: 'COMPREHENSIVE', value: 2, label: "Comprehensive Support", }
  ]

  return (
      <TextField
          error={Boolean(isTouched && errorMessage)}
          helperText={isTouched && errorMessage ? errorMessage : undefined}
          {...rest}
          {...field}
          fullWidth
          InputLabelProps={{
              sx: {
                  fontSize: '0.9rem',
              },
          }}
          sx={{
              mt: '8px',
              '& .MuiInputBase-root': {
                  fontSize: '0.9rem',
              },
          }}
          select
      >
          <MenuItem key={0} value="">
              <em>select support level</em>
          </MenuItem>

          {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                  {option.label}
              </MenuItem>
          ))}
      </TextField>
  );
};


interface FilterCriteriaSelectFieldProps extends FieldProps {
    options: {name: string, type: string, value: any, label: string }[];
}

export const FilterCriteriaSelectField: React.FC<FilterCriteriaSelectFieldProps & TextFieldProps> = (props) => {
    const isTouched = getIn(props.form.touched, props.field.name);
    const errorMessage = getIn(props.form.errors, props.field.name);

    const { error, helperText, field, form, options, type, ...rest } = props;

    const handleChange = (event:any) => {
        console.log("FilterCriteriaSelectField value:", event.target.value);
        field.onChange(event);
        if (props.onChange) props.onChange(event);
    };

    return (
        <TextField
            error={Boolean(isTouched && errorMessage)}
            helperText={(isTouched && errorMessage) ? errorMessage : undefined}
            {...rest}
            {...field}
            fullWidth
            select
            label={props.label}
            onChange={handleChange}
        >
            <MenuItem key={0} value="">
                <em>&nbsp;</em>
            </MenuItem>

            {options.map((option, index) => (
                <MenuItem key={option.name} value={option.name}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const TextEditField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            //autoFocus
        />
    )
}

export const TextEditReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
            }}
            sx={{
              }}
            InputLabelProps={{
                style: {
                },
                shrink: true 
            }}
        />
    )
}

interface SelectEditFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
  }

  export const SelectEditField: React.FC<SelectEditFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, type, ...rest } = props
  
    return (
      <TextField
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        select
        InputLabelProps={{
          sx: {
            // fontSize: '0.9rem',
          },
        }}
        sx={{
        //   margin: '7px 0',
          '& .MuiInputBase-root': {
            // fontSize: '0.9rem',
          },
        }}
      >
           <MenuItem key={0} value={""}>
              <em>&nbsp;</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }

  export const FormDatePicker: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
    
    const { error, helperText, field, form, ...rest } = props
    
      return (

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
          <DatePicker
              value={dayjs(field.value)}
              sx={{
                margin: '20px',
                '& .MuiInputBase-root': {
                  fontSize: '0.9rem',
                }}}
              onChange={(newValue) => {
                  if (newValue) {
                    form.setFieldValue(field.name, newValue ? dayjs(newValue).toISOString() : "");
                  }
              }}
          />
          </DemoContainer>
          </LocalizationProvider>
      )
    }


    export const ResultDetailEditField: React.FC<FieldProps & TextFieldProps> = props => {
        const isTouched = getIn(props.form.touched, props.field.name)
        const errorMessage = getIn(props.form.errors, props.field.name)
    
        const { error, helperText, field, form, ...rest } = props
    
        return (
            <TextField
                //   variant="outlined"
                error={error ?? Boolean(isTouched && errorMessage)}
                helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
                {...rest}
                {...field}
                fullWidth
                variant="outlined"
                margin="normal"
                autoFocus
    
                InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    sx: {
                      m:0,
                        fontSize: 14,
                        '& input': {
                          padding: 1,
                          paddingX: 2,
                          m:0
                      },
                    },
                }}
                sx={{
                    fontSize: 14,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: "rgba(0, 0, 0, 0.1)",
                      },
                      '&:hover fieldset': {
                        borderColor: "rgba(0, 0, 0, 0.1)",
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: "rgba(0, 0, 0, 0.1)",
                      },
                      '& legend': {
                        width: 'auto',
                      },
                    },
                  }}
                InputLabelProps={{
                    style: {
                        fontSize: 16,
                        opacity: 0.8,
                        fontWeight: 1000,
                        top: '-2px',
                    },
                    shrink: true 
                }}
            />
        )
    }
    
    export const ReadOnlySwitch: React.FC<FieldProps & { label: string, colorConfigs: any }> = ({ field, label, colorConfigs }) => {
      const isChecked = Boolean(field.value);
  
      return (
          <FormControlLabel
              control={
                  <Switch
                      checked={isChecked}
                      readOnly
                      sx={{
                          // marginLeft: 1,
                          '& .MuiSwitch-switchBase': {
                              color: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                          },
                          '& .MuiSwitch-track': {
                              backgroundColor: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                          },
                      }}
                  />
              }
              label={label}
              sx={{
                  '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                  },
              }}
          />
      );
  };

  export const ReadOnlyCheckbox: React.FC<FieldProps & { label: string, colorConfigs: any }> = ({ field, label, colorConfigs }) => {
    const isChecked = Boolean(field.value);

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={isChecked}
                    readOnly
                    sx={{
                        color: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                        '&.Mui-checked': {
                            color: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                        },
                    }}
                />
            }
            label={label}
            sx={{
                '.MuiFormControlLabel-label': {
                    fontSize: '14px',
                },
            }}
        />
    );
};


export const SmallTextEditField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
      <TextField
          //   variant="outlined"
          error={error ?? Boolean(isTouched && errorMessage)}
          helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
          {...rest}
          {...field}
          fullWidth
          variant="outlined"
          margin="normal"
          autoFocus
          sx={{
            my: 0,
          }}
          InputProps={{
            sx:{
              '& input': {
                padding: 1,
                paddingX: 2,
            },
            }
        }}
      />
  )
}

export const SmallEditReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
      <TextField
          //   variant="outlined"
          error={error ?? Boolean(isTouched && errorMessage)}
          helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
          {...rest}
          {...field}
          fullWidth
          variant="outlined"
          margin="normal"
          autoFocus

          InputProps={{
              readOnly: true,
              sx:{
                '& input': {
                  padding: 1,
                  paddingX: 2,
              },
              }
          }}
          sx={{
            my: 0,
          }}
          InputLabelProps={{
              style: {
              },
              shrink: true 
          }}
      />
  )
}

export const HousingResolutionSelectField: React.FC<
    FieldProps & TextFieldProps & { resolutionOptions: { label: string; value: number[] }[] }
> = (props) => {
    const { error, helperText, field, form, resolutionOptions, ...rest } = props;
    const isTouched = getIn(form.touched, field.name);
    const errorMessage = getIn(form.errors, field.name);

    const handleChange = (event: any) => {
        const selectedValue = JSON.parse(event.target.value); // parse the selected value
        form.setFieldValue(field.name, selectedValue);  // Store the selected [width, height] array directly
    };

    return (
        <TextField
            {...rest}
            {...field}
            select
            fullWidth
            error={Boolean(isTouched && errorMessage)}
            helperText={isTouched && errorMessage ? errorMessage : undefined}
            onChange={handleChange}
            value={JSON.stringify(field.value)} // ensure it matches the serialized form
            InputLabelProps={{
            }}
            sx={{
                minWidth: '200px',
                maxWidth: '200px',
                my: 0.5,
            }}
            InputProps={{
                sx: {
                    '& .MuiSelect-select': {
                      padding: 1,
                      paddingX: 2,
                    },
                },
            }}
        >
            {resolutionOptions.map((option) => (
                <MenuItem key={option.label} value={JSON.stringify(option.value)}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};


export const HousingResolutionReadOnlySelectField: React.FC<
    FieldProps & TextFieldProps & { resolutionOptions: { label: string; value: number[] }[] }
> = (props) => {
    const { error, helperText, field, form, resolutionOptions, ...rest } = props;
    const isTouched = getIn(form.touched, field.name);
    const errorMessage = getIn(form.errors, field.name);

    const handleChange = (event: any) => {
        const selectedValue = JSON.parse(event.target.value);
        form.setFieldValue(field.name, selectedValue); 
    };

    return (
        <TextField
            {...rest}
            {...field}
            select
            fullWidth
            error={Boolean(isTouched && errorMessage)}
            helperText={isTouched && errorMessage ? errorMessage : undefined}
            onChange={handleChange}
            value={JSON.stringify(field.value)}
            InputLabelProps={{
            }}
            sx={{
                minWidth: '200px',
                maxWidth: '200px',
                my: 0.5,
            }}
            InputProps={{
                readOnly: true,
                sx: {
                    '& .MuiSelect-select': {
                      padding: 1,
                      paddingX: 2,
                    },
                },
            }}
        >
            {resolutionOptions.map((option) => (
                <MenuItem key={option.label} value={JSON.stringify(option.value)}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
