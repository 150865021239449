import React, { useEffect, useState } from 'react';
import { AuthState } from '../../redux/features/auth/authSlice';
import api from '../../redux/features/auth/axiosAuthHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
export interface OrganisationData {
    id: string;
    name: string;
}


interface HousingData {
    id: number;
    name: string;
}

export interface ControlCorrection {
  id?: number;
  trim?: number;
  div_profile?: number;
  step_search?: number;
  search_min?: number;
  search_max?: number;
  min_fit_percentage?: number;
  method?: string;
}

export interface StripRegion {
  id?: number;
  category: string;
  lower_bound: number;
  upper_bound: number;
}

export interface Strip {
  id?: number;
  position: number;
  label: string;
  sample_rows: number;
  sample_cols: number;
  strip_corners: [number, number][];
  strip_regions: StripRegion[];
  control_correction: ControlCorrection;
}

  
/**
 * Custom hook to fetch and manage clinic data based on the user type.
 * @param authData - The authData object containing authentication-related information.
 * @returns An array containing clinics.
 */
const useOrganisations = () => {
    // State to hold the fetched clinic data.
    const [organisations, setOrganisations] = useState<OrganisationData[]>([]);
    const authData = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        // Skip fetching if the user is not authenticated.
        if (!authData.isAuthenticated) {
            return;
        }

        // Asynchronous function for fetching clinic data.
        const fetchData = async () => {
            try {
                let organisationData: OrganisationData[] = [];
                if (authData.isSuperUser || authData.isAdmin) {
                    // Fetch Organisations using the 'api/organisations/' endpoint for administrators and support users.
                    const response = await api.get('organisations/', {
                        headers: {
                            Authorization: `Bearer ${authData.access}`,
                        },
                    });

                    organisationData = (response.data.results as OrganisationData[]).map(
                        ({ id, name }) => ({
                            id,
                            name,
                        }),
                    );
                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                } else if (authData.isOrganisationAdminAnyTier || authData.isStandardUserAnyTier) {
                    organisationData = [
                        {
                            id: String(authData.user?.organisation),
                            name: authData.user?.organisation_name || '',
                        },
                    ];

                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                }
                console.log('-----Organisations------');
                console.log(organisationData);
            } catch (error) {
                // Log an error message if there is an issue fetching organisation data.
                console.error('Error fetching organisation data:', error);
            }
        };

        // Call the fetchData function to initiate the effect.
        fetchData();
    }, [authData.isAuthenticated, authData.isSuperUser, authData.isAdmin, authData.isStandardUserAnyTier, authData.isOrganisationAdminAnyTier]);

    return [organisations] as const;
};



const useRareOrganisations = (): OrganisationData[] => {
    // State to hold the fetched clinic data.
    const [organisations, setOrganisations] = useState<OrganisationData[]>([]);
    const authData = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        // Skip fetching if the user is not authenticated.
        if (!authData.isAuthenticated) {
            return;
        }

        // Asynchronous function for fetching clinic data.
        const fetchData = async () => {
            try {
                let organisationData: OrganisationData[] = [];
                if (authData.isSuperUser || authData.isAdmin) {
                    // Fetch Organisations using the 'api/organisations/' endpoint for administrators and support users.
                    const response = await api.get('organisations/', {
                        headers: {
                            Authorization: `Bearer ${authData.access}`,
                        },
                    });

                    organisationData = (response.data.results as OrganisationData[]).map(
                        ({ id, name }) => ({
                            id,
                            name,
                        }),
                    );
                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                } else if (authData.isOrganisationAdminAnyTier || authData.isStandardUserAnyTier) {
                    organisationData = [
                        {
                            id: String(authData.user?.organisation),
                            name: authData.user?.organisation_name || '',
                        },
                    ];

                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                }
                console.log('-----Organisations------');
                console.log(organisationData);
            } catch (error) {
                // Log an error message if there is an issue fetching organisation data.
                console.error('Error fetching organisation data:', error);
            }
        };

        // Call the fetchData function to initiate the effect.
        fetchData();
    }, [authData]);

    return organisations;
};


export {
    useOrganisations,
    useRareOrganisations,
};


export const useHousing = () => {
  const [housing, setHousing] = useState<HousingData[]>([]);
  const [housingError, setHousingError] = useState<string | null>(null);
  const authData = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchHousings = async () => {
      try {
        const response = await api.get("housing/", {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });

        const housingData = (response.data.results as HousingData[]).map(
          ({ id, name }) => ({
            id,
            name,
          })
        );
        setHousing(housingData);
        setHousingError("");
      } catch (err) {
        console.error(err);
        setHousingError("Failed to fetch housings");
      }
    };
    fetchHousings();
  }, [authData.isAuthenticated]);

  return { housing, housingError };
};
