import React, { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Breakpoint,
} from "@mui/material";

interface CustomDialogProps {
  width?: Breakpoint;
  title: string;
  children?: ReactNode;
  actions?: ReactNode;
  isOpen: boolean;
  closeDialog: () => void;
}

const Popup: React.FC<CustomDialogProps> = ({
  width = "sm",
  title,
  children,
  actions,
  isOpen,
  closeDialog,
}) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            closeDialog();
          }
        }}
        fullWidth
        maxWidth={width}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {actions || (
            <>
              <Button
                onClick={closeDialog}
                color="primary"
                style={{ margin: "10px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={closeDialog}
                color="secondary"
                variant="contained"
                style={{ margin: "10px" }}
              >
                Accept
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup;
