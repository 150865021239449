import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Button } from "@mui/material";

const UpdateSubscriptionForm = () => {
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validationSchema = () => {
    return Yup.object().shape({
      tier: Yup.number()
        .required("Tier is required")
        .oneOf([1, 2, 3], "Invalid tier selected"),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      tier: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const payload = {
        tier: values.tier,
      };

      try {
        const response = await api.post(
          "payments/update-subscription/",
          payload,
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );
        if (response.status === 200) {
          setSuccess("Subscription updated successfully!");
          setError(""); // Clear any previous errors
        }
      } catch (error: any) {
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
        setSuccess("");
        console.error("Error during checkout:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <hr />
      <h2>Update Subscription</h2>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label htmlFor="tier">Tier</label>
            <select
              id="tier"
              name="tier"
              onChange={formik.handleChange}
              value={formik.values.tier}
            >
              <option value="">Select a Tier</option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
            </select>
            {formik.errors.tier ? (
              <div style={{ color: "red" }}>{formik.errors.tier}</div>
            ) : null}
          </div>

          <Button
            className="form-group"
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              background: "#024c97",
              opacity: 0.6,
              color: "white",
              textTransform: "none",
              fontSize: 16,
            }}
          >
            {loading ? "Processing..." : "Update"}
          </Button>
        </form>
      </FormikProvider>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {success && <div style={{ color: "green" }}>{success}</div>}
    </>
  );
};

export default UpdateSubscriptionForm;
