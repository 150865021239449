import React, { useEffect, useState } from 'react';
import { Grid, Card, Typography, Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, AppBar, Toolbar, IconButton, Slide } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FileUploader } from 'react-drag-drop-files';
import sizeConfigs from '../../../configs/sizeConfigs';
import colorConfigs from '../../../configs/colorConfigs';
import fontConfigs from '../../../configs/fontConfigs';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

interface FileUploadCardProps {
    title: string;
    handleChange: (file: File) => void;
    name: string;
    types: string[];
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const FileUploadCard: React.FC<FileUploadCardProps> = ({ title, handleChange, name, types }) => {
    const [uploaded, setUploaded] = useState(false);
    const [fileName, setFileName] = useState<string | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleFileChange = (file: File) => {
        handleChange(file);
        setUploaded(true);
        setFileName(file.name);
        handleDialogClose(); // Automatically close the dialog
    };

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    return (
        <Card sx={{ p: 1, height: '100%', width: '100%', minWidth: {xs: '140px', md: '160px', lg: '180px', xl: '220px'}, maxWidth: {xs: '140px', md: '160px', lg: '180px', xl: '220px'}, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
                {uploaded ? (
                    <Box sx={{minHeight: {xs: '90px', md: '110px', lg: '130px', xl: '170px'}, maxHeight: {xs: '90px', md: '110px', lg: '130px', xl: '170px'}, minWidth: {xs: '140px', md: '160px', lg: '180px', xl: '220px'}, maxWidth: {xs: '140px', md: '160px', lg: '180px', xl: '220px'}, overflowY: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
                        <CheckCircleIcon sx={{ fontSize: {xs: 40, md: 50, lg: 60, xl: 70}, color: 'green', transition: 'all 0.3s ease' }} />
                        {fileName && 
                        <Typography 
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: 12, md: 12, lg: 14, xl: 14 },
                            mb: 1,
                            color: 'green',
                            maxWidth: { xs: '120px', md: '150px', lg: '170px', xl: '200px' },
                            overflowWrap: 'break-word',  // Allows long words to wrap
                            whiteSpace: 'normal',        // Allows multiline wrapping
                            display: 'block',            // Ensures multiline text display
                            textAlign: 'center',         // Centers text for better readability
                            cursor: 'pointer',
                    }}>
                        {fileName}</Typography>}
                    </Box>
                ) : (
                    <Box sx={{minHeight: {xs: '90px', md: '110px', lg: '130px', xl: '170px'}, maxHeight: {xs: '90px', md: '110px', lg: '130px', xl: '170px'}, minWidth: {xs: '140px', md: '160px', lg: '180px', xl: '220px'}, maxWidth: {xs: '140px', md: '160px', lg: '180px', xl: '220px'}, overflowY: 'auto'}}>
                        <UploadFileIcon sx={{ fontSize: {xs: 60, md: 80, lg: 100, xl: 110}, color: 'grey', transition: 'all 0.3s ease' }} />
                    </Box>
                )}
            </Box>

            {/* Dialog for file upload */}
            <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
                m:0
              },
            }}
          >
            <AppBar sx={{ 
                position: 'relative',
                height: sizeConfigs.detailPage.appbar.height,
                minHeight: sizeConfigs.detailPage.appbar.height,
                maxHeight: sizeConfigs.detailPage.appbar.height,
                }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:0, backgroundColor: colorConfigs.tables.headBg,}}>
              {title && (
                  <Box onClick={(e) => e.stopPropagation()} sx={{ m: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                  <Typography sx={{ fontSize: fontConfigs.detailPage.appbar.title, fontWeight: "bold", padding: "0 8px" }}>
                    Upload  {title}
                  </Typography>
                  </Box>
                )}
                
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleDialogClose}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>

                <DialogContent>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Typography sx={{ fontSize: fontConfigs.detailPage.headings, pt: 2}}>
                            Supported File Formats:
                        </Typography>
                        <Typography sx={{ fontSize: fontConfigs.detailPage.headings, fontWeight: "bold", pt: 2}}>
                            {types.map(type => `.${type.toLowerCase()}`).join(" ")}
                        </Typography>
                    </Box>

                    <Box sx={{ textAlign: 'center', minWidth: {xs: '100vw', md: '400px'}, maxWidth: "100%", mt: 2, mb: 1}}>
                        <FileUploader
                            handleChange={handleFileChange}
                            name={name}
                            types={types}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{width: '100%', justifyContent: 'space-between', gap: 3}}>
                    <Button 
                        variant="contained" 
                        onClick={handleDialogClose}
                        sx={{
                            bottom: 5,
                            width: '30%',
                            textTransform: 'none',
                            mx:1
                        }}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            bottom: 5,
                            width: '70%',
                            textTransform: 'none',
                            mx:1
                        }}
                        onClick={() => {
                            const inputElement = document.querySelector(`input[name="${name}"]`) as HTMLInputElement | null;
                            inputElement?.click();
                            // handleDialogClose();
                        }}
                        color="primary"
                    >
                        Choose File
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Button to open dialog */}
            <Button
                variant="contained"
                onClick={handleDialogOpen}
                sx={{
                    bottom: 5,
                    width: '90%',
                    textTransform: 'none',
                    mx: 1
                }}
            >
                Upload
            </Button>
        </Card>
    );
};

export default FileUploadCard;
