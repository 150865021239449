import axios from "axios";
import { store } from "../../store";
import { refreshAccessToken, setIsAuthenticated } from "./authSlice";
import { isLoggedIn } from "./authService";

// Create an Axios instance
const api = axios.create({
  baseURL: window.env.API_URL, // Use your API base URL
});

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const access = state.auth.access;

    if (access && !isLoggedIn(access)) {
      try {
        if (state.auth.refreshing) {
          return Promise.reject({
            response: "Request cancelled... refreshing token",
          });
        }
        // Refresh the access token if it’s expired
        await store.dispatch(refreshAccessToken()).unwrap();
        const newAccess = store.getState().auth.access;
        console.log(
          `axios interceptor PRE new access token ...${
            newAccess ? newAccess.slice(-6) : newAccess
          }`
        );
        config.headers.Authorization = `Bearer ${newAccess}`;
      } catch (error) {
        // If refresh fails, set the user as unauthenticated
        store.dispatch(setIsAuthenticated(false));
        return Promise.reject(error);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors and refresh token
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (store.getState().auth.refreshing) {
        return Promise.reject(error);
      }
      originalRequest._retry = true;

      try {
        // Refresh the access token if needed
        await store.dispatch(refreshAccessToken()).unwrap();
        const authData = store.getState().auth;
        console.log(
          `axios interceptor POST new access token ...${
            authData.access ? authData.access.slice(-6) : authData.access
          }`
        );
        originalRequest.headers.Authorization = `Bearer ${authData.access}`;
        return api(originalRequest); // Retry the request after refreshing
      } catch (refreshError) {
        console.error(refreshError);
        // Set isAuthenticated to false if refreshing fails
        store.dispatch(setIsAuthenticated(false));
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
