// src/Cancel.js
import React from 'react';

function PaymentCancelPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Payment Canceled</h1>
      <p>Your payment was not completed. You can try again or contact support.</p>
      <a href="/">Go back to Home</a>
    </div>
  );
}

export default PaymentCancelPage;
