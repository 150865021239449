import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Button, Typography } from "@mui/material";
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { InvoiceSessionStorageKey, InvoiceSessionStorageModel, InvoiceTableParameterStorageService } from "../../../services/sessionStorage";

interface SubscriptionPaymentCheckoutFormProps {
  upgradeTier: any,
}


const SubscriptionPaymentCheckoutForm: React.FC<SubscriptionPaymentCheckoutFormProps>  = ({upgradeTier}) => {
  const stripe = useStripe();
  const elements = useElements();
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const validationSchema = () => {
    return Yup.object().shape({
      tier: Yup.number()
        .required("Tier is required")
        .oneOf([1, 2, 3], "Invalid tier selected"),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      tier: upgradeTier || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      InvoiceTableParameterStorageService.set(InvoiceSessionStorageKey.updatedCardDetails, false)

      if (!stripe || !elements) {
        console.error("Stripe has not loaded yet.");
        setLoading(false);
        return;
      }
      const payload = {
        tier: values.tier,
      };

      try {
        const response = await api.post(
          "payments/checkout-session-for-new-subscription/",
          payload,
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );

        const session = response.data;

        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        dispatch(fetchUserProfile());
        if (result.error) {
          console.error("ERROR:", result.error.message);
          setError(`An unexpected error occurred: ${result.error.message}`);
        }
      } catch (error: any) {
        console.error("Error during checkout:", error);
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
        <Button 
                type='submit'
                variant="contained" 
                color={"primary"} 
                sx={{
                  textAlign: 'center', 
                  marginX: 'auto', 
                  textTransform: 'none', 
                  fontWeight: 'bold', 
                  mt: 1, 
                  mb:1, 
                  width: '100%'
                }}>
                  {loading ? (
                "Processing..."
            ) : (
                <>
                    <Typography display="block" fontWeight='bold'>Confirm Upgrade to Tier {upgradeTier}</Typography>
                </>
            )}
        </Button>
        </form>
      </FormikProvider>
      {error && <div style={{ color: "red" }}>{error}</div>}
    </>
  );
};

export default SubscriptionPaymentCheckoutForm;
