import {
  Alert,
  Box,
  AlertTitle,
  Snackbar,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  TextField,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Field, FormikProvider, useFormik } from "formik";
import { FormSwitch } from "../../../components/common/fields/ProfileFeilds";
import * as Yup from "yup";
import MuiAlert from "@mui/material/Alert";
import { SyntheticEvent } from "react";
import { SnackbarCloseReason } from "@mui/material";
import { TextEditField, TextEditReadOnlyField } from "../fields/fields";
import { useHousing } from "../CustomHooks";
import SignInDialog from "../SignInDialog";
import { ImageItem } from "./ImageGrid";
import ResultSelectorCard from "./ResultSelectorCard";
import TabbedStripsCard from "./TabbedStripsCard";

interface MethodCardProps {
  create: boolean;
}

const MethodCard: React.FC<MethodCardProps> = ({ create }) => {
  const navigate = useNavigate();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [errors, setErrors] = useState<any>(null);
  const authData = useSelector((state: RootState) => state.auth);

  const { id } = useParams();
  const { housing, housingError } = useHousing();
  const [selectedImage, setSelectedImage] = useState<ImageItem | undefined>(
    undefined
  );

  const handleSnackbarClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    const fetchMethodData = async () => {
      if (create || !authData.isAuthenticated || housing.length === 0) {
        return;
      }

      try {
        const response = await api.get(`methods/${id?.slice(1)}`, {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });

        const data = response.data;
        formik.setFieldValue("id", data.id);
        formik.setFieldValue("housing", data.housing);
        formik.setFieldValue("test_name", data.test_name);
        formik.setFieldValue("description", data.description || "");
        formik.setFieldValue("version", data.version);
        formik.setFieldValue("flash_required", data.flash_required);
        formik.setFieldValue("collection_mode", data.collection_mode);
        formik.setFieldValue("initial_h_factor", data.initial_h_factor);
        formik.setFieldValue(
          "control_line_correction",
          data.control_line_correction
        );
        formik.setFieldValue("profile_alignment", data.profile_alignment);
        formik.setFieldValue("control_exposure", data.control_exposure);
        formik.setFieldValue(
          "profile_control_levels",
          data.profile_control_levels
            ? JSON.stringify(data.profile_control_levels)
            : ""
        );
        formik.setFieldValue(
          "polynomial_baseline_order",
          data.polynomial_baseline_order
        );
        formik.setFieldValue("numberOfStrips", data.strips.length);
        formik.setFieldValue("frames_to_capture", data.frames_to_capture);
        formik.setFieldValue("control_baseline", data.control_baseline);
        formik.setFieldValue(
          "control_baseline_threshold",
          data.control_baseline_threshold
        );
        // setStrips(data.strips);
      } catch (error) {
        console.error("Error fetching method", error);
      }
    };

    fetchMethodData();
  }, [housing, authData.isAuthenticated, create]);

  const validationSchema = () => {
    return Yup.object().shape({
      test_name: Yup.string().required("This field is required"),
      description: Yup.string().required("This field is required"),
      housing: Yup.string().required("This field is required"),
      profile_control_levels: Yup.string()
        .test(
          "is-valid-integer-array",
          "Profile Control Levels must be a list of integers, e.g., [2, 3]",
          (value) => {
            if (!value) return true; // Optional field
            try {
              const parsed = JSON.parse(value);
              return (
                Array.isArray(parsed) &&
                parsed.length == 2 &&
                parsed.every((num) => Number.isInteger(num))
              );
            } catch {
              return false;
            }
          }
        )
        .optional(),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      id: "",
      housing: "",
      test_name: "",
      description: "",
      version: 1,
      flash_required: true,
      collection_mode: false,
      initial_h_factor: 1.5,
      control_line_correction: false,
      profile_alignment: false,
      control_exposure: false,
      profile_control_levels: "", // optional
      polynomial_baseline_order: 2,
      frames_to_capture: 10,
      control_baseline: false,
      control_baseline_threshold: 0.0, // optional
      numberOfStrips: 0,
      strip: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setOpenSnackBar(false);
      const basicData = {
        housing: values.housing,
        test_name: values.test_name,
        description: values.description,
        flash_required: values.flash_required,
        collection_mode: values.collection_mode,
        frames_to_capture: values.frames_to_capture,
        // strips:
        //   !strips || strips.length === 0
        //     ? Array.from({ length: values.numberOfStrips }, () => ({
        //         order: 0,
        //         sample_rows: 0,
        //         sample_cols: 0,
        //         strip_corners: [
        //           [0, 0],
        //           [0, 0],
        //           [0, 0],
        //           [0, 0],
        //         ],
        //         control_correction: {
        //           trim: 0,
        //           div_profile: 0,
        //           step_search: 0,
        //           search_min: 0,
        //           search_max: 0,
        //           min_fit_percentage: 0,
        //           method: "window",
        //         },
        //       }))
        //     : strips,
      };
      const extraData = {
        initial_h_factor: values.initial_h_factor,
        control_line_correction: values.control_line_correction,
        profile_alignment: values.profile_alignment,
        control_exposure: values.control_exposure,
        profile_control_levels: values.profile_control_levels
          ? (JSON.parse(values.profile_control_levels) as number[])
          : null,
        polynomial_baseline_order: values.polynomial_baseline_order,
        frames_to_capture: values.frames_to_capture,
        control_baseline: values.control_baseline,
        control_baseline_threshold: values.control_baseline_threshold,
      };
      const data = values.collection_mode
        ? { ...basicData }
        : { ...basicData, ...extraData };
      const url = create ? "methods/" : `methods/${id?.slice(1)}/`;
      const method = create ? "post" : "patch";
      // for (let i = 0; i < values.numberOfStrips; i++) {
      //   data["strips"][i]["position"] = i + 1;
      // }

      console.log("SEND DATA");
      console.log(data);

      try {
        const result = await api({
          method,
          url,
          data,
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });
        setErrors(null);
        setOpenSnackBar(true);
        // setStrips(result.data.strips);
        // setAllowEdit(!allowEdit);
        console.log(`Method successfully ${create ? "created" : "updated"}.`);
        navigate(`/methods/edit/:${result.data.id}`);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          const firstErrorItem = Object.keys(error.response.data)[0];
          setErrors(
            `${firstErrorItem}: ${error.response.data[firstErrorItem]}`
          );
        } else {
          const errorText = `Unknown error ${
            create ? "creating" : "updating"
          } method`;
          setErrors(errorText);
          console.error(errorText, error.response);
        }
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "frames_to_capture",
      formik.values.collection_mode ? 50 : 10
    );
  }, [formik.values.collection_mode]);

  useEffect(() => {
    console.log("selectedImage changed:");
    console.log(selectedImage);
  }, [selectedImage]);

  return authData.isAuthenticated == undefined ? (
    // isAuthenticated is undefined or null
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={false} />
  ) : housing.length === 0 ? (
    // clinics not loaded yet
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : authData.isAuthenticated ? (
    <Grid
      item
      xs={12}
      md={11}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Card
        sx={{
          boxShadow: 10,
          p: 2,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormikProvider value={formik}>
          <form
            onSubmit={formik.handleSubmit}
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div style={{ flexGrow: 0, width: "100%" }}>
              <Box
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: "grey.300",
                  borderRadius: 2,
                  mb: 3, // margin-bottom
                  boxShadow: 3,
                }}
              >
                {/* Container for horizontally arranged fields */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  {/* Left side - name on top of version */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <Field
                      name="test_name"
                      type="text"
                      className="form-control"
                      label="Name"
                      component={TextEditField}
                      onChange={formik.handleChange}
                      required
                    />
                    <Field
                      name="version"
                      type="text"
                      className="form-control"
                      label="Version"
                      default="1"
                      component={TextEditReadOnlyField}
                      onChange={formik.handleChange}
                    />
                  </div>

                  {/* Right side - description as a text area */}
                  <div style={{ flex: 2 }}>
                    <Field
                      name="description"
                      type="text"
                      className="form-control"
                      label="Description"
                      component={TextEditField}
                      multiline
                      rows={3} // Use rows for textarea
                      required
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div style={{ flex: 1 }}>
                    <Field
                      name="id"
                      type="text"
                      className="form-control"
                      label="ID"
                      component={TextEditReadOnlyField}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      select
                      label="Housings"
                      margin="normal"
                      autoFocus
                      name="housing"
                      value={formik.values.housing}
                      onChange={formik.handleChange}
                      required
                    >
                      <MenuItem key={0} value={""}>
                        <em>&nbsp;</em>
                      </MenuItem>
                      {housing.map((h) => (
                        <MenuItem key={h.id} value={h.id}>
                          {h.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    {housingError && (
                      <p style={{ color: "red" }}>{housingError}</p>
                    )}
                  </div>
                </div>
              </Box>
            </div>
            <div style={{ flexGrow: 0, width: "100%" }}>
              {/* <div style={{ flex: 1 }}> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    border: "1px solid",
                    borderColor: "grey.300",
                    borderRadius: 2,
                    mb: 3, // margin-bottom
                    boxShadow: 3,
                    // flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      gap: "1rem",
                      // width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#6987C9",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Reader Configuration
                    </Typography>
                    <Field
                      name="collection_mode"
                      type="checkbox"
                      label="Collection Mode"
                      component={FormSwitch}
                      visible={false}
                    />
                    <Field
                      name="frames_to_capture"
                      type="number"
                      label="Frames to Capture"
                      component={TextEditField}
                      onChange={formik.handleChange}
                    />
                    <Divider
                      sx={{
                        width: "100%",
                        marginY: 1,
                        borderBottomWidth: 3,
                        borderColor: "text.secondary",
                      }}
                    />{" "}
                    <Field
                      name="flash_required"
                      type="checkbox"
                      label="Flash Required"
                      component={FormSwitch}
                    />
                    {!formik.values.collection_mode && (
                      <>
                        <Field
                          name="control_exposure"
                          type="checkbox"
                          label="Control Exposure"
                          component={FormSwitch}
                        />
                        <Field
                          name="control_line_correction"
                          type="checkbox"
                          label="Control Line Correction"
                          component={FormSwitch}
                        />
                        <Field
                          name="profile_alignment"
                          type="checkbox"
                          label="Profile Alignment"
                          component={FormSwitch}
                        />
                        <Field
                          name="control_baseline"
                          type="checkbox"
                          label="Control Baseline"
                          component={FormSwitch}
                        />
                        <Field
                          name="control_baseline_threshold"
                          type="number"
                          label="Control Baseline Threshold"
                          component={TextEditField}
                          onChange={formik.handleChange}
                        />
                        <Field
                          name="polynomial_baseline_order"
                          type="number"
                          label="Polynomial Baseline Order"
                          component={TextEditField}
                          onChange={formik.handleChange}
                        />
                        <Field
                          name="initial_h_factor"
                          type="number"
                          label="Initial H Scale Factor"
                          component={TextEditField}
                          onChange={formik.handleChange}
                        />
                        <Field
                          name="profile_control_levels"
                          type="string"
                          label="Profile Control Levels"
                          component={TextEditField}
                          onChange={formik.handleChange}
                          placeholder="List of integers, e.g., [2, 3]"
                        />
                      </>
                    )}
                  </div>
                </Box>
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "row",
                    flex: 5,
                    // gap: "1rem",
                    // width: "100%",
                  }}
                >
                  {/* <LinearProgress/> */}
                  {create ? (
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        m: 1,
                        color: "white",
                        textTransform: "none",
                        fontSize: 16,
                        animation: "growShrink 0.5s infinite alternate",
                        "&.Mui-disabled": {
                          color: "white",
                          opacity: 0.5,
                        },
                      }}
                    >
                      Save Changes
                    </Button>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // flex: 5,
                          gap: "1rem",
                          // width: "100%",
                        }}
                      >
                        <ResultSelectorCard onAccept={setSelectedImage} />
                      </div>
                      <TabbedStripsCard
                        method_id={
                          formik.values.id ? parseInt(formik.values.id) : 0
                        }
                        // create={true}
                        image={selectedImage}
                        // data={strips}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Keep the button at the bottom */}
            <div
              style={{
                marginTop: "auto",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  m: 1,
                  color: "white",
                  textTransform: "none",
                  fontSize: 16,
                  animation: "growShrink 0.5s infinite alternate",
                  "&.Mui-disabled": {
                    color: "white",
                    opacity: 0.5,
                  },
                }}
              >
                Save Changes
              </Button>
            </div>{" "}
            {/* Adjusted width */}
            {errors && (
              <Alert severity="error" style={{ width: "100%" }}>
                <AlertTitle>Error</AlertTitle>
                {errors}
              </Alert>
            )}
          </form>
        </FormikProvider>
      </Card>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Method successfully updated
        </MuiAlert>
      </Snackbar>
    </Grid>
  ) : null;
};

export default MethodCard;
