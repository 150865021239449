import {
  Alert,
  Box,
  AlertTitle,
  Snackbar,
  Button,
  Divider,
  Typography,
  TextField,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { SyntheticEvent } from "react";
import { SnackbarCloseReason } from "@mui/material";
import { ControlCorrection, Strip, StripRegion } from "../CustomHooks";
import SignInDialog from "../SignInDialog";
import ControlCorrectionPopup from "./ControlCorrectionPopup";
import RegionSelectorCard, { ImageProfile } from "./RegionSelectorCard";
import { ImageItem } from "./ImageGrid";
import MuiAlert from "@mui/material/Alert";
import ProfileChart from "../graphs/ProfileChart";
import { Point } from "../../../utils/math";

interface StripCardProps {
  method_id: number;
  create: boolean;
  image?: ImageItem;
  index: number;
  label: string;
}

const StripCard: React.FC<StripCardProps> = ({
  method_id,
  create,
  image,
  index,
  label,
}) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [id, setId] = useState<number>(0);

  const [errors, setErrors] = useState<any>(null);
  const authData = useSelector((state: RootState) => state.auth);

  const [stripCorners, setStripCorners] = useState<Point[]>([]);
  const [stripRegions, setStripRegions] = useState<StripRegion[]>([]);
  const [controlCorrection, setControlCorrection] = useState<
    ControlCorrection | undefined
  >(undefined);
  const [imageProfile, setImageProfile] = useState<ImageProfile | undefined>(
    undefined
  );

  const handleSnackbarClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    const fetchStripData = async () => {
      let data = null;
      try {
        const response = await api.get(
          `strips/?method_id=${method_id}&index=${index}`,
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );
        data = response.data;
      } catch (error) {
        console.error("Error fetching strips", error);
      }
      if (data && data.count < 1) {
        setId(0);
        return;
      }
      console.log("fetchStripData", data);

      if (data && data.results.length > 0) {
        const stripData = data.results[0] as Strip;
        setStripCorners(stripData.strip_corners);
        setStripRegions(stripData.strip_regions);
        setControlCorrection(stripData.control_correction);
        setImageProfile((prevProfile) => {
          if (prevProfile)
            return {
              ...prevProfile,
              sample_rows: stripData.sample_rows,
              sample_cols: stripData.sample_cols,
            };
          else
            return {
              profile: [],
              sample_rows: stripData.sample_rows,
              sample_cols: stripData.sample_cols,
            };
        });
        if (stripData.id) {
          setId(stripData.id);
        }
      }
    };
    if (create || !authData.isAuthenticated) {
      return;
    }
    fetchStripData();
  }, [authData.isAuthenticated, create, index, method_id]);

  const handleSubmit = async () => {
    setOpenSnackBar(false);
    const data = {
      method_id,
      index,
      label,
      sample_rows: imageProfile?.sample_rows,
      sample_cols: imageProfile?.sample_cols,
      strip_corners: stripCorners,
      control_correction: controlCorrection,
      strip_regions: stripRegions,
    };
    const url = id ? `strips/${id}/` : "strips/";
    const method = id ? "patch" : "post";
    console.log("SEND DATA");
    console.log(data);

    try {
      const result = await api({
        method,
        url,
        data,
        headers: {
          Authorization: `Bearer ${authData.access}`,
        },
      });
      setErrors(null);
      setOpenSnackBar(true);
      console.log(`Strip successfully ${id ? "updated" : "created"}.`);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        const firstErrorItem = Object.keys(error.response.data)[0];
        setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
      } else {
        const errorText = `Unknown error ${
          id ? "updating" : "creating"
        } method`;
        setErrors(errorText);
        console.error(errorText, error.response);
      }
    }
  };

  return authData.isAuthenticated == undefined ? (
    // isAuthenticated is undefined or null
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={false} />
  ) : authData.isAuthenticated ? (
    <>
      <Box
        sx={{
          p: 2,
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 2,
          mb: 3, // margin-bottom
          boxShadow: 3,
          // flex: 1,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            color: "#6987C9",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Assay Configuration
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // flex: 5,
            gap: "1rem",
            // width: "100%",
          }}
        >
          <div style={{ flex: 1 }}>
            <ControlCorrectionPopup
              data={controlCorrection}
              setData={setControlCorrection}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // flex: 5,
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            sx={{
              flex: 1, // Make the container flexible
              width: "100%", // Ensure it takes full width
            }}
          >
            {image && (
              <RegionSelectorCard
                imageUrl={image.image_url}
                homography={image.homography}
                setImageProfile={setImageProfile}
                setStripCorners={setStripCorners}
              />
            )}
            {imageProfile ? (
              <ProfileChart
                profile={imageProfile?.profile}
                index={index}
                setStripRegions={setStripRegions}
              />
            ) : (
              <Box>No profile data available</Box>
            )}
          </Box>
        </div>
        <Divider
          sx={{
            width: "100%",
            marginY: 1,
            // borderBottomWidth: 1,
            // borderColor: "text.secondary",
            border: "1px solid",
            borderColor: "grey.300",
          }}
        />{" "}
        <div
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            // type="submit"
            variant="contained"
            onClick={handleSubmit}
            sx={{
              m: 1,
              color: "white",
              textTransform: "none",
              fontSize: 16,
              animation: "growShrink 0.5s infinite alternate",
              "&.Mui-disabled": {
                color: "white",
                opacity: 0.5,
              },
            }}
          >
            Save Strip
          </Button>
        </div>{" "}
        {/* Adjusted width */}
        {errors && (
          <Alert severity="error" style={{ width: "100%" }}>
            <AlertTitle>Error</AlertTitle>
            {errors}
          </Alert>
        )}
      </Box>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Strip successfully saved
        </MuiAlert>
      </Snackbar>
    </>
  ) : null;
};

export default StripCard;
