import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import SignInDialog from '../../components/common/SignInDialog';
import api from '../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { HousingSessionStorageKey, HousingTableParameterStorageService } from '../../services/sessionStorage';
import ResultDetailCard from '../../components/common/cards/ResultDetailCard';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ResultsTable from '../../components/common/tables/ResultsTable';
import colorConfigs from '../../configs/colorConfigs';
import ResultFilterBar from '../../components/common/filterbars/ResultFilterBar';
import DownloadProgressBar from '../../components/common/cards/DownloadProgressBar';
import sizeConfigs from '../../configs/sizeConfigs';
import fontConfigs from '../../configs/fontConfigs';
import ResultDetailDCMCard from '../../components/common/cards/ResultDetailDCMCard';
import HousingTable from '../../components/common/tables/HousingTable';
import HousingFilterBar from '../../components/common/filterbars/HousingFilterBar';
import HousingDetailCard from '../../components/common/cards/HousingDetailCard';

type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const HousingPage = (props: Props) => {

const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [housing, setHousing] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [totalCount, setTotalCount] = useState<any>(0); 
const [totalCountLoaded, setTotalCountLoaded] = useState<any>(0); 

const [sorting, setSorting] = useState<any>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.columnVisibility) || authData.isAdmin ? {id: false} : {id: false}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.pagination) || { pageSize: 250, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.showSearch) || false
});

const [rows, setRows] = useState<any>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.data) || []
});

const [queryString, setQueryString] = useState<any>(() => {
  return HousingTableParameterStorageService.get(HousingSessionStorageKey.currentQueryString) || ""
});

const handleRefreshButton = () => {
  setHousing("")
  fetchData(queryString)
  setPopup("")
}

const handleBackButton = () => {
  setHousing("")
  setPopup("")
}

const fetchData = async (query = "") => {
  setIsLoading(true);
  const data: any[] = [];
  const downloadPageSize = 250;  // Set the batch size to 250
  let downloadOffset = 0;
  let total = 0;

  try {
      const url = query ?  `housing/?${query}&limit=${downloadPageSize}&skip=${downloadOffset}` : `housing/?limit=${downloadPageSize}&skip=${downloadOffset}`

      // Fetch the first batch to get the total count
      const initialResponse = await api.get(url, {
          headers: {
              'Authorization': `Bearer ${authData.access}`
          }
      });
      if (initialResponse.data.results.length < 1) {
        console.log("No results for this query")
        return data
      } else {
        data.push(...initialResponse.data.results);
        total = initialResponse.data.count;
        downloadOffset += downloadPageSize;
        setTotalCount(total);
        setTotalCountLoaded(data.length);
        setIsLoading(false);
        setRows([...data]);
        setQueryString(query)
        HousingTableParameterStorageService.set(HousingSessionStorageKey.currentQueryString, query)
      }
      // Now fetch the remaining lots in the background
      while (data.length < total) {
          const url = query ?  `housing/?${query}&limit=${downloadPageSize}&skip=${downloadOffset}` : `housing/?limit=${downloadPageSize}&skip=${downloadOffset}`

          const response = await api.get(url, {
              headers: {
                  'Authorization': `Bearer ${authData.access}`
              }
          });

          data.push(...response.data.results);
          downloadOffset += downloadPageSize;
          setTotalCountLoaded(data.length);
          console.log(`Downloaded ${data.length} of ${total} housings`);
      }

      // Final update after all lots are fetched
      setRows([...data]);
      console.log('Downloaded all housings:', data);

      HousingTableParameterStorageService.set(HousingSessionStorageKey.data, data)
      HousingTableParameterStorageService.set(HousingSessionStorageKey.count, total)

      setTimeout(() => { 
        setTotalCount(false)
      }, 6000);

  } catch (error) {
      console.error('Error fetching housings:', error);
  } finally {
    setIsLoading(false);
    return data
  }
};

useEffect(() => { 
  const dataInStorage = HousingTableParameterStorageService.get(HousingSessionStorageKey.count);
  if ((!dataInStorage || dataInStorage === 0) && (rows.length < 1 || !rows.length)) {
    // No data in storage and no rows loaded, show loading and fetch data
    // setIsLoading(true);
    // fetchData();
  } else if (dataInStorage && rows.length < 1) {
      // Data exists in session storage but rows haven't loaded yet, show loading until rows are ready
      setTotalCount(dataInStorage);
      setTotalCountLoaded(0);
      setIsLoading(true)
      const storedData = HousingTableParameterStorageService.get(HousingSessionStorageKey.data) || [];

      const fetchStoredDataInBatches = async () => {
        const batchSize = 1000;
        let batchIndex = 0;
        let allData: any[] = [];
        // Retrieve patients from storage in batches of 250 until all are fetched
        while (batchIndex * batchSize < storedData.length) {
          const batch = storedData.slice(batchIndex * batchSize, (batchIndex + 1) * batchSize);
          if (batchIndex >= 1) {setIsLoading(false)}
          allData = [...allData, ...batch];
          setRows([...allData]);  // Update rows incrementally
          batchIndex++;
          setTotalCountLoaded(allData.length)
          await new Promise(resolve => setTimeout(resolve, 10));
        }
        setTimeout(() => { // Hide progress bar
          setTotalCount(false)
        }, 3000);
        setIsLoading(false);  // All batches loaded, stop loading
      };
  
      fetchStoredDataInBatches();
  } else if (rows.length > 0) {
    // Rows are loaded, stop loading
    setIsLoading(false);
  }
}, [rows.length, authData.isAuthenticated]);


const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 250, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  const checkAuthentication = async () => {
    if (!authData.isAuthenticated) {
      return;
    }

    try {
      await dispatch(refreshAccessToken()).unwrap();
    } catch (error) {
      console.log("Authentication check failed:", error);
    }
  };

  checkAuthentication();
}, [dispatch, authData.isAuthenticated, authData.access]);


  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated && authData.isFreeUser ? (
    <Navigate to="/account/subscription"/>
    ) : authData.isAuthenticated === true && !authData.isFreeUser ? (
      <div style={{ overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw`, margin: "auto"}}>

      <div style={{ display: "flex", height: "100%", width: "100%"}}>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
            <Box sx={{ m:0, p:2, pt: 0, height: "100%", overflowY: "auto", marginRight: 6, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw` }}>
                <DownloadProgressBar totalCount={totalCount} totalCountLoaded={totalCountLoaded}/>
                <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 5, borderTopRightRadius: 3, borderTopLeftRadius: 3 }}>
                <Box sx={{
                    borderRadius: 1,
                    height: 'calc(100vh - 120px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <HousingFilterBar setIsLoading={setIsLoading} handleRemoveAllFilters={handleRemoveAllFilters} fetchData={fetchData}/>
                <Box sx={{
                    flex: 1,
                    // maxHeight: 'calc(100vh - 295px)',
                    minHeight: 'calc(100vh - 295px)',
                    display: 'flex',
                    flexDirection: 'column',
                    borderTopRightRadius: 50,
                    borderTopLeftRadius: 0,
                    border: 'none',
                    boxShadow: 0,
                    
                }}>

                 <HousingTable
                    rows={rows}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    setPopup={setPopup}
                    setHousing={setHousing}
                    handleRefreshButton={handleRefreshButton}
                    authData={authData}
                  />
                  </Box>
                  </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={(housing && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setHousing(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
                height: '100vh',
                minHeight: '100vh',
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
                p:0,
                height: '100vh',
                minHeight: '100vh'
              },
            }}
          >
            <AppBar
              onClick={() => { setHousing(""); setPopup(""); }}
              sx={{
                position: 'fixed',
                top: 0,
                height: sizeConfigs.detailPage.appbar.height,
                minHeight: sizeConfigs.detailPage.appbar.height,
                maxHeight: sizeConfigs.detailPage.appbar.height,
                zIndex: 1201,
              }}
            >             
             <Toolbar
                sx={{
                  height: sizeConfigs.detailPage.appbar.height,
                  minHeight: sizeConfigs.detailPage.appbar.height,
                  maxHeight: sizeConfigs.detailPage.appbar.height,
                  padding: 0,
                  backgroundColor: colorConfigs.tables.headBg,
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                }}
              >
                {popup && (
                  <Box onClick={(e) => e.stopPropagation()} sx={{ m: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                  {popup === "Create" ? 
                  <>
                  <Typography sx={{ fontSize: fontConfigs.detailPage.appbar.title, fontWeight: "bold", padding: "0 8px" }}>
                    Create Housing
                  </Typography>
                  </>
                  :
                  <>
                  <Typography sx={{ ml: 2, fontSize: fontConfigs.detailPage.appbar.label, padding: "0 8px" }}>
                    Housing Name
                  </Typography>
                  <Typography sx={{ fontSize: fontConfigs.detailPage.appbar.title, fontWeight: "bold", padding: "0 8px" }}>
                    {housing.name}
                  </Typography>
                  </>
                  }
                  </Box>
                )}
                 <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => { setHousing(""); setPopup(""); }}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    padding: 0,
                    fontSize: '18px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>


            <DialogContent
                  sx={{
                    height: sizeConfigs.detailPage.dialogContent.height,
                    minHeight: sizeConfigs.detailPage.dialogContent.height,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    p: 0,
                    m: 0,
                    minWidth: '100vw',
                    backgroundColor: colorConfigs.sidebar.bg,
                    mt: sizeConfigs.detailPage.appbar.height,
                  }}
                >         
                {(popup === "Create") ?
                <HousingDetailCard create={popup === "Create"} handleRefreshButton={handleRefreshButton} handleBackButton={handleBackButton} />
                :(popup === "Edit" && housing) ?
                <HousingDetailCard housing_id={housing.id}  create={popup === "Create"} handleRefreshButton={handleRefreshButton} handleBackButton={handleBackButton} />
                : null
              }       
            </DialogContent>
        </Dialog>
    </div>
) : (null);
};

export default HousingPage;

