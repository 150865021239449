import { Badge, ListItemButton, ListItemIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../configs/colorConfigs";
import { RootState } from "../redux/store";
import { RouteType } from "../routes/config";
import { setAppState } from "../redux/features/appStateSlice";
import checkRoutePermissions from "../routes/appRoutePermissions";


type Props = {
  item: RouteType;
  isHovered: boolean;
};

const SubSidebarItem = ({ item, isHovered }: Props) => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state: RootState) => state.appState);
  const authData = useSelector((state: RootState) => state.auth);
  const permission = checkRoutePermissions(authData, item);

  if (!permission) return null; // Do not render if the route is not visible

  return (
    item.sidebarProps && item.path ? (
      <ListItemButton
        onClick={permission !== 'premium' ? () => dispatch(setAppState(item.state)) : () => {}}
        component={Link}
        to={permission !== 'premium' ? item.path : '#'} // Disable link for premium routes
        disableRipple
        selected={appState === item.state}
        sx={{
          "&:hover": {
            color: permission === 'premium' ? 'grey' : appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
            cursor: permission === 'premium' ? 'not-allowed' : 'pointer',
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected": {
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.activeBg,
            "&:hover": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:active": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:focus": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          color: permission === 'premium' ? 'grey' : appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
          pb: "10px",
          pt: 0,
          transform: 'scale(0.9)',
          opacity: permission === 'premium' ? 0.65 : 1,
          position: 'relative', // Required for Badge
        }}
      >
        <ListItemIcon sx={{
          minWidth: "unset",
          marginLeft: "25px",
          p:0,
          marginRight: "15px",
          justifyContent: "center",
          color: permission === 'premium' ? 'grey' : appState === item.state ? colorConfigs.sidebar.activeBg : "unset",  // Keep the displayText color as is
          "&:hover": {
            color: permission === 'premium' ? 'grey' : appState === item.state ? colorConfigs.sidebar.activeBg : "unset",  // Keep the displayText color as is
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected": {
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.activeBg,
            "&:hover": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:active": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:focus": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
        }}>
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {isHovered && (
        <>
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                color: 'unset',
                backgroundColor: 'transparent',
                borderRadius: '12px',
                fontWeight: 'bold',
                paddingX: 0.5,
                paddingY: 0,
                fontSize: '0.5rem',
                height: "55%",
                right: '5px',
                top: '-1.5px',
                cursor: 'pointer',
                },
              color: permission === 'premium' ? 'grey' : appState === item.state ? 'unset' : "unset",
            }}
            badgeContent={
              permission === 'premium' ? (
                <Link
                  to="/account/subscription"
                  style={{ textDecoration: 'none', color: '#111', padding:0, margin:0}}
                >
                  Premium
                </Link>
              ) : null
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {item.sidebarProps.displayText}
          </Badge>
        </>
      )}
      </ListItemButton>
    ) : null
  );
};

export default SubSidebarItem;