import { AppBar, Box, Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, Link, Paper, Slide, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  isLoggedIn,
  refreshToken,
} from "../../../redux/features/auth/authService";
import {
  logout,
  refreshAccessToken,
} from "../../../redux/features/auth/authSlice";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionPaymentCheckoutForm from "./SubscriptionPaymentCheckoutForm";
import UpgradeSubscriptionForm from "./UpgradeSubscriptionForm";
import DowngradeSubscriptionForm from "./DowngradeSubscriptionForm";
import UpdateMyPaymentMethodForm from "./UpdateMyPaymentMethodForm";
import CancelMySubscriptionForm from "./CancelMySubscriptionForm";
import colorConfigs from "../../../configs/colorConfigs";
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import api from "../../../redux/features/auth/axiosAuthHelper";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

interface SubscriptionCardProps {
    currentTier: any,
    selectedUpgrade: any,
  }

const UpgradeCard: React.FC<SubscriptionCardProps> = ({currentTier, selectedUpgrade}) => {
    const navigate = useNavigate();
    const authData = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();
    const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null);
    const currentPlan = currentTier.tier === selectedUpgrade.tier
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState("");
    const [animate, setAnimate] = useState(false);
    const tabs = ['cancel', 'upgrade', 'upgrade_new_user', 'downgrade']

    // Trigger animation when selectedUpgrade changes
    useEffect(() => {
        if (selectedUpgrade) {
            setAnimate(true);
            const timer = setTimeout(() => {
                setAnimate(false); // Remove animation after it finishes
            }, 1000); // Animation duration
            return () => clearTimeout(timer);
        }
    }, [selectedUpgrade]);


    useEffect(() => {
        const fetchStripeKey = async () => {
          try {
            const response = await api.get("payments/stripe-key/", {
              headers: {
                Authorization: `Bearer ${authData.access}`,
              },
            });
            // Load Stripe instance and update state
            const stripe = await loadStripe(response.data.publishable_key);
            setStripeInstance(stripe);
          } catch (error) {
            console.error("Error fetching Stripe key:", error);
          }
        };
    
        fetchStripeKey();
      }, [authData.access]);


    return !stripeInstance ? (
        <CircularProgress />
      ) : ( 
        <Elements stripe={stripeInstance}>
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'space-between'}}>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', gap: 2}}>
            <Box sx={{p:2, border: '1px solid #ddd', borderRadius: 1, boxShadow: 2, background: colorConfigs.subscription.mySubscription}}>
                <Typography variant='h6' fontWeight="bold">My Current Plan</Typography>
                <Typography>Tier {currentTier.tier}</Typography>
                <Typography>£{currentTier.price_per_month} / month</Typography>
                
            </Box>
            {(selectedUpgrade && !currentPlan) &&
            <Box
                sx={{
                    p: 2,
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    flexGrow: 1,
                    boxShadow: 2,
                    background: colorConfigs.subscription.selected,
                    // Add animation when 'animate' is true
                    animation: animate
                        ? `pulse 0.75s ease`
                        : 'none',
                    "@keyframes pulse": {
                        "0%": {
                            transform: "scale(0.95)",
                        },
                        "50%": {
                            transform: "scale(1.05)", // Slightly grow the box
                        },
                        "100%": {
                            transform: "scale(1)", // Back to original size
                        },
                    },
                }}
            >                
                <Typography variant='h6' fontWeight="bold">{selectedUpgrade.upgrade ? `Upgrade Your Plan` : `Downgrade Your Plan` }</Typography>
                <Typography>Tier {selectedUpgrade.tier}</Typography>
                <Typography>£{selectedUpgrade.price_per_month} / month</Typography>

            {/* Upgrade For New User - Redirect to stripe */}
            {selectedUpgrade.upgrade && authData.isFreeUser &&
                    <Button
                    onClick={() => {setOpen("upgrade_new_user")}}
                    variant="contained" 
                    color="primary"
                    sx={{
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      textAlign: 'center', 
                      marginX: 'auto', 
                      textTransform: 'none', 
                      fontWeight: 'bold', 
                      mt: 2, 
                      mb: 1, 
                      width: '100%', 
                      minHeight: '100px',
                      gap: 1
                    }}
                >
                    {loading ? (
                        "Processing..."
                    ) : (
                        <>
                            <Typography display="block" fontWeight='bold'>UPGRADE</Typography>
                            <Typography display="block" fontWeight='bold'>to Tier {selectedUpgrade.tier}</Typography>
                        </>
                    )}
                </Button>
            }

            {/* Upgrade For Existing User */}
            {selectedUpgrade.upgrade && !authData.isFreeUser &&
                    <Button
                    onClick={() => {setOpen("upgrade")}}
                    variant="contained" 
                    color="primary"
                    sx={{
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      textAlign: 'center', 
                      marginX: 'auto', 
                      textTransform: 'none', 
                      fontWeight: 'bold', 
                      mt: 2, 
                      mb: 1, 
                      width: '100%', 
                      minHeight: '100px',
                      gap: 1
                    }}
                >
                    {loading ? (
                        "Processing..."
                    ) : (
                        <>
                            <Typography display="block" fontWeight='bold'>UPGRADE</Typography>
                            <Typography display="block" fontWeight='bold'>to Tier {selectedUpgrade.tier}</Typography>
                        </>
                    )}
                </Button>
            }

            {/* Downgrade */}
            {selectedUpgrade.downgrade &&
                    <Button
                    onClick={() => {setOpen(selectedUpgrade.tier === 0 ? "cancel" :"downgrade")}}
                    variant="contained" 
                    color="primary"
                    sx={{
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      textAlign: 'center', 
                      marginX: 'auto', 
                      textTransform: 'none', 
                      fontWeight: 'bold', 
                      mt: 2, 
                      mb: 1, 
                      width: '100%', 
                      minHeight: '100px',
                      gap: 1,
                    }}
                >
                    {loading ? (
                        "Processing..."
                    ) : (
                        <>
                            <Typography display="block" fontWeight='bold' >DOWNGRADE</Typography>
                            <Typography display="block" fontWeight='bold' >to Tier {selectedUpgrade.tier}</Typography>
                        </>
                    )}
                </Button>
            }

            {/* Current */}
            {currentPlan &&
            <Button 
                variant="contained" disabled color={"primary"} 
                sx={{marginX: 1, textTransform: 'none', fontWeight: 'bold', mt: 2, mb:1, height: "80px", width: "200px"}}>
                    My Current Plan
            </Button>
            }

            </Box>
            }
            {(!selectedUpgrade && authData.isFreeUser) &&
            <Box sx={{p:2, border: '1px solid #ddd', borderRadius: 1, flexGrow: 1}}>
                <Typography variant='h6' fontWeight="bold">Choose a plan and upgrade today</Typography>
            </Box>
            }

            </Box>

            <Box sx={{paddingX:2, border: '1px solid #ddd', borderRadius: 1, boxShadow: 2, background: colorConfigs.topbar.bg2}}>
                <UpdateMyPaymentMethodForm />
                <Button 
                    onClick={() => {setOpen("cancel")}}
                    disabled={authData.isFreeUser}
                    variant="contained" color={"secondary"} 
                    sx={{textAlign: 'center', marginX: 'auto', textTransform: 'none', fontWeight: 'bold', mt: 1, mb:2, width: '100%'}}>
                    {"Cancel Subscription"}
                </Button>
                

        <Dialog
            open={tabs.includes(open)}
            // fullScreen
            onClose={() => {setOpen(""); navigate(0)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:1, backgroundColor: colorConfigs.tables.headBg, opacity: 0.95}}>
                <Typography
                      onClick={() => {setOpen(""); navigate(0)}}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        borderRadius: 1,
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                    {open === "cancel" ?
                     `Cancel My Subscription`
                     : open === "upgrade_new_user" ?
                     `Upgrade My Account`
                     : open === "upgrade" ?
                     `Upgrade My Account`
                    : open === "downgrade" ?
                     `Downgrade My Account`
                     : null}
                    </Typography>  
                
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setOpen(""); navigate(0)}}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{p:3, backgroundColor: colorConfigs.sidebar.bg, width: '100%'}}>
                {/* Cancel Subscription */}
                {open === "cancel" ?
                <Box>
                    <Typography sx={{p: 1}}>Are you sure you want to cancel your subscription?</Typography>
                    <Typography sx={{p: 1}}>If you cancel your subscription you will <span style={{textDecoration: 'underline'}}>lose your current users and data.</span></Typography>
                    <Typography sx={{ p: 1 }}>
                        If you have questions, you can 
                        <Link href="https://sales.rare.staging.novarumcloud.com/company#contact" target="_blank" rel="noopener" sx={{ ml: 1 }}>
                            Contact Us
                        </Link>
                    </Typography>
                    <Box sx={{m: 3, marginX: 10}}>
                        <CancelMySubscriptionForm />                
                    </Box>
                </Box>
                : open === "upgrade_new_user" ?
                <Box>
                    <Typography sx={{p: 1}}>You will now be redirected to Stripe to setup your payment details</Typography>
                    <Typography sx={{ p: 1 }}>
                        If you have questions, you can 
                        <Link href="https://sales.rare.staging.novarumcloud.com/company#contact" target="_blank" rel="noopener" sx={{ ml: 1 }}>
                            Contact Us
                        </Link>
                    </Typography>
                    <Box sx={{m: 3, marginX: 10}}>
                        <SubscriptionPaymentCheckoutForm upgradeTier={selectedUpgrade.tier} />
                    </Box>
                </Box>       
                : open === "upgrade" ?
                <Box>
                    <Typography sx={{p: 1}}>You are currently on Tier {currentTier.tier}. By clicking Confirm you will automatically be upgrade to Tier {selectedUpgrade.tier}.</Typography>
                    <Typography sx={{p: 1}}>Your subscription per month will now be £{selectedUpgrade.price_per_month} </Typography>
                    <Typography sx={{ p: 1 }}>
                        If you have questions, you can 
                        <Link href="https://sales.rare.staging.novarumcloud.com/company#contact" target="_blank" rel="noopener" sx={{ ml: 1 }}>
                            Contact Us
                        </Link>
                    </Typography>
                    <Box sx={{m: 3, marginX: 10}}>
                        <UpgradeSubscriptionForm upgradeTier={selectedUpgrade.tier}/>
                    </Box>
                </Box>  
                : open === "downgrade" ?
                <Box>
                    <Typography sx={{p: 1}}>You are currently on Tier {currentTier.tier}. By clicking Confirm you will automatically be downgraded to Tier {selectedUpgrade.tier}.</Typography>
                    <Typography sx={{p: 1}}>Your subscription per month will now be £{selectedUpgrade.price_per_month ? selectedUpgrade.price_per_month : '0'} </Typography>
                    <Typography sx={{ p: 1 }}>
                        If you have questions, you can 
                        <Link href="https://sales.rare.staging.novarumcloud.com/company#contact" target="_blank" rel="noopener" sx={{ ml: 1 }}>
                            Contact Us
                        </Link>
                    </Typography>
                    <Box sx={{m: 3, marginX: 10}}>
                        <DowngradeSubscriptionForm upgradeTier={selectedUpgrade.tier}/>
                    </Box>
                </Box>  
                : null }
            </DialogContent>
            </Dialog>

            </Box>
        </Box>
        </Elements>
     );
}
 
export default UpgradeCard;