import { Alert, AlertTitle, Box, Button, Card, Divider, FormControlLabel, FormGroup, Grid, IconButton, Link, Paper, Snackbar, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { isLoggedIn, refreshToken } from '../../../redux/features/auth/authService';
import SignInDialog from '../../../components/common/SignInDialog';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { FormIsActiveSwitch, FormLastLoginTextField, FormUserEmailTextField, FormUserFirstNameTextField, FormUserIDTextField, FormUserLastNameTextField, FormUserTypeTextField } from '../../../components/common/fields/ProfileFeilds';
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import { TextEditField, TextEditReadOnlyField } from '../../../components/common/fields/fields';
import MuiAlert from '@mui/material/Alert';
import { SyntheticEvent } from 'react';
import { SnackbarCloseReason } from '@mui/material';

interface EditUserDetailProps {
    setPopup: (detail: any) => void
    handleBackButton: () => void;
}

const EditProfileCard: React.FC<EditUserDetailProps> = ({setPopup, handleBackButton}) => {
  
  const [allowEdit, setAllowEdit] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>(null)
  const [initialValues, setInitialValues] = useState<any>("")
  const [updatedValues, setUpdatedValues] = useState<any>("")
  const [hasMadeChanges, setHasMadeChanges] = useState<any>(false)
  const authData = useSelector((state: RootState) => state.auth);
  const [open, setOpen] = useState(false);

const dispatch = useDispatch<AppDispatch>();

const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
if (reason === 'clickaway') {
  return;
}
setOpen(false);
};
const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
setOpen(false);
};

useEffect(() => {
  if (authData.user) {
      formik.setFieldValue('id', authData.user.id);
      formik.setFieldValue('first_name', authData.user.first_name);
      formik.setFieldValue('last_name', authData.user.last_name);
      formik.setFieldValue('email', authData.user.email);
      formik.setFieldValue('user_type', authData.user.user_type);
      formik.setFieldValue('date_joined', authData.user.date_joined ? dayjs(authData.user.date_joined).format('YYYY-MM-DD') : '' );
      formik.setFieldValue('last_login', authData.user.last_login ? dayjs(authData.user.last_login).format('YYYY-MM-DD HH:mm:ss') : '' );
      formik.setFieldValue('is_active', authData.user.is_active);
  }
}, [authData.user]);

const validationSchema = () => {
  return Yup.object().shape({
      first_name: Yup.string().required("This field is required"), 
      last_name: Yup.string().required("This field is required"),
      email: Yup.string().required("This field is required").email(),
  });
}

const formik = useFormik({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      user_type: "",
      date_joined: "",
      last_login: "",
      is_active: false
  },
  validationSchema,
  onSubmit: async (values) => {

      const patch_data = {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              // user_type: values.user_type,
              // is_active: values.is_active,
          }

      try {
          await api.patch(`profile/`, patch_data, {
              headers: {
                  'Authorization': `Bearer ${authData.access}`
              }
          })
          setErrors(null);
          setOpen(!open)
          setAllowEdit(!allowEdit)
          dispatch(fetchUserProfile());
          console.log(`Profile edited successfully.`);
          setTimeout(() => {
            setPopup("")
          }, 1500);
      } catch (error: any) {
          if (error?.response?.status === 400) {
              const firstErrorItem = Object.keys(error.response.data)[0];
              setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
          } else {
              const errorText = `Unknown error 'updating' profile`;
              setErrors(errorText);
              console.error(errorText, error);
          }
      }
  },
  });

    // Effect to set initial form values from subscription to check for form changes
    useEffect(() => {
      setTimeout(() => {
          setInitialValues(formik.initialValues);
          setUpdatedValues(formik.initialValues);
      }, 500);
      setHasMadeChanges(false);
  }, [authData.isFreeUser, open]);

  // Update the updatedValues state when formik values change
  useEffect(() => {
      setUpdatedValues(formik.values);
  }, [formik.values]);

  // Function to check if form has changed so the save button becomes active on edit
  const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
      for (const key in formValues) {
          if (key === "expiry_date") {
              const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
              const initialDate = dayjs(formValues[key]).format('YYYY-MM-DD');
              if (formDate !== initialDate) {
                  console.log("Changed Date", formDate, initialDate)
                  return true;
              }
          } else {
              if (formValues[key]!= flatModel[key]) {
                  console.log("Changed Data", formValues[key], flatModel[key])
                  return true;
              }
          }
      }
      return false;
  };
          
  // Use Effect to check if any changes ahve been made to the form on Edit
  useEffect(() => {
      if (authData.user) {
          if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
              const formChanged = hasFormChanged(initialValues, updatedValues);
              setHasMadeChanges(formChanged);
          }
      }
  }, [updatedValues, initialValues, authData.user]);



  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated == true && authData.isFreeUser ? (
    <Navigate to="/account/subscription"/>
    ) : authData.isAuthenticated == true && authData.isFreeUser === false ? (

            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit} style={{}}> 
                <Card sx={{ height: "100%", boxShadow: 10, p:2, marginX: 5, marginY: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                    {errors && (
                      <Alert severity="error" style={{ width: '100%' }}>
                        <AlertTitle>Error</AlertTitle>
                        {errors}
                      </Alert>
                    )}
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        margin: 'auto',
                        padding: '1em',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                  <Grid container spacing={5} alignItems="top" justifyContent="center" sx={{}}>
                  <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
                          <Card sx={{ boxShadow: 10, p: 2, height: '100%', width: '100%' }}>
                            <div
                              className="form-group"
                              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                            >
                              <Typography variant="h6" gutterBottom sx={{ color: '#6987C9', fontWeight: 'bold' }}>
                                My Details
                              </Typography>
                                <Field name="first_name" type="text" className="form-control" label="First Name" component={TextEditField} />
                                <Field name="last_name" type="text" className="form-control" label="Last Name" component={TextEditField} />
                              <Field name="email" type="text" className="form-control" label="Email" component={TextEditField} />
                            </div>
                          </Card>
                        </Grid>
          
                        <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
                          <Card sx={{ boxShadow: 10, p: 2, height: '100%' }}>
                            <div
                              className="form-group"
                              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                            >
                              <Typography variant="h6" sx={{ color: '#6987C9', fontWeight: 'bold' }} gutterBottom>
                                My Stats
                              </Typography>
                              <Field name="user_type" type="text" className="form-control" label="User Type" component={TextEditReadOnlyField} />
                              <Field name="date_joined" type="text" className="form-control" label="Date Created" component={TextEditReadOnlyField} />
                              <Field name="last_login" type="text" className="form-control" label="Last Login" component={TextEditReadOnlyField} />
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
          
                    <div className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Button
                            className="form-group"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            sx={{
                                position: "relative",
                                m: 1,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className="form-group"
                            type="submit"
                            variant="contained"
                            disabled={!hasMadeChanges}
                            sx={{
                                type:"submit",
                                position: "relative",
                                m: 1,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                                animation: hasMadeChanges ? 'growShrink 0.5s infinite alternate' : 'none',
                                '&.Mui-disabled': {
                                    color: "white", // Text color when disabled
                                    opacity: 0.5,
                                },
                            }}
                        >
                            Save Changes
                        </Button>
                    </div>
                  </Card>
              </form>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <MuiAlert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                     Profile successfully updated
                    </MuiAlert>
                </Snackbar>
            </FormikProvider>
) : (null);
};
export default EditProfileCard;

