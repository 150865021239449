import { Navigate, useNavigate } from "react-router-dom";
import { Button, Card, Divider, Grid, Link, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import RadialGraph from "../../components/graphs/multiradialgraph";
import LineGraph from "../../components/graphs/linegraph";
import BarGraph from "../../components/graphs/bargraph";
import LastFiveRList from '../../components/common/tables/LastFiveRList';
import LastFiveFList from '../../components/common/tables/LastFiveFList';
import { last5columns, organisationColumns } from '../../components/common/tables/Columns';
import api from "../../redux/features/auth/axiosAuthHelper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { refreshAccessToken } from "../../redux/features/auth/authSlice";
import dayjs from 'dayjs';
import colorConfigs from "../../configs/colorConfigs";
import ResultDetailGraph from "../../components/common/graphs/ResultDetailGraph";



type Props = {}

interface SortingState {
  id: string;
  desc?: boolean;
}

interface ColumnVisibilityState {
  [key: string]: boolean;
}

interface FilterState {
  id: string;
  value: string;
}

interface TableState {
  sorting: SortingState[];
  columnVisibility: ColumnVisibilityState;
  columnFilters: FilterState[];
  pagination: { pageSize: number; pageIndex: number };
}

// Initial state
const initialState: TableState = {
  sorting: [],
  columnVisibility: {},
  columnFilters: [],
  pagination: { pageSize: 50, pageIndex: 0 },
};

const HomePage = (props: Props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const [Fcolumns, setFColumns] = useState(last5columns["F"]);
    const [Rcolumns, setrColumns] = useState(last5columns["R"]);
    const [RMData, setRMData] = useState<any>();
    const [RMTotal, setRMTotal] = useState<any>();
    const [FBData, setFBData] = useState<any>();
    const [FMTotal, setFMTotal] = useState<any>();
    const [RData, setRData] = useState<any[]>([]);
    const [FData, setFData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const authData = useSelector((state: RootState) => state.auth);

    useEffect(() => {
      // fetch results and feedback data (last 5 results, last 5 feedback, today's results and today's feedback)
      const today = dayjs().format('YYYY-MM-DD')
      const fetchData = () => {
        const today = dayjs().format('YYYY-MM-DD')

        api
        .get(`/api/results/latestresults`, {
          headers:{
            Authorization: `Bearer ${authData.access}`,
          },
        })
        .then((res) => {
          console.log("latestresults", res.data)
          setRData(res.data)
          setIsLoading(false);
        })
        .catch((err) =>{
          console.log(err);
        })

        api
        .get(`/api/feedback/latestfeedback`, {
          headers:{
            Authorization: `Bearer ${authData.access}`,
          },
        })
        .then((res) => {
          console.log("latestfeedback", res.data)
          setFData(res.data)
          setIsLoading(false);
        })
        .catch((err) =>{
          console.log(err);
        })

        api
        .get(`/api/results/todaysresults?from_date=${today}&to_date=${today}`, {
          headers:{
            Authorization: `Bearer ${authData.access}`,
          },
        })
        .then((res) => {
          console.log("todaysresults", res.data)
          let totalRM = res.data?.length
          let positiveRM = 0
          let negativeRM = 0
          res.data.forEach((item: any) => {
            const visual_result = item.visual_result.toLowerCase() 
            if (visual_result === "positive") {
              positiveRM++;
            }
            if (visual_result === "negative") {
              negativeRM++;
            }
          });
          let resultsMdata = [positiveRM, negativeRM]
          setRMData(resultsMdata);
          setRMTotal(totalRM);
          setIsLoading(false);
        })
        .catch((err) =>{
          console.log(err);
        })

        api
        .get(`/api/feedback/todaysfeedback?from_date=${today}&to_date=${today}`, {
          headers:{
            Authorization: `Bearer ${authData.access}`,
          },
        })
        .then((res) => {
          console.log("todaysfeedback", res.data)
          let totalFM = res.data?.length
          let readerFeedbackGood = 0
          let readerFeedbackBad = 0
          let testFeedbackGood = 0
          let testFeedbackBad = 0
          res.data.forEach((item: any) => {
            const readerFeedback = item.reader_feedback.toLowerCase();
            const testFeedback = item.test_feedback.toLowerCase();
          
            if (readerFeedback === "good") {
              readerFeedbackGood++;
            } else if (readerFeedback === "bad") {
              readerFeedbackBad++;
            }
          
            if (testFeedback === "good") {
              testFeedbackGood++;
            } else if (testFeedback === "bad") {
              testFeedbackBad++;
            }
          });
          
          let Feedbackdata = [readerFeedbackGood, readerFeedbackBad, testFeedbackGood, testFeedbackBad] // new states to user in graph
          setFBData(Feedbackdata);
          setFMTotal(totalFM);
          setIsLoading(false);
        })
        .catch((err) =>{
          console.log(err);
        })
      };
      fetchData()
      dispatch(refreshAccessToken());
    }, [authData.access]);
    return authData.isFreeUser ?  (<Navigate to="/account/subscription"/>) : (
      <div>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Grid container spacing={1} sx={{height: '100px', width: '70%'}}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Card sx={{mt: '1%', pt:'2%', height: '100px', background: colorConfigs.sub_banner.secondary_sub}}>
              <Tooltip title="Upgrade your subscription!">
                <Link 
                  href="/admin" 
                  underline="none" 
                  sx={{
                    color: 'white',
                    fontSize: '24px',
                    pt: '1.5%',
                  }}>
                  Subscription Tier
                </Link>
              </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip title="Go to your Admin panel">
                  <Link 
                    href="/admin" 
                    underline="none" 
                    sx={{
                      color: 'white',
                      fontSize: '24px',
                      pt: '1.5%',
                    }}>
                    Organisation_Name
                  </Link>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip title="Add more users">
                    <Link 
                      href="/admin" 
                      underline="none" 
                      sx={{
                        color: 'white',
                        fontSize: '24px',
                        pt: '1.5%',
                      }}>
                      Users/MaxUsers
                    </Link>
                  </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip title="Increase your maximum monthly results">
                  <Link 
                    href="/admin" 
                    underline="none" 
                    sx={{
                      color: 'white',
                      fontSize: '24px',
                      pt: '1.5%',
                    }}>
                    Results/MaxResults
                  </Link>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                  <Card sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography variant="body2" color="text.secondary" align="center">
                          Latest results
                    </Typography>
                    <LastFiveRList
                        columns={Rcolumns}
                        data={RData}
                        isLoading={isLoading}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography variant="body2" color="text.secondary" align="center" sx={{p:1, fontSize: "20px"}}>
                        Today's Results
                    </Typography>
                      <RadialGraph total={RMTotal} data={RMData}/>
                  </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography variant="body2" color="text.secondary" align="center">
                          Users
                    </Typography>
                    <LastFiveRList
                        columns={Rcolumns}
                        data={RData}
                        isLoading={isLoading}
                      />
                  </Card>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{height:'35vh', background: colorConfigs.sub_banner.secondary_sub}}>
                <LineGraph data={[]}/>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
   ) 
};

export default HomePage;