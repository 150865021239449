import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, TextField, IconButton, Paper } from "@mui/material";
import api from "../../../redux/features/auth/axiosAuthHelper";

import AddIcon from "@mui/icons-material/Add";
import StripCard from "./StripCard";
import { ImageItem } from "./ImageGrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface TabData {
  index: number;
  label: string;
  create: boolean;
}

interface TabbedStripsProps {
  method_id: number;
  image?: ImageItem;
}

const TabbedStripsCard: React.FC<TabbedStripsProps> = ({
  method_id,
  image,
}) => {
  const defaultTab = {
    index: 0,
    label: `Strip 1`,
    create: true,
  };
  const [tabs, setTabs] = useState<TabData[]>([defaultTab]);
  const [selectedTab, setSelectedTab] = useState(0);
  const authData = useSelector((state: RootState) => state.auth);

  console.log(
    `TabbedStripsCard selectedTab=${selectedTab} method_id=${method_id} image=${image}`
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(
      `handleTabChange newValue=${newValue} selectedTab=${selectedTab}`
    );
    setSelectedTab(newValue);
  };

  const handleAddTab = () => {
    console.log("handleAddTab");
    const newPosition = tabs.length;
    setTabs([
      ...tabs,
      {
        index: newPosition,
        label: `Strip ${newPosition + 1}`,
        create: true,
      },
    ]);
    setSelectedTab(newPosition);
  };

  const handleLabelChange = (index: number, newLabel: string) => {
    console.log("handleLabelChange");
    setTabs(
      tabs.map((tab) =>
        tab.index === index ? { ...tab, label: newLabel } : tab
      )
    );
  };

  useEffect(() => {
    const fetchMethodData = async () => {
      console.log(method_id, authData.isAuthenticated, defaultTab);
      if (!method_id || !authData.isAuthenticated) {
        setTabs([defaultTab]);
        setSelectedTab(0);
        return;
      }

      try {
        const response = await api.get(`methods/${method_id}/`, {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });

        const data = response.data.strips;
        console.log("fetchMethodData response.data", response.data);
        const tabsData = response.data.strips
          ? data.map((item: { index: number; label: string }) => ({
              index: item.index,
              label: item.label,
            }))
          : [];
        setTabs(tabsData.length ? tabsData : [defaultTab]);
        setSelectedTab(tabsData.length ? tabsData[0].index : 0);
      } catch (error) {
        console.error("Error fetching method", error);
      }
    };

    fetchMethodData();
  }, [method_id, authData.isAuthenticated]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Strips"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.index}
            label={
              <TextField
                value={tab.label}
                onChange={(e) => handleLabelChange(tab.index, e.target.value)}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
            }
            wrapped
          />
        ))}
        <IconButton onClick={handleAddTab} color="primary">
          <AddIcon />
        </IconButton>
      </Tabs>
      {tabs.map((tab, index) => (
        <Box
          key={tab.index}
          role="tabpanel"
          hidden={selectedTab !== tab.index}
          sx={{ p: 3 }}
        >
          <StripCard method_id={method_id} image={image} {...tab} />
        </Box>
      ))}
    </Box>
  );
};

export default TabbedStripsCard;
