import { Alert, Box, AlertTitle, Snackbar, Button, Card, Divider, FormControlLabel, FormGroup, Grid, IconButton, Link, Paper, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { isLoggedIn, refreshToken } from '../../../redux/features/auth/authService';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { FormIsActiveSwitch, OrgIDTextField, FormUserFirstNameTextField, FormUserIDTextField, FormUserLastNameTextField, OrgNameTextField, OrgTierTextField } from '../../../components/common/fields/ProfileFeilds';
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import { SyntheticEvent } from 'react';
import { SnackbarCloseReason } from '@mui/material';
import { TextEditField, TextEditReadOnlyField } from '../fields/fields';
import dayjs from 'dayjs';

const loginpage = "/login"

type Props = {}


const EditOrganisationalAdminCard = (props: Props) => {
const [open, setOpen] = useState(false);

const [allowEdit, setAllowEdit] = useState<boolean>(false)
const [errors, setErrors] = useState<any>(null)
const [initialValues, setInitialValues] = useState<any>("")
const [updatedValues, setUpdatedValues] = useState<any>("")
const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [hasMadeChanges, setHasMadeChanges] = useState<any>(false)

const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
if (reason === 'clickaway') {
    return;
}
setOpen(false);
};
const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
setOpen(false);
};

const dispatch = useDispatch<AppDispatch>();

useEffect(() => {
    if (authData.user) {
        formik.setFieldValue('id', authData.user.id);
        formik.setFieldValue('first_name', authData.user.first_name);
        formik.setFieldValue('last_name', authData.user.last_name);
        formik.setFieldValue('email', authData.user.email);
        formik.setFieldValue('user_type', authData.user.user_type);
        formik.setFieldValue('last_login', authData.user.last_login);
        formik.setFieldValue('is_active', authData.user.is_active);
    }
}, [authData.user]);

const validationSchema = () => {
    return Yup.object().shape({
        first_name: Yup.string().required("This field is required"), 
        last_name: Yup.string().required("This field is required"),
        email: Yup.string().required("This field is required").email(),
    });
}

const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        user_type: "",
        last_login: "",
        is_active: false
    },
    validationSchema,
    onSubmit: async (values) => {
        setOpen(false)
        const patch_data = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            }

        try {
            await api.patch(`profile/`, patch_data, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }
            })
            setErrors(null);
            setOpen(!open)
            setAllowEdit(!allowEdit)
            dispatch(fetchUserProfile());
            console.log(`Org admin profile edited successfully.`);
        } catch (error: any) {
            if (error?.response?.status === 400) {
                const firstErrorItem = Object.keys(error.response.data)[0];
                setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
                const errorText = `Unknown error 'updating' org admin profile`;
                setErrors(errorText);
                console.error(errorText, error);
            }
        }
    },
    });

        // Effect to set initial form values from subscription to check for form changes
        useEffect(() => {
            setTimeout(() => {
                setInitialValues(formik.initialValues);
                setUpdatedValues(formik.initialValues);
            }, 500);
            setHasMadeChanges(false);
        }, [authData.isFreeUser, open]);
      
        // Update the updatedValues state when formik values change
        useEffect(() => {
            setUpdatedValues(formik.values);
        }, [formik.values]);
      
        // Function to check if form has changed so the save button becomes active on edit
        const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
            for (const key in formValues) {
                if (key === "expiry_date") {
                    const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                    const initialDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                    if (formDate !== initialDate) {
                        console.log("Changed Date", formDate, initialDate)
                        return true;
                    }
                } else {
                    if (formValues[key]!= flatModel[key]) {
                        console.log("Changed Data", formValues[key], flatModel[key])
                        return true;
                    }
                }
            }
            return false;
        };
                
        // Use Effect to check if any changes ahve been made to the form on Edit
        useEffect(() => {
            if (authData.user) {
                if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                    const formChanged = hasFormChanged(initialValues, updatedValues);
                    setHasMadeChanges(formChanged);
                }
            }
        }, [updatedValues, initialValues, authData.user]);
      
      

        return (
            <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
              <Card
                sx={{
                  boxShadow: 10,
                  p: 2,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormikProvider value={formik}>
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                  >
                    <div style={{ flexGrow: 0 }}>
                      <Typography variant="h6" gutterBottom sx={{ color: '#6987C9', fontWeight: 'bold' }}>
                        Organisation Admin
                      </Typography>
                      {errors && (
                        <Alert severity="error" style={{ width: '100%' }}>
                          <AlertTitle>Error</AlertTitle>
                          {errors}
                        </Alert>
                      )}
                      <Field
                        name="id"
                        type="text"
                        className="form-control"
                        label="ID"
                        component={TextEditReadOnlyField}
                      />
                      <Field
                        name="first_name"
                        type="text"
                        className="form-control"
                        label="First Name"
                        component={TextEditField}
                      />
                      <Field
                        name="last_name"
                        type="text"
                        className="form-control"
                        label="Last Name"
                        component={TextEditField}
                      />
                      <Field
                        name="email"
                        type="text"
                        className="form-control"
                        label="Email"
                        component={TextEditField}
                      />
                      <Field
                        name="user_type"
                        type="text"
                        className="form-control"
                        label="User Type"
                        component={TextEditReadOnlyField}
                      />
                    </div>
                    
                    {/* Button at the bottom */}
                    <div
                      style={{
                        marginTop: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Button
                        className="form-group"
                        type="submit"
                        variant="contained"
                        disabled={!hasMadeChanges}
                        sx={{
                          type: 'submit',
                          position: 'relative',
                          m: 1,
                          color: 'white',
                          textTransform: 'none',
                          fontSize: 16,
                          animation: hasMadeChanges ? 'growShrink 0.5s infinite alternate' : 'none',
                          '&.Mui-disabled': {
                            color: 'white',
                            opacity: 0.5,
                          },
                        }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </form>
                </FormikProvider>
              </Card>
              <Snackbar open={open} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                  Organisational Admin Details successfully updated
                </MuiAlert>
              </Snackbar>
            </Grid>
          );
          
      
};

export default EditOrganisationalAdminCard;

