import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export interface ImageItem {
  id: string;
  result_id: string;
  image_url: string;
  homography: number[];
}

interface ImageGridProps {
  setSelectedImage: (selectedImage: ImageItem | undefined) => void;
  selectedImage: ImageItem | undefined;
  onDoubleClick?: () => void;
}

const ImageGrid: React.FC<ImageGridProps> = ({
  selectedImage,
  setSelectedImage, // Accept action callback
  onDoubleClick,
}) => {
  const authData = useSelector((state: RootState) => state.auth);
  const [items, setItems] = useState<ImageItem[]>([]);
  const [nextPage, setNextPage] = useState<string | null>(
    "result-images?limit=15"
  );
  const [loading, setLoading] = useState(false);

  const fetchImages = useCallback(
    async (nextUrl: string | null, access: string | null) => {
      // console.log(`ImageGrid fetchImages INI - ${loading} || ${!nextPage}`);
      if (!nextUrl || loading || !access) return;
      setLoading(true);
      try {
        const response = await api.get(nextUrl, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        console.log("ImageGrid fetchImages SUCCESS");

        setItems((prevItems: ImageItem[]) => {
          const existingIds = new Set(
            prevItems.map((item: ImageItem) => item.id)
          );
          const newItems = response.data.results.filter(
            (item: ImageItem) => !existingIds.has(item.id)
          );
          return [...prevItems, ...newItems];
        });

        setNextPage(response.data.next);
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const observer = useRef<IntersectionObserver | null>(null);

  const lastImageRef = useCallback(
    async (node: HTMLDivElement) => {
      console.log("ImageGrid lastImageRef INI");
      if (loading) {
        console.log("ImageGrid lastImageRef loading=true END");
        return;
      }
      if (observer.current) observer.current.disconnect();

      if (node) {
        console.log("ImageGrid lastImageRef node is NOT null");
        observer.current = new IntersectionObserver(async (entries) => {
          console.log(entries[0]);
          console.log(
            `ImageGrid IntersectionObserver INI, ${entries[0].isIntersecting} && ${nextPage}`
          );
          if (entries[0].isIntersecting && nextPage) {
            console.log("ImageGrid IntersectionObserver inside IF");
            await fetchImages(nextPage, authData.access);
          }
          console.log(
            `ImageGrid IntersectionObserver END, ${entries[0].isIntersecting} && ${nextPage}`
          );
        });

        if (node) observer.current.observe(node);
      } else {
        console.log("ImageGrid lastImageRef node is null");
      }
      console.log("ImageGrid lastImageRef END");
    },
    [loading, nextPage, authData.access, fetchImages]
  );

  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  const handleImageDoubleClick = (image: ImageItem) => {
    setSelectedImage(image);
    if (onDoubleClick) onDoubleClick();
  };

  useEffect(() => {
    if (authData.isAuthenticated) {
      console.log("ImageGrid useEffect");
      fetchImages(nextPage, authData.access); // Initial load
    }
  }, [nextPage, authData.access]);

  return (
    <div>
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid
            item
            xs={4}
            key={item.id}
            ref={index === items.length - 1 ? lastImageRef : null}
          >
            <img
              src={item.image_url}
              alt={item.id}
              width="100%"
              style={{
                cursor: "pointer",
                border:
                  selectedImage?.id === item.id ? "5px solid blue" : "none",
              }}
              onClick={() => handleImageSelect(item)}
              onDoubleClick={() => handleImageDoubleClick(item)}
            />
          </Grid>
        ))}
      </Grid>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default ImageGrid;
