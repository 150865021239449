import { Box, Card, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import SignInDialog from '../../components/common/SignInDialog';
import api from '../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import colorConfigs from '../../configs/colorConfigs';
import SubscriptionsCard from "../../components/common/cards/SubscriptionsCard";
import UpgradeCard from "../../components/common/cards/UpgradeCard";


const SubscriptionPage = () => {
  
  const authData = useSelector((state: RootState) => state.auth);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [myTierIndex, setMyTierIndex] = useState<number>(0);
  const [selectedUpgrade, setSelectedUpgrade] = useState<any>('')
  const [currentTier, setCurrentTier] =  useState<any>('')


  const handleUpgradeSelection = (tier: any) => {
    if (subscriptions) {
      const upgrade = subscriptions.find((sub) => sub.tier === tier);
      setSelectedUpgrade(upgrade)
      console.log('selected', upgrade)
    }
    console.log('current', currentTier)
  }

  useEffect(() => {
    fetchData();
  }, [authData.access, selectedUpgrade]);

  const fetchData = async () => {
    try {
      const response = await api.get('subscription-plans/', {
        headers: {
          Authorization: `Bearer ${authData.access}`,
        },
      });
      const updatedSubscriptions = response.data.results.map((sub:any, index:number) => {
        sub["my_tier"] = false
        if (sub.tier === authData.user?.subscription_tier) {
          sub["my_tier"] = true
          setMyTierIndex(index)
          setCurrentTier(sub)
        }
        if (authData.user) {
          const upgradeTier = sub.tier > authData.user?.subscription_tier
          sub["upgrade"] = upgradeTier
          const downgradeTier = sub.tier < authData.user?.subscription_tier
          sub["downgrade"] = downgradeTier
          const freeTier = sub.tier === 0 ? true : false
          sub["freeTier"] = freeTier
          if (freeTier) {
              sub["price_per_month"] = 0.00
          }
        }
          // Backgorund colour
        if (sub.my_tier) {
          sub["backgroundColor"] = colorConfigs.subscription.mySubscription
        } else if (selectedUpgrade.tier === sub.tier){
          sub["backgroundColor"] = colorConfigs.subscription.selected
        } else {
          sub["backgroundColor"] = 'inherit'
        }

        return sub
      })
      setSubscriptions(updatedSubscriptions);
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
    }
  };

  console.log(subscriptions);

  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated == true ? (
      <div style={{ overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw`, margin: "auto"}}>
      <div style={{ display: "flex", height: "100%", width: "100%"}}>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
            <Box sx={{ m:0, p:4, height: "100%", overflowY: "auto", display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw` }}>

              {subscriptions.length < 1 ? 
              <CircularProgress />
              :
              <Grid container spacing={2} sx={{ flexGrow: 1, minHeight: '80vh'  }}>
                <Grid item sm={12} lg={9}>
                  <Card sx={{ height: '100%', boxShadow: 10, p: 1}}>
                    <SubscriptionsCard  handleUpgradeSelection={handleUpgradeSelection} subscriptions={subscriptions} myTierIndex={myTierIndex} selectedUpgrade={selectedUpgrade}/>
                  </Card>
                </Grid>
                <Grid item sm={12} lg={3} sx={{pb: {lg: 0, md: 6}}}>
                  <Card sx={{ height: '100%', boxShadow: 10, p: 1}}>
                    <UpgradeCard selectedUpgrade={selectedUpgrade} currentTier={currentTier} />
                  </Card>
                </Grid>
              </Grid>
              }

            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
) : (null);
};
export default SubscriptionPage;

