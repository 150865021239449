import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Theme, Switch } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { tsXLXS } from 'ts-xlsx-export';

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface ResultsTableProps {
    authData: any;
    rows: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setResult: (detail: any) => void;
    setPopup: (detail: any) => void;
    handleRefreshButton: () => void;
  }

const ResultsTable: React.FC<ResultsTableProps> = ({
  authData,
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setResult,
  setPopup,
  handleRefreshButton
}) => {

    console.log("Results", rows)
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Result<br />ID
            </div>, 
            filterFn: (row, id, filterValue) => {
              const idValue = row.getValue(id);          
              // Ensure both values are numbers before comparison
              return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
              },   
        },  
        {
          accessorKey: 'sample_id',
          header: 'Sample ID',
          Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Sample<br />ID
          </div>,   
      },  
        {
            accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
            header: 'Timestamp',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Timestamp<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
              ),
        },
        {
          accessorKey: 'test_configuration.collection_mode',
          header: 'Data Collector Mode',
          Header: () => (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
              Data Collector<br />Mode
            </div>
          ),
          Cell: ({ cell }) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Switch
                checked={cell.getValue()?.toString() === "true"}
                color="primary"
                inputProps={{ 'aria-label': 'Data Collector Mode' }}
              />
            </div>
          ),
          Filter: ({ column }) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Switch
                checked={column.getFilterValue() === "true"}
                onChange={(e) => column.setFilterValue(e.target.checked ? "true" : "false")}
                color="primary"
                inputProps={{ 'aria-label': 'Filter Data Collector Mode' }}
              />
            </div>
          ),
          filterFn: 'equals', // or you can create a custom filter function if needed
        },
        {
            accessorKey: 'method_id',
            header: "Method",
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                             Method<br />ID
                        </div>,
            filterFn: (row, id, filterValue) => {
              const idValue = row.getValue(id);          
              // Ensure both values are numbers before comparison
              return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
              },  
        },
        {
            accessorKey: 'housing_id',
            header: "Housing",
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Housing<br />ID
                        </div>,
            filterFn: (row, id, filterValue) => {
              const idValue = row.getValue(id);          
              // Ensure both values are numbers before comparison
              return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
              },  
        },
        {
          accessorKey: 'organisation_id',
          header: 'Organisation ID',
          Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Organisation<br />ID
          </div>, 
          filterFn: (row, id, filterValue) => {
              const idValue = row.getValue(id);          
              // Ensure both values are numbers before comparison
              return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
              },    
      },  
        {
            accessorKey: 'user_id',
            header: 'User ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            User<br />ID
            </div>, 
            filterFn: (row, id, filterValue) => {
                const idValue = row.getValue(id);          
                // Ensure both values are numbers before comparison
                return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
                },    
        },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: ResultSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        ResultTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



    return <MaterialReactTable
                columns={columns}
                data={rows}
                state={{
                isLoading,
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                showGlobalFilter
                }}
                onSortingChange={setSortingFunction}
                onColumnVisibilityChange={setColumnVisibilityFunction}
                onColumnFiltersChange={setFilterChangeFunction}
                onPaginationChange={setPaginationChangeFunction}
                onGlobalFilterChange={setGlobalFilterChangeFunction}
                onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
                defaultColumn={{    
                minSize: 10,
                maxSize: 160,
                size: 10,
                }}
                positionActionsColumn="last"
                enableRowSelection
                selectAllMode="all"
                // enableRowActions
                initialState={{
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                density: 'compact',
                columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
                }}

            // TABLE STYLING IS IN THE THEME
            displayColumnDefOptions={{
                'mrt-row-actions': {
                header: "Actions", // Renames Actions Column
                },
            }}

            muiTopToolbarProps={({ table }) => ({
                sx: (theme: any) => ({
                  ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
                }),
              })}

            muiTableHeadProps={{
                sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
              }}

            muiTableHeadCellProps={({ column }) => ({
                sx: (theme: any) => ({
                  ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
                  ...(column.id === 'mrt-row-select' && {
                    maxWidth: '50px',
                    width: '50px',
                  }),
                }),
              })}

            muiFilterTextFieldProps={{
                sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
              }}

            muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                    console.log(row.original);
                    setResult(row.original);
                    setPopup('Edit');
                },
                sx: (theme: any) => ({
                    ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
                    backgroundColor:
                    row.index % 2 === 0
                        ? colorConfigs.tables.firstRow
                        : colorConfigs.tables.secondRow,
                }),
                })}

            muiTableBodyProps={{
                sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
                }}
            
                muiPaginationProps={{
                    rowsPerPageOptions: [250, 500, 1000],
                    sx: {
                        padding: 0,  
                        margin: 0,   
                        '& .MuiTablePagination-root': {
                        padding: 0,  
                        margin: 0,   
                        },
                        '& .MuiToolbar-root': {
                        padding: 0,
                        margin: 0,
                        minHeight: '0px',
                        },
                        '& .MuiTablePagination-selectLabel': {
                        margin: 0,
                        },
                        '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                        },
                        '& .MuiTablePagination-actions': {
                        margin: 0,
                        },
                    },
                    }}

            muiTableBodyCellProps={({ cell }) => ({
                // TABLE BODY CELLS
                sx: {
                },
            })}

            muiBottomToolbarProps={{
                // BOTTOM TOOLBAR (Pagination)
                sx: {
                    height: '41px',
                    maxHeight: '41px',
                    minHeight: '41px',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1,
                    backgroundColor: '#fff',
                    boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)', 
                    margin: 0,
                    padding: 0,
                  '& .MuiToolbar-root': {
                    padding: 0,
                    margin: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                  },
                  '& .MuiBox-root': {
                    p: 0,
                  },
                  '& .MuiIconButton-root': {
                    color: colorConfigs.tables.headBg,
                  },
                  '& .MuiTablePagination-root': {
                    margin: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                    color: colorConfigs.buttons.bg,
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: colorConfigs.tables.headBg,
                    padding: 0,
                    margin: 0,
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: colorConfigs.buttons.bg,
                    padding: 0,
                    margin: 0,
                  },
                  '& .MuiTablePagination-actions': {
                    color: colorConfigs.tables.headBg,
                    padding: 0,
                    margin: 0,
                  },
                },
              }}


            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };

                

                const handleCreateDataSet = async (rows: MRT_Row<any>[]) => {
                  // This should open a dialogue box with a card and formik form to create a name for the new data set and post it to FastAPI.  Also need to create data set model in fast api. 
                  
                  // Return early if there are no rows to process
                  // if (rows.length === 0) return;
                
                  // const ids = rows.map(row => row.original.id);
                  // const data = { ids };
                  // const url = `results/dataset`;
                  // const method = 'post';
                
                  // try {
                  //   await api({
                  //     method,
                  //     url,
                  //     data,
                  //     headers: {
                  //       'Authorization': `Bearer ${authData.access}`,
                  //     },
                  //   });
                  //   console.log("Dataset created successfully");
                  // } catch (error) {
                  //   console.error("Error creating dataset:", error);
                  // }
                };

                const handleExportRows = (rows: MRT_Row<any>[]) => {
                  var excel_rows = []
                  var now = dayjs().format('YYYY-MM-DD HH:mm:ss')
                  var filename = now + "_ResultsList"
          
                  for (var x = 0; x < rows.length; x++) {
                    const formattedTimestamp = dayjs(rows[x].original.Timestamp).format('YYYY-MM-DD HH:mm:ss')
                    
                    excel_rows[x] = {
                      "ID": rows[x].original.id,
                      "Sample ID": rows[x].original.sample_id,
                      "Timestamp": formattedTimestamp,
                      "Data Collector Mode": rows[x].original.test_configuration.collection_mode ? "On" : "Off",
                      "Method ID": rows[x].original.method_id,
                      "Housing ID": rows[x].original.housing_id,
                      "Organisation ID": rows[x].original.organisation_id,
                      "User ID": rows[x].original.user_id,
                    }
                  }
          
                  tsXLXS().exportAsExcelFile(excel_rows).saveAsExcelFile(filename);
          
                };

                // const handleExportRows = (rows: MRT_Row<any>[]) => {
                //     if (rows.length === 0) return; // Return early if there are no rows to process
                
                //     const excel_rows: string[] = [];
                //     const now = dayjs().format('YYYY-MM-DD_HH-mm-ss');
                //     const filename = `${now}_results.csv`;
                
                //     // Utility function to capitalize and remove underscores from headers
                //     const formatHeader = (header:any) => {
                //         return header
                //             .split('_')
                //             .map((word:any) => word.charAt(0).toUpperCase() + word.slice(1))
                //             .join(' ');
                //     };


                //     // Dynamically determine column headers based on the keys of the first row
                //     const headers = Object.keys(rows[0].original);
                //     const filteredHeaders = headers.filter((header:any) => header !== "test_strips" && header !== "strip_image_paths");
                
                //     // Prepare test_strip headers
                //     const testStripHeaders = rows[0].original.test_strips?.length ? Object.keys(rows[0].original.test_strips[0]) : [];
                //     const filteredTestStripHeaders = testStripHeaders.filter(header => header !== "c_line" && header !== "lines");
                
                //     // Prepare c_line headers
                //     const cLineHeaders = rows[0].original.test_strips?.[0]?.c_line ? Object.keys(rows[0].original.test_strips[0].c_line) : [];
                
                //     // Prepare test line headers dynamically
                //     const testLineHeaders = rows[0].original.test_strips?.[0]?.lines?.[0] ? Object.keys(rows[0].original.test_strips[0].lines[0]) : [];
                //     const maxTestLines = Math.max(...rows.map(row => row.original.test_strips[0].lines.length));
                //     const dynamicTestLineHeaders = [];
                //     for (let i = 0; i < maxTestLines; i++) {
                //         dynamicTestLineHeaders.push(...testLineHeaders.map((header:any) => `"Testline ${i + 1} ${header}"`));
                //     }
                
                //     // Prepare strip_image_paths headers dynamically
                //     const stripImageHeaders = rows[0].original.strip_image_paths?.map((_:any, index:any) => `"strip image ${index + 1} path"`) || [];
                
                //     // Construct the final header string
                //     const finalHeaderString = [
                //         ...filteredHeaders.map(header => `"${formatHeader(header)}"`),
                //         ...filteredTestStripHeaders.map(header => `"${formatHeader(header)}"`),
                //         ...cLineHeaders.map(header => `"C Line ${(formatHeader(header))}"`),
                //         ...dynamicTestLineHeaders.map(header => formatHeader(header.replace(/"/g, ''))),
                //         ...stripImageHeaders.map((header:any) => `"${formatHeader(header.replace(/"/g, ''))}"`)
                //     ].join(',');
                //     excel_rows.push(finalHeaderString);
                
                //     // Populate the CSV rows
                //     rows.forEach(row => {
                //         const rowArray = filteredHeaders.map(header => {
                //             let cellValue = row.original[header];
                //             if (header === "timestamp") {
                //                 cellValue = dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss');
                //             }
                //             const formattedValue = cellValue ? (typeof cellValue === 'string' ? cellValue.replace(/"/g, '""') : cellValue) : ["tc_ratio", "score", "peak_position"].includes(header) ? "0.0" : "";
                //             return `"${formattedValue}"`;
                //         });
                
                //         const testStripRows = row.original.test_strips.map((test_strip:any) => {
                //             // Add test_strip values
                //             const testStripValues = filteredTestStripHeaders.map((header:any) => {
                //                 const value = test_strip[header];
                //                 return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                //             });
                
                //             // Add c_line values
                //             const cLineValues = cLineHeaders.map(header => {
                //                 const value = test_strip.c_line[header];
                //                 return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                //             });
                
                //             // Add test line values dynamically
                //             const testLineValues = [];
                //             for (let i = 0; i < maxTestLines; i++) {
                //                 const line = test_strip.lines[i] || {};
                //                 testLineValues.push(...testLineHeaders.map(header => {
                //                     const value = line[header];
                //                     return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                //                 }));
                //             }
                
                //             return [...testStripValues, ...cLineValues, ...testLineValues].join(',');
                //         });
                
                //         // Add strip_image_paths values dynamically
                //         const stripImageValues = row.original.strip_image_paths.map((path:any) => {
                //             const value = path;
                //             return `"${value ? value.toString().replace(/"/g, '""') : ''}"`;
                //         });
                
                //         if (row.original.test_strips.length > 0) {
                //             row.original.test_strips.forEach((_:any, index:any) => {
                //                 const combinedRowArray = [...rowArray, ...testStripRows[index].split(','), ...stripImageValues];
                //                 excel_rows.push(combinedRowArray.join(','));
                //             });
                //         } else {
                //             const combinedRowArray = [...rowArray, ...Array(filteredTestStripHeaders.length + cLineHeaders.length + dynamicTestLineHeaders.length).fill('""'), ...stripImageValues];
                //             excel_rows.push(combinedRowArray.join(','));
                //         }
                //     });
                
                //     // Combine all rows into a single CSV string
                //     const csvContent = excel_rows.join('\n');
                
                //     // Trigger download
                //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                //     const link = document.createElement('a');
                //     link.href = URL.createObjectURL(blob);
                //     link.setAttribute('download', filename);
                //     document.body.appendChild(link);
                //     link.click();
                
                //     // Clean up by removing the link after triggering the download
                //     if (link.parentNode) {
                //         link.parentNode.removeChild(link);
                //     }
                // };
                

                return (
                    <Box style={{ display: 'flex', gap: '0.3rem', height: "40px" }}>
                      <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        disableRipple
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{ minHeight: "100%",  minWidth: "7.5em", background: colorConfigs.buttons.bg, color: "#fff", textTransform: 'none', fontWeight: "bold" }}
                      >
                        Bulk Actions
                      </Button>   
                      <Button 
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={() => handleRefreshButton()}
                        sx={{ minHeight: "100%", textTransform: 'none', background: colorConfigs.buttons.bg, color: "#fff", fontWeight: "bold",}}
                        >
                        <RefreshIcon sx={{ p: 0, m: 0 }} />
                    </Button>
                      <Button
                        id="demo-customized-button1"
                        variant="contained"
                        disableElevation
                        onClick={() => handleRemoveAllFilters()}
                        endIcon={<FilterAltOffOutlinedIcon />}
                        sx={{ 
                          minWidth: "5em",
                          minHeight: "100%",
                          background: colorConfigs.buttons.bg, 
                          color: "#fff", 
                          textTransform: 'none', 
                          fontWeight: "bold", 
                        }}
                      >
                        Clear Filters
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                          <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <FileDownloadOutlinedIcon />
                            <Typography>Export to Excel</Typography>
                          </Box>    
                        </MenuItem>
                        <MenuItem disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleCreateDataSet(table.getSelectedRowModel().rows)} disableRipple>
                         <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <PlaylistAddIcon />
                            <Typography>Create New Data Set</Typography>
                          </Box>
                        </MenuItem>
                      </StyledMenu>
                      </Box>
                );
            }}
        />
}
 
export default ResultsTable;

