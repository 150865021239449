import { Outlet } from "react-router-dom";
import { Box, styled, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";

import colorConfigs from "../../configs/colorConfigs";
import React from "react";
import Sidebar from "../Sidebar";
import ProfileButton from "../common/buttons/ProfileButton";

const MainLayout = () => {
  return (
    <Box sx={{ display: "flex", m:0 }}>
      {/* <Topbar /> */}
      <ProfileButton />
      <Box 
        component="nav"
        sx={{
          width: sizeConfigs.sidebar.halfwidth,
          flexShrink: 0,
          overflowY: "auto",
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingX: 3,
          paddingTop: 5,
          width: `calc(100vw - ${sizeConfigs.sidebar.halfwidth})`,
          height: "100vh",
          backgroundColor: colorConfigs.topbar.bg2,
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none', // Internet Explorer 10+
          'scrollbar-width': 'none', // Firefox
          overflowX: "hidden"
        }}
      > 
        <Outlet/>
      </Box>
      
    </Box>
  );
};

// const MainLayout = () => {
//   return (
//     <Box sx={{
//       display: "flex",
//       flexDirection: "column",
//       m: 0,
//       height: "100vh", 
//       width: "100vw",
//       overflowY: 'auto',
//       '&::-webkit-scrollbar': { display: 'none' },
//       '-ms-overflow-style': 'none', // Internet Explorer 10+
//       'scrollbar-width': 'none', // Firefox
//       p: 0
//       }}
//       >
//       <Topbar />
//       <Box 
//         component="nav"
//         sx={{
//           width: sizeConfigs.sidebar.halfwidth,
//           flexShrink: 0,
//           overflowY: "auto",
//         }}
//       >
//         <Sidebar />
//       </Box>
//       <Box
//         component="main"
//         sx={{
//           // paddingTop: '60px', // Header Height
//           // paddingBottom: '45px', // Footer Height
//           // flexGrow: 1,
//           // display: 'flex',
//           // flexDirection: 'row', // Ensure children are laid out horizontally
//           // overflow: 'hidden', // Prevent overflow on the main container
//                     flexGrow: 1,
//           paddingX: 3,
//           paddingTop: 8,
//           width: `calc(100vw - ${sizeConfigs.sidebar.halfwidth})`,
//           height: "100vh",
//           backgroundColor: colorConfigs.topbar.bg2,
//           overflowY: 'auto',
//           '&::-webkit-scrollbar': { display: 'none' },
//           '-ms-overflow-style': 'none', // Internet Explorer 10+
//           'scrollbar-width': 'none', // Firefox
//           overflowX: "hidden"
//         }}
//       > 
//         <Outlet/>
//       </Box>
//       {/* <Footer /> */}
//     </Box>
//   );
// };

export default MainLayout;