
export interface iStorageService<T> {
    get<K extends keyof T>(key: K): T[K] | undefined;
  
    set<K extends keyof T>(key: K, value: T[K]): void;
  
    remove<K extends keyof T>(key: K): void;
  
    clear(): void;
  }

export class StorageService<T> implements iStorageService<T> {
    constructor(private readonly storage: Storage) {}

    set<K extends keyof T>(key: K, value: T[K]): void {
        this.storage.setItem(key.toString(), JSON.stringify(value));
    }

    get<K extends keyof T>(key: K): T[K] | undefined {
        const value = this.storage.getItem(key.toString());

        if (value === null || value === 'null'
        || value === undefined || value === 'undefined') {
        return undefined;
        }

        return JSON.parse(value);
    }
    remove<K extends keyof T>(key: K): void {
        this.storage.removeItem(key.toString());
    }

    clear(): void {
        this.storage.clear();
    }
}

// Remove Session Storage at logout
export const clearAllSessionStorage = () => {
    Object.values(ResultSessionStorageKey).forEach(key => {
      sessionStorage.removeItem(key);
    });
    Object.values(FeedbackSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
      });
    Object.values(UserSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
    Object.values(OrganisationSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
    Object.values(SubscriptionSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
    Object.values(InvoiceSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
    Object.values(HousingSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
    Object.values(MethodSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
  };


export enum ResultSessionStorageKey {
    sorting = 'result_sorting',
    columnVisibility = 'result_columnVisibility',
    pagination = 'result_pagination',
    columnFilters = 'result_columnFilters',
    search = 'result_search',
    showSearch = 'result_showSearch',
    data = 'results_data',
    count = 'result_count',
    filterCriteria = 'result_filter_criteria',
    filters = 'result_filters',
    currentQueryString = 'result_current_query_string'
}

export enum FeedbackSessionStorageKey {
    sorting = 'feedback_sorting',
    columnVisibility = 'feedback_columnVisibility',
    pagination = 'feedback_pagination',
    columnFilters = 'feedback_columnFilters',
    search = 'feedback_search',
    showSearch = 'feedback_showSearch',
    data = 'feedback_data',
    count = 'feedback_count',
    filterCriteria = 'feedback_filter_criteria',
    filters = 'feedback_filters',
    currentQueryString = 'feedback_current_query_string'
}

export enum UserSessionStorageKey {
    sorting = 'user_sorting',
    columnVisibility = 'user_columnVisibility',
    pagination = 'user_pagination',
    columnFilters = 'user_columnFilters',
    search = 'user_search',
    showSearch = 'user_showSearch',
    data = 'user_data',
    count = 'user_count',
    filterCriteria = 'user_filter_criteria',
    filters = 'user_filters',
    currentQueryString = 'user_current_query_string'
}

export enum OrganisationSessionStorageKey {
    sorting = 'organisation_sorting',
    columnVisibility = 'organisation_columnVisibility',
    pagination = 'organisation_pagination',
    columnFilters = 'organisation_columnFilters',
    search = 'organisation_search',
    showSearch = 'organisation_showSearch',
    data = 'organisation_data',
    count = 'organisation_count',
    filterCriteria = 'organisation_filter_criteria',
    filters = 'organisation_filters',
    currentQueryString = 'organisation_current_query_string'

}

export enum SubscriptionSessionStorageKey {
    sorting = 'subscription_sorting',
    columnVisibility = 'subscription_columnVisibility',
    pagination = 'subscription_pagination',
    columnFilters = 'subscription_columnFilters',
    search = 'subscription_search',
    showSearch = 'subscription_showSearch',
    data = 'subscription_data',
    count = 'subscription_count',
    filterCriteria = 'subscription_filter_criteria',
    filters = 'subscription_filters',
    currentQueryString = 'subscription_current_query_string'
}

export enum InvoiceSessionStorageKey {
    sorting = 'invoice_sorting',
    columnVisibility = 'invoice_columnVisibility',
    pagination = 'invoice_pagination',
    columnFilters = 'invoice_columnFilters',
    search = 'invoice_search',
    showSearch = 'invoice_showSearch',
    data = 'invoice_data',
    count = 'invoice_count',
    filterCriteria = 'invoice_filter_criteria',
    filters = 'invoice_filters',
    currentQueryString = 'invoice_current_query_string',
    updatedCardDetails = 'invoice_updated_card_details',
}

export enum HousingSessionStorageKey {
    sorting = 'housing_sorting',
    columnVisibility = 'housing_columnVisibility',
    pagination = 'housing_pagination',
    columnFilters = 'housing_columnFilters',
    search = 'housing_search',
    showSearch = 'housing_showSearch',
    data = 'housing_data',
    count = 'housing_count',
    filterCriteria = 'housing_filter_criteria',
    filters = 'housing_filters',
    currentQueryString = 'housing_current_query_string'
}

export enum MethodSessionStorageKey {
    sorting = 'method_sorting',
    columnVisibility = 'method_columnVisibility',
    pagination = 'method_pagination',
    columnFilters = 'method_columnFilters',
    search = 'method_search',
    showSearch = 'method_showSearch',
    data = 'method_data',
    count = 'method_count',
    filterCriteria = 'method_filter_criteria',
    filters = 'method_filters',
    currentQueryString = 'method_current_query_string'
}

export interface ResultSessionStorageModel {
    [ResultSessionStorageKey.sorting]: any;
    [ResultSessionStorageKey.columnVisibility]: any;
    [ResultSessionStorageKey.pagination]: any;
    [ResultSessionStorageKey.columnFilters]: any;
    [ResultSessionStorageKey.search]: any;
    [ResultSessionStorageKey.showSearch]: any;
    [ResultSessionStorageKey.data]: any;
    [ResultSessionStorageKey.count]: any;
    [ResultSessionStorageKey.filterCriteria]: any;
    [ResultSessionStorageKey.filters]: any;
    [ResultSessionStorageKey.currentQueryString]: any;
  }

export interface FeedbackSessionStorageModel {
    [FeedbackSessionStorageKey.sorting]: any;
    [FeedbackSessionStorageKey.columnVisibility]: any;
    [FeedbackSessionStorageKey.pagination]: any;
    [FeedbackSessionStorageKey.columnFilters]: any;
    [FeedbackSessionStorageKey.search]: any;
    [FeedbackSessionStorageKey.showSearch]: any;
    [FeedbackSessionStorageKey.data]: any;
    [FeedbackSessionStorageKey.count]: any;
    [FeedbackSessionStorageKey.filterCriteria]: any;
    [FeedbackSessionStorageKey.filters]: any;
    [FeedbackSessionStorageKey.currentQueryString]: any;
}

export interface UserSessionStorageModel {
    [UserSessionStorageKey.sorting]: any;
    [UserSessionStorageKey.columnVisibility]: any;
    [UserSessionStorageKey.pagination]: any;
    [UserSessionStorageKey.columnFilters]: any;
    [UserSessionStorageKey.search]: any;
    [UserSessionStorageKey.showSearch]: any;
    [UserSessionStorageKey.data]: any;
    [UserSessionStorageKey.count]: any;
    [UserSessionStorageKey.filterCriteria]: any;
    [UserSessionStorageKey.filters]: any;
    [UserSessionStorageKey.currentQueryString]: any;
}

export interface OrganisationSessionStorageModel {
    [OrganisationSessionStorageKey.sorting]: any;
    [OrganisationSessionStorageKey.columnVisibility]: any;
    [OrganisationSessionStorageKey.pagination]: any;
    [OrganisationSessionStorageKey.columnFilters]: any;
    [OrganisationSessionStorageKey.search]: any;
    [OrganisationSessionStorageKey.showSearch]: any;
    [OrganisationSessionStorageKey.data]: any;
    [OrganisationSessionStorageKey.count]: any;
    [OrganisationSessionStorageKey.filterCriteria]: any;
    [OrganisationSessionStorageKey.filters]: any;
    [OrganisationSessionStorageKey.currentQueryString]: any;
}

export interface SubscriptionSessionStorageModel {
    [SubscriptionSessionStorageKey.sorting]: any;
    [SubscriptionSessionStorageKey.columnVisibility]: any;
    [SubscriptionSessionStorageKey.pagination]: any;
    [SubscriptionSessionStorageKey.columnFilters]: any;
    [SubscriptionSessionStorageKey.search]: any;
    [SubscriptionSessionStorageKey.showSearch]: any;
    [SubscriptionSessionStorageKey.data]: any;
    [SubscriptionSessionStorageKey.count]: any;
    [SubscriptionSessionStorageKey.filterCriteria]: any;
    [SubscriptionSessionStorageKey.filters]: any;
    [SubscriptionSessionStorageKey.currentQueryString]: any;
}

export interface InvoiceSessionStorageModel {
    [InvoiceSessionStorageKey.sorting]: any;
    [InvoiceSessionStorageKey.columnVisibility]: any;
    [InvoiceSessionStorageKey.pagination]: any;
    [InvoiceSessionStorageKey.columnFilters]: any;
    [InvoiceSessionStorageKey.search]: any;
    [InvoiceSessionStorageKey.showSearch]: any;
    [InvoiceSessionStorageKey.data]: any;
    [InvoiceSessionStorageKey.count]: any;
    [InvoiceSessionStorageKey.filterCriteria]: any;
    [InvoiceSessionStorageKey.filters]: any;
    [InvoiceSessionStorageKey.currentQueryString]: any;
    [InvoiceSessionStorageKey.updatedCardDetails]: any;
}

export interface HousingSessionStorageModel {
    [HousingSessionStorageKey.sorting]: any;
    [HousingSessionStorageKey.columnVisibility]: any;
    [HousingSessionStorageKey.pagination]: any;
    [HousingSessionStorageKey.columnFilters]: any;
    [HousingSessionStorageKey.search]: any;
    [HousingSessionStorageKey.showSearch]: any;
    [HousingSessionStorageKey.data]: any;
    [HousingSessionStorageKey.count]: any;
    [HousingSessionStorageKey.filterCriteria]: any;
    [HousingSessionStorageKey.filters]: any;
    [HousingSessionStorageKey.currentQueryString]: any;
}

export interface MethodSessionStorageModel {
    [MethodSessionStorageKey.sorting]: any;
    [MethodSessionStorageKey.columnVisibility]: any;
    [MethodSessionStorageKey.pagination]: any;
    [MethodSessionStorageKey.columnFilters]: any;
    [MethodSessionStorageKey.search]: any;
    [MethodSessionStorageKey.showSearch]: any;
    [MethodSessionStorageKey.data]: any;
    [MethodSessionStorageKey.count]: any;
    [MethodSessionStorageKey.filterCriteria]: any;
    [MethodSessionStorageKey.filters]: any;
    [MethodSessionStorageKey.currentQueryString]: any;
}

export const ResultTableParameterStorageService = new StorageService<ResultSessionStorageModel>(
    sessionStorage
);

export const FeedbackTableParameterStorageService = new StorageService<FeedbackSessionStorageModel>(
    sessionStorage
);
  
export const UserTableParameterStorageService = new StorageService<UserSessionStorageModel>(
    sessionStorage
);

export const OrganisationTableParameterStorageService = new StorageService<OrganisationSessionStorageModel>(
    sessionStorage
);

export const SubscriptionTableParameterStorageService = new StorageService<SubscriptionSessionStorageModel>(
    sessionStorage
);

export const InvoiceTableParameterStorageService = new StorageService<InvoiceSessionStorageModel>(
    sessionStorage
);

export const HousingTableParameterStorageService = new StorageService<HousingSessionStorageModel>(
    sessionStorage
);

export const MethodTableParameterStorageService = new StorageService<MethodSessionStorageModel>(
    sessionStorage
);