import React, { ReactNode } from 'react';
import './App.css';
import { routes } from "./routes";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import HomePage from './pages/home/HomePage';
import LoginPage from './pages/auth/loginPage';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import ForgottenPasswordPage from './pages/auth/ForgottenPasswordPage';
import ResetPasswordPage from './pages/auth/PasswordResetPage';
import ActivationPage from './pages/auth/ActivationPage';

const loginpage = "/login";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const authData = useSelector((state: RootState) => state.auth);
  if (!authData.isAuthenticated && authData.access) {
    return <>{children}</> // Show Signin Dialogue Box
  }
  return authData.isAuthenticated ? <>{children}</> : <Navigate to={loginpage} replace />;
};

function App() {
  const authData = useSelector((state: RootState) => state.auth);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
          <Route index element={<HomePage />} />
          {routes}
        </Route>
        <Route path="/login" element={<LoginPage />}>
          <Route index element={<LoginPage />} />
        </Route>
        <Route path="/activation-status" element={<ActivationPage />}>
          <Route path=":token" element={<ActivationPage />} />
        </Route>
        <Route path="/forgot-password" element={<ForgottenPasswordPage />}>
          <Route index element={<ForgottenPasswordPage />} />
        </Route>
        <Route path="/reset/:uidb64/:token" element={<ResetPasswordPage />}>
          <Route index element={<ResetPasswordPage />} />
        </Route>
        <Route path="*" element={<Navigate to={authData.isAuthenticated ? "/" : loginpage} replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
