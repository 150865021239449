

import { Card, Grid, Box, Button, Typography, InputLabel, Table, TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Switch, Dialog, DialogContent, CircularProgress, Collapse, IconButton, Tooltip } from '@mui/material';
import React, { Component, useEffect, useState, useRef } from 'react'
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
//import PostService from "../../../services/post.service";
import { Navigate, Link as routerLink } from "react-router-dom";
// import { TCRatioField, TextDetailField, FormVisualResultsField, ImageField, TScoreField, CScoreField, CPeakField, NotesField, TPeakField  } from '../fields/resultDetailFields';
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs, { Dayjs } from 'dayjs';
import colorConfigs from '../../../configs/colorConfigs';
import ResultDetailGraph from '../graphs/ResultDetailGraph';
import { useTheme } from '@mui/material/styles';
import { ResultDetailEditField, TCRatioField, TScoreField, CScoreField, CPeakField, TPeakField, NotesField, TextAdornmentReadOnlyField, TextAdornmentNoStatusReadOnlyField, ReadOnlySwitch, ResultDetailTableField } from '../fields/fields';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Slider from "react-slick";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import sizeConfigs from '../../../configs/sizeConfigs';
import fontConfigs from '../../../configs/fontConfigs';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

interface ResultDetailDCMCardProps {
  handleBackButton: () => void;
  popup: any;
  result_id: any;
}

const ResultDetailDCMCard: React.FC<ResultDetailDCMCardProps> = ({ handleBackButton, popup, result_id}) => {

    const [user, setUser] = useState<any>("")
    const authData = useSelector((state: RootState) => state.auth);
    const [result, setResult] = useState<any>(null)
    const [expanded, setExpanded] = useState(''); // Default to 'resultInfo' open on render
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedStrip, setSelectedStrip] = useState({
        baseline: [],
        c_line: {
            score: null,
            peak_position: null
        },
        lines: [
            {t_color: '',}
        ],
        c_color: '',
        profile_color: '',
        baseline_color: '',
        main_image: "",
        mean_profile: [],
        pmf_story: [
            {
                baseline: [],
                full_image: "",
                homography: [],
                lux: null,
                orientation: [],
                pmf_status: null,
                profile: [],
                strip_image: "",
                strip_statuses: [],
                timestamp: ""
            }
        ]
    });
    const [openMainImagePopup, setOpenMainImagePopup] = useState(false);
    const [openStripImagePopup, setOpenStripImagePopup] = useState(false);
    const [selectedStripImage, setSelectedStripImage] = useState("");

    const resultDetailsRef = useRef<HTMLDivElement>(null);
    const configurationRef = useRef<HTMLDivElement>(null);
    
    // Separate state variables for each accordion
    const [resultShowScrollBottom, setResultShowScrollBottom] = useState(false);
    const [configShowScrollBottom, setConfigShowScrollBottom] = useState(false);
    
    //Setting for Carousel
    const sliderRef = useRef<Slider>(null); // Reference for the Slider
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)

    const handleThumbnailClick = (index: number) => {
        setSelectedImageIndex(index);
        sliderRef.current?.slickGoTo(index); // Navigate to the selected slide
    };

    const handlePrev = () => {
        const newIndex = selectedImageIndex === 0 ? pmfStories.length - 1 : selectedImageIndex - 1;
        setSelectedImageIndex(newIndex);
        sliderRef.current?.slickGoTo(newIndex); // Navigate to the previous slide
    };

    const handleNext = () => {
        const newIndex = (selectedImageIndex + 1) % pmfStories.length;
        setSelectedImageIndex(newIndex);
        sliderRef.current?.slickGoTo(newIndex); // Navigate to the next slide
    };
    const settings = {
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Show 3 slides
        slidesToScroll: 1,
        centerMode: true, // Center the middle slide
        focusOnSelect: true,
        beforeChange: (_: any, next: any) => setSelectedImageIndex(next),
        arrows: false,
    };

    const [pmfStories, setPmfStories] = useState<any[]>([])

    // State for dialog and selected image URL
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');

    // Open dialog and set selected image URL
    const handleOpenDialog = (imageUrl: any) => {
        setSelectedImageUrl(imageUrl);
        setOpenDialog(true);
    };

    // Close dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Download image function
    const handleOpenInNewTab = (imageUrl: any) => {
        window.open(imageUrl, '_blank');
    };


    // Updated checkScroll function to handle different refs
    const checkScroll = (ref: any, setShowScrollBottom: any) => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;
            setShowScrollBottom(scrollTop + clientHeight < scrollHeight);
        }
    };
    
    // Updated scrollContent function to scroll specific ref
    const scrollContent = (ref: any, direction: any) => {
        ref.current?.scrollBy({
            top: direction === 'down' ? 600 : -600,
            behavior: 'smooth'
        });
    };
    
    // Set up scroll event listeners for each ref
    useEffect(() => {
        const handleResultScroll = () => checkScroll(resultDetailsRef, setResultShowScrollBottom);
    
        if (resultDetailsRef.current) {
            // Delay initial check to ensure content is fully loaded
            setTimeout(handleResultScroll, 100);
    
            // Add scroll listener
            resultDetailsRef.current.addEventListener('scroll', handleResultScroll);
        }
    
        return () => {
            resultDetailsRef.current?.removeEventListener('scroll', handleResultScroll);
        };
    }, [resultDetailsRef.current?.scrollHeight, resultDetailsRef.current?.clientHeight]);
    
    useEffect(() => {
        const handleConfigScroll = () => checkScroll(configurationRef, setConfigShowScrollBottom);
    
        if (configurationRef.current) {
            // Delay initial check to ensure content is fully loaded
            setTimeout(handleConfigScroll, 100);
    
            // Add scroll listener
            configurationRef.current.addEventListener('scroll', handleConfigScroll);
        }
    
        return () => {
            configurationRef.current?.removeEventListener('scroll', handleConfigScroll);
        };
    }, [configurationRef.current?.scrollHeight, configurationRef.current?.clientHeight]);
    


    const handleAccordianChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
      };

    useEffect(() => {
        // When selectedStrip or selectedTab changes, reset carousel to first image
        sliderRef.current?.slickGoTo(0); // Navigate the carousel to the first slide
    }, [selectedStrip, selectedTab]);

    const handleMainImageOpen = () => {
        setOpenMainImagePopup(true);
    };

    const handleMainImageClose = () => {
        setOpenMainImagePopup(false);
    };

    const handleStripImageOpen = (image:any) => {
        setSelectedStripImage(image); // Set the selected strip image source
        setOpenStripImagePopup(true);
    };

    const handleStripImageClose = () => {
        setOpenStripImagePopup(false);
    };

    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    // Fetch Result for Feedback
    useEffect(() => {
        const fetchResult = async () => { 
            try {
                const url = `results/${result_id}/data_collector_mode`
          
                const response = await api.get(url, {
                    headers: {
                        'Authorization': `Bearer ${authData.access}`
                    }
                });
                setResult(response.data);
                console.log("result", response.data)

                try {
                    const url = `users/${response?.data?.user_id}`
              
                    const res = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    setUser(res.data);
                    console.log("user", res.data)
                } catch (error) {
                    console.log(error)
                    console.log(`Cannot find User with id ${result.user_id}`)
                }

            } catch (error) {
                console.error('Error fetching result', error);
            }
          };
            fetchResult()    
        }, [result_id])

    const theme = useTheme();


    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            app_build: '',
            app_version: '',
            device_model: '',
            device_uuid: '',
            full_image: '',
            id: '',
            latitude: '',
            longitude: '',
            notes: '',
            organisation_id: '',
            os_version: '',
            sample_id: '',
            sample_type: '',

            strip_image_paths: [],
            // Test lines
            baseline: '',
            cline_score: '',
            cline_peak_position: '',
            cline_peak_area: '',
            // Lines
            lines: [],
            tline_1_score: '',
            tline_1_area: '',
            tline_1_peak_position: '',
            tline_1_ratio: '',
            tline_2_score: '',
            tline_2_area: '',
            tline_2_peak_position: '',
            tline_2_ratio: '',
            profile: '',

            timestamp: '',
            user_id: '',
            uuid: '',

            // User Data
            result_user_id: '',
            user_first_name: '',
            user_last_name: '',
            user_email: '',
            user_is_active: '',
            user_organisation_id: '',
            user_organisation_name: '',
            user_user_type: '',
            user_subscription_tier: '',

            camera_macro_focus_required: false,
            collection_mode: false,
            control_baseline: false,
            control_baseline_threshold: '',
            control_exposure: false,
            control_line_correction: false,
            flash_required: false,
            frames_to_capture: '',
            pmf_initial_h_scale_factor: '',
            polynomial_baseline_order: '',
            profile_alignment: false,
            profile_control_levels: '',
            reader_resolution: [], 
            test_pmf_classifier_file: '',
            test_pmf_max_conformance_probability: '',
            test_pmf_min_conformance_probability: '',        
        },
        validationSchema,
        onSubmit: (values) => {
        },
    });
    


    useEffect(() => {
        if (result) {
                console.log("Result Data", result)

                setPmfStories(result.pmf_story)

                formik.setFieldValue('app_build', result.app_build);
                formik.setFieldValue('app_version', result.app_version);
                formik.setFieldValue('device_model', result.device_model);
                formik.setFieldValue('device_uuid', result.device_uuid);
                formik.setFieldValue('id', result.id);
                formik.setFieldValue('latitude', result.latitude);
                formik.setFieldValue('longitude', result.longitude);
                formik.setFieldValue('notes', result.notes);
                formik.setFieldValue('organisation_id', result.organisation_id);
                formik.setFieldValue('os_version', result.os_version);
                formik.setFieldValue('sample_id', result.sample_id);
                formik.setFieldValue('sample_type', result.sample_type);
                formik.setFieldValue('method_id', result.method_id);
                formik.setFieldValue('housing_id', result.housing_id);


                formik.setFieldValue('camera_macro_focus_required', result.test_configuration.camera_macro_focus_required);
                formik.setFieldValue('collection_mode', result.test_configuration.collection_mode);
                formik.setFieldValue('control_baseline', result.test_configuration.control_baseline);
                formik.setFieldValue('control_baseline_threshold', result.test_configuration.control_baseline_threshold);
                formik.setFieldValue('control_exposure', result.test_configuration.control_exposure);
                formik.setFieldValue('control_line_correction', result.test_configuration.control_line_correction);
                formik.setFieldValue('flash_required', result.test_configuration.flash_required);
                formik.setFieldValue('frames_to_capture', result.test_configuration.frames_to_capture);
                formik.setFieldValue('pmf_initial_h_scale_factor', result.test_configuration.pmf_initial_h_scale_factor);
                formik.setFieldValue('polynomial_baseline_order', result.test_configuration.polynomial_baseline_order);
                formik.setFieldValue('profile_alignment', result.test_configuration.profile_alignment);
                formik.setFieldValue('profile_control_levels', result.test_configuration.profile_control_levels);
                formik.setFieldValue('reader_resolution', result.test_configuration.reader_resolution);
                formik.setFieldValue('test_pmf_max_conformance_probability', result.test_configuration.test_pmf_max_conformance_probability);
                formik.setFieldValue('test_pmf_min_conformance_probability', result.test_configuration.test_pmf_min_conformance_probability);

                formik.setFieldValue('timestamp', dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                formik.setFieldValue('user_id', result.user_id)
                formik.setFieldValue('uuid', result.uuid)
    
                // Set User Data
                if (user) {
                    formik.setFieldValue('result_user_id', user.id);
                    formik.setFieldValue('user_first_name', user.first_name);
                    formik.setFieldValue('user_last_name', user.last_name);
                    formik.setFieldValue('user_email', user.email);
                    formik.setFieldValue('user_is_active', user.is_active);
                    formik.setFieldValue('user_organisation_name', user.organisation_name);
                    formik.setFieldValue('user_user_type', user.user_type);
                    formik.setFieldValue('user_subscription_tier', user.subscription_tier);
                }
                setTimeout(() => { 
                    setExpanded('resultInfo')  
                }, 1800);
            }
    }, [user, result]);


    const handleJsonExport = async () => {
        const export_url = `results/${result.id}/export_json`;
        try {
            const response = await api.get(export_url, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `result_${result.id}.json`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Failed to download JSON file:", error);
        }
    };

    return !result_id ? (
        <Navigate to={"/results"} />
    ) : !result ? (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress />
    </Box>
    ) : (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{height: '100%' }}>

            <Grid 
                container 
                sx={{ 
                    height: '100%',
                    minHeight: '100%',
                    display: 'flex', 
                    overflowY:{ md: 'auto', lg: 'hidden'}
                }}
                >            
            {/* Left Panel */}
            <Grid 
                item 
                xs={12} 
                sm={12} 
                md={3} 
                lg={3}
                sx={{ 
                    maxWidth: '100vw',
                    height: '100%',
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'space-between',
                    borderRight: "solid #eee 1px",
                    p: 3,
                    pt: 0,
                    background: 'rgba(1, 1, 1, 0.02)'
                }}
            >           
                    {/* Accordion Container */}
                    <Box sx={{ maxWidth: '100%', flexGrow: 1, overflowY: 'auto', pt:1 }}>

                    <Accordion 
                        expanded={expanded === 'resultInfo'} 
                        onChange={handleAccordianChange('resultInfo')}
                        sx={{ 
                            boxShadow: 'none', 
                            padding: 0,
                            margin: 0,
                            '&:before': { display: 'none' },
                            backgroundColor: 'transparent',
                            '&.Mui-expanded': {
                                padding: 0,
                                margin: 0,
                            },
                        }}
                        >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{}}/>}
                            aria-controls="result-info-content"
                            id="result-info-header"
                            sx={{
                                padding: 0,
                                margin: 0,
                                paddingY: 0.5,
                                minHeight: "40px",
                                // color: colorConfigs.primary,
                                '& .MuiSvgIcon-root': {
                                    // color: colorConfigs.primary,
                                },
                                backgroundColor: 'transparent',
                                '&.Mui-expanded': {
                                    minHeight: "40px",
                                    margin: 0,
                                    padding: 0,
                                    paddingY: 0.5,
                                    color: colorConfigs.primary,
                                    '& .MuiSvgIcon-root': {
                                        color: colorConfigs.primary,
                                    },
                                },
                                '&:hover': {
                                    color: colorConfigs.primary,
                                    '& .MuiSvgIcon-root': {
                                        color: colorConfigs.primary,
                                    },
                                },
                                '& .MuiAccordionSummary-content': {
                                    margin: 0,  // Ensure no margin in the content area
                                },
                            }}
                        >
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 2,}}>  
                            <InsertChartOutlinedIcon fontSize="large"/>
                            <Typography sx={{ fontSize: fontConfigs.detailPage.headings.title, fontWeight: "Bold" }}>Result Info</Typography>
                        </Box>
                       
                        </AccordionSummary>
                        <AccordionDetails
                            ref={resultDetailsRef}
                            sx={{ 
                                padding: 0, 
                                maxHeight: sizeConfigs.detailPage.dialogContent.accordionDetails, 
                                overflow: 'scroll',
                                '&::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none', // Internet Explorer 10+
                                'scrollbar-width': 'none', // Firefox
                                }}>

                            <Field name="id" type="text" className="form-control" label="ID" component={ResultDetailEditField} />
                            <Field name="timestamp" type="text" className="form-control" label="Date" component={ResultDetailEditField} />
                            <Field name="sample_id" type="text" className="form-control" label="Sample ID" component={ResultDetailEditField} />
                            <Field name="notes" type="text" className="form-control" label="Notes" component={NotesField} />
                            {user ? (
                                <div>
                                    <Field name="user_email" type="text" id={formik.values.result_user_id} status={formik.values.user_is_active} className="form-control" label="User" component={TextAdornmentReadOnlyField} />
                                    <Field name="user_organisation_name" type="text" id={formik.values.organisation_id} tier={formik.values.user_subscription_tier} className="form-control" label="Organisation" component={TextAdornmentNoStatusReadOnlyField} />
                                </div>
                            ) : (
                                <Field name="user_id" type="text" className="form-control" label="User ID" component={ResultDetailEditField} />
                            )}
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                <Field name="method_id" type="text" className="form-control" label="Method ID" component={ResultDetailEditField} />
                                <Field name="housing_id" type="text" className="form-control" label="Housing ID" component={ResultDetailEditField} />
                            </div>
                            <Field name="device_model" type="text" className="form-control" label="Device Model" component={ResultDetailEditField} />
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                <Field name="app_version" type="text" className="form-control" label="App Version" component={ResultDetailEditField} />   
                                <Field name="os_version" type="text" className="form-control" label="OS Version" component={ResultDetailEditField} />
                            </div>

                            <Box
                                onClick={() => scrollContent(resultDetailsRef, 'down')}
                                sx={{
                                    opacity: resultShowScrollBottom ? 0.9 : 0,  // Controls visibility smoothly
                                    transition: 'opacity 0.3s ease',  // Smooth transition for opacity
                                    pointerEvents: resultShowScrollBottom ? 'auto' : 'none',  // Disable clicks when hidden
                                    position: 'sticky',
                                    bottom: 0,
                                    width: '100%',
                                    height: '20px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    backgroundColor: colorConfigs.tables.headBg,
                                    cursor: 'pointer',
                                    zIndex: 10,
                                    display: 'flex',
                                    '&:hover': {
                                        backgroundColor: colorConfigs.tables.headBg,
                                        opacity: 1,
                                    }
                                }}
                            >
                                <ArrowDropDownIcon fontSize="large" sx={{ color: '#fff' }} />
                            </Box>
                        </AccordionDetails>
                        </Accordion>

                        {/* Configuration Accordion */}
                        <Accordion 
                        expanded={expanded === 'configuration'} 
                        onChange={handleAccordianChange('configuration')}
                        sx={{ 
                            boxShadow: 'none', 
                            padding: 0,
                            margin: 0,
                            '&:before': { display: 'none' },
                            backgroundColor: 'transparent',
                            '&.Mui-expanded': {
                                padding: 0,
                                margin: 0,
                            },
                        }}
                        >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="configuration-content"
                            id="configuration-header"
                            sx={{
                                padding: 0,
                                paddingY: 0.5,
                                margin: 0,
                                minHeight: "40px",
                                // color: colorConfigs.primary,
                                '& .MuiSvgIcon-root': {
                                    // color: colorConfigs.primary,
                                },
                                backgroundColor: 'transparent',
                                '&.Mui-expanded': {
                                    minHeight: "40px",
                                    margin: 0,
                                    padding: 0,
                                    paddingY: 0.5,
                                    color: colorConfigs.primary,
                                    '& .MuiSvgIcon-root': {
                                        color: colorConfigs.primary,
                                    },
                                },
                                '&:hover': {
                                    color: colorConfigs.primary,
                                    '& .MuiSvgIcon-root': {
                                        color: colorConfigs.primary,
                                    },
                                },
                                '& .MuiAccordionSummary-content': {
                                    margin: 0,  // Ensure no margin in the content area
                                },
                            }}
                        >
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 2,}}>  
                            <DataObjectOutlinedIcon fontSize="large"/>
                            <Typography sx={{ fontSize: fontConfigs.detailPage.headings.title, fontWeight: "Bold" }}>Configuration</Typography>
                        </Box>
                       
                        </AccordionSummary>
                        <AccordionDetails 
                            ref={configurationRef} 
                            sx={{ 
                                padding: 0, 
                                maxHeight: sizeConfigs.detailPage.dialogContent.accordionDetails, 
                                overflow: 'scroll',
                                '&::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none', // Internet Explorer 10+
                                'scrollbar-width': 'none', // Firefox
                                }}>
                            <div style={{}}>
                                <Field name="control_baseline_threshold" type="text" label="Control Baseline Threshold" component={ResultDetailEditField} />
                                <Field name="frames_to_capture" type="text" label="Frames to Capture" component={ResultDetailEditField} />
                                <Field name="pmf_initial_h_scale_factor" type="text" label="PMF Initial H Scale Factor" component={ResultDetailEditField} />
                                <Field name="polynomial_baseline_order" type="text" label="Polynomial Baseline Order" component={ResultDetailEditField} />
                                <Field name="reader_resolution" type="text" label="Reader Resolution" component={ResultDetailEditField} />
                                <Field name="test_pmf_max_conformance_probability" type="text" label="PMF Max Conformance Probability" component={ResultDetailEditField} />
                                <Field name="test_pmf_min_conformance_probability" type="text" label="PMF Min Conformance Probability" component={ResultDetailEditField} />
                            </div>
                            <Box sx={{ border: '1px #ddd solid', borderRadius: 1.2, p: 2, paddingY: 1.5, marginY: 2}}>
                                <Field name="camera_macro_focus_required" label="Camera Macro Focus Required" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                                <Field name="collection_mode" label="Collection Mode" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                                <Field name="control_baseline" label="Control Baseline" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                                <Field name="control_exposure" label="Control Exposure" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                                <Field name="control_line_correction" label="Control Line Correction" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                                <Field name="flash_required" label="Flash Required" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                                <Field name="profile_alignment" label="Profile Alignment" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                            </Box>

                            <Box
                                onClick={() => scrollContent(configurationRef, 'down')}
                                sx={{
                                    opacity: configShowScrollBottom ? 0.9 : 0,  // Controls visibility smoothly
                                    transition: 'opacity 0.3s ease',  // Smooth transition for opacity
                                    pointerEvents: configShowScrollBottom ? 'auto' : 'none',  // Disable clicks when hidden
                                    position: 'sticky',
                                    bottom: 0,
                                    width: '100%',
                                    height: '20px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    backgroundColor: colorConfigs.tables.headBg,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    zIndex: 10,
                                    '&:hover': {
                                        backgroundColor: colorConfigs.tables.headBg,
                                        opacity: 1,
                                    }
                                }}
                            >
                                <ArrowDropDownIcon fontSize="large" sx={{ color: '#fff' }} />
                            </Box>

                        </AccordionDetails>
                        </Accordion>
                </Box>
                <Box sx={{ 
                    // pt: 2,               
                    // textAlign: 'center'
                    }}>
                    <Button variant="contained" onClick={handleJsonExport}>
                    Export JSON
                    </Button>
                </Box>
            </Grid>



            {/* Right Panel */}
            <Grid 
                item 
                xs={12} 
                sm={12} 
                md={9} 
                lg={9} 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%',
                }}
            >
                {/* Top half: Carousel */}
                <Box sx={{ 
                    flex: 1, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    maxHeight: '50%', 
                    padding: "1em"
                }}>
                    <Box sx={{
                        width: "100%",
                        maxWidth: '600px',
                        p: 2,
                        margin: 'auto',
                    }}>
                        <Slider {...settings} ref={sliderRef}>
                            {pmfStories.map((pmfStory, index) => (
                                <Tooltip key={index} arrow placement="top" title={(
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px", color: "#fff" }}>
                                        <span>{`Image ${index + 1}`}</span>
                                        <IconButton 
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                window.open(pmfStory.image_id, '_blank');
                                            }}
                                            size="small"
                                            sx={{ padding: 0 }}
                                        >
                                            <AspectRatioOutlinedIcon fontSize="small" sx={{ ml: 1, color: "#fff" }} />
                                        </IconButton>
                                    </div>
                                )}>
                                    <div 
                                        key={index} 
                                        style={{ padding: "0 10px" }} 
                                        onClick={() => index === selectedImageIndex && handleOpenDialog(pmfStory.image_id)} // Only opens dialog for selected image
                                    >
                                        <img src={pmfStory.image_id} alt={`Slide ${index}`} 
                                            style={{ 
                                                width: "100%", 
                                                maxHeight: "500px",
                                                maxWidth: "500px",
                                                transition: "all 0.3s ease-in-out", 
                                                transform: index === selectedImageIndex ? "scale(1.1)" : "scale(0.8)", 
                                                objectFit: "contain",
                                                cursor: index === selectedImageIndex ? 'pointer' : 'default' // Pointer only for center image
                                            }} 
                                        />
                                    </div>
                                </Tooltip>
                            ))}
                        </Slider>

                        {/* Icons below selected image */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                            <IconButton 
                                onClick={() => handleOpenInNewTab(pmfStories[selectedImageIndex].image_id)}
                                sx={{
                                    // color: colorConfigs.primary,
                                    '&:hover': { color: colorConfigs.primary },
                                }}
                            >
                                <DownloadIcon fontSize="large" />
                            </IconButton>
                            <IconButton 
                                onClick={() => handleOpenDialog(pmfStories[selectedImageIndex].image_id)}
                                sx={{
                                    // color: colorConfigs.primary,
                                    '&:hover': { color: colorConfigs.primary },
                                }}
                            >
                                <ZoomInIcon fontSize="large" />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>

                {/* Dialog for Large Image View */}
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogContent 
                        onClick={handleCloseDialog}
                        sx={{
                            p: 0, 
                            m: 0, 
                            display: 'flex', 
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: 'transparent',
                        }}>
                        <img src={selectedImageUrl} alt="Larger view" style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} />
                    </DialogContent>
                </Dialog>

                    {/* Bottom half: Thumbnails */}
                    <Collapse in={pmfStories.length > 0}>
                    <Box sx={{ 
                        flex: 1, 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        gap: 2, 
                        padding: 4, 
                        justifyContent: 'center', 
                        overflowY: 'auto',
                        overflowX: 'auto',
                        maxWidth: '100%',
                    }}>
                    {pmfStories.map((pmfStory, i) => (
                        <Tooltip 
                            key={i} 
                            arrow
                            placement="bottom"
                            title={(
                                <div style={{ display: "flex", alignItems: "center", gap: "5px", color: "#fff", background: colorConfigs.tables.headBg }}>
                                    <span>{`Image ${i + 1}`}</span>
                                    <IconButton 
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            window.open(pmfStory.image_id, '_blank');
                                        }}
                                        size="small"
                                        style={{ padding: 0 }}
                                    >
                                        <AspectRatioOutlinedIcon fontSize="small" sx={{ ml: 1, color: "#fff" }} />
                                    </IconButton>
                                </div>
                            )}
                        >
                            <button key={i} onClick={() => handleThumbnailClick(i)} 
                                    style={{ border: 'none', background: "none", padding: 0 }}>
                                <img src={pmfStory.image_id} alt={`Thumbnail ${i}`} width="60px" height="60px" 
                                    style={{ opacity: i === selectedImageIndex ? 1 : 0.5, transition: 'opacity 0.3s' }} />
                                <Typography fontSize={'small'}>{i + 1}</Typography>
                            </button>
                        </Tooltip>
                    ))}
                </Box>
                </Collapse>
            </Grid>
        </Grid>


            </form>
        </FormikProvider>
    )
}

export default ResultDetailDCMCard;