import React, { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCheckoutForm from "./PaymentCheckoutForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import UpdateSubscriptionForm from "./UpdateSubscriptionForm";
import CancelSubscriptionForm from "./CancelSubscriptionForm";
import UpdatePaymentMethodForm from "./UpdatePaymentMethodForm";

const PaymentTestCard = () => {
  const authData = useSelector((state: RootState) => state.auth);
  const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null);

  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await api.get("payments/stripe-key/", {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });
        // Load Stripe instance and update state
        const stripe = await loadStripe(response.data.publishable_key);
        setStripeInstance(stripe);
      } catch (error) {
        console.error("Error fetching Stripe key:", error);
      }
    };

    fetchStripeKey();
  }, [authData.access]);

  return !stripeInstance ? (
    <div>Loading...</div>
  ) : (
    <Elements stripe={stripeInstance}>
      <PaymentCheckoutForm />
      <UpdateSubscriptionForm />
      <UpdatePaymentMethodForm />
      <CancelSubscriptionForm />
    </Elements>
  );
};

export default PaymentTestCard;
