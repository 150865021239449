import ActivationCard from "../../components/common/cards/ActivationCard";


type Props = {}

const ActivationPage = (props: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <ActivationCard />

    </div>
  );
};

export default ActivationPage;