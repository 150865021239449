
const appbarHeights = {
    xs: '8vh',    // Extra small screens
    sm: '7vh',    // Small screens
    md: '7vh',    // Medium screens
    lg: '6vh',    // Large screens
    xl: '4vh',    // Extra large screens
};

const sizeConfigs = {
    topbar: {
        height: "80px"
    },
    sidebar: {
        width: "290px",
        halfwidth: "80px"
    },
    detailPage: {
        appbar: {
            height: appbarHeights
        },
        dialogContent: {
            height: {
                xs: `calc(100vh - ${appbarHeights.xs})`,
                sm: `calc(100vh - ${appbarHeights.sm})`,
                md: `calc(100vh - ${appbarHeights.md})`,
                lg: `calc(100vh - ${appbarHeights.lg})`,
                xl: `calc(100vh - ${appbarHeights.xl})`,
            },
            accordionDetails: {
                xs: `calc(100vh - ${appbarHeights.xs})`,  // Subtract header height and extra padding
                sm: `calc(70vh - ${appbarHeights.sm})`,
                md: `calc(75vh - ${appbarHeights.md})`,
                lg: `calc(79vh - ${appbarHeights.lg})`,
                xl: `calc(80vh - ${appbarHeights.xl})`,
            },
        }
    }
};

export default sizeConfigs;